import LoadingBarProcess from 'components/LoadingBarProcess';
import MasterLayout from 'layouts/MasterLayout';
import DEManagement from 'pages/DEManagement/DEManagement';
import React from 'react';
import { Route, Routes } from 'react-router-dom';

const DEManagementRoutes = () => {
  return (
    <Routes>
      <Route path="/" element={<MasterLayout type="header" />}>
        <Route
          index
          element={
            <React.Suspense fallback={<LoadingBarProcess />}>
              <DEManagement />
            </React.Suspense>
          }
        />
      </Route>
    </Routes>
  );
};

export default DEManagementRoutes;
