import React, { ReactNode } from 'react';
import { AiOutlineClose } from 'react-icons/ai';
import { CircleSpin } from '../CircleSpin';

const PanelSiderBar = ({
  children,
  open = false,
  close,
  submit,
  clear,
  title,
  submitButtonName,
  disabledSubmit,
  onSubmitExport,
  cancelButtonName
}: {
  children: ReactNode;
  open: boolean;
  close: () => void;
  submit: () => void;
  clear?: () => void;
  title?: string;
  submitButtonName?: string;
  cancelButtonName?: string;
  disabledSubmit?: boolean;
  onSubmitExport?: boolean;
}) => {
  return (
    <>
      {open && (
        <>
          <div
            className="fixed w-full h-full bg-overlay-menu z-100 top-0 left-0"
            onClick={close}
          ></div>
          <div className="z-600 fixed h-full bg-gray-fb top-0 right-0 w-11/12 md:w-full max-w-[500px]">
            <div className="flex flex-col h-full">
              <div className="flex-none flex items-center text-black-100 font-semibold text-xl py-4 p-6">
                <span>{title ? title : 'Bộ Lọc'}</span>
                <button className="ml-auto" onClick={close}>
                  <AiOutlineClose />
                </button>
              </div>
              <div className="flex-grow py-4 p-6 overflow-y-auto">
                {children}
              </div>
              <div className="flex-none flex bg-gray-f2 py-4 p-6 z-50 space-x-2">
                <button
                  type="button"
                  onClick={clear}
                  className="flex justify-center w-full md:w-52 form-button-seconday"
                >
                  {cancelButtonName ? cancelButtonName : 'Đặt lại'}
                </button>
                <button
                  type="button"
                  onClick={submit}
                  disabled={onSubmitExport || disabledSubmit}
                  className="flex justify-center w-full md:w-52 form-button"
                >
                  {onSubmitExport && <CircleSpin />}
                  {submitButtonName ? submitButtonName : 'Xong'}
                </button>
              </div>
            </div>
          </div>
        </>
      )}
    </>
  );
};

export default PanelSiderBar;
