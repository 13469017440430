import { HOST_URL, SURVEY_SECTION } from "constant/api.contant";
import axiosInstance from "services/restful.service";

export const getSurveySections = (params: any) => {
    return axiosInstance.get(HOST_URL + SURVEY_SECTION.GET_SURVEY_SECTIONS, {params});
}

export const getSurveySectionByCode = (code: string) => {
    return axiosInstance.get(HOST_URL + SURVEY_SECTION.GET_SURVEY_SECTION_BY_CODE(code));
}

export const updateSurveySectionByCode = (params: any) => {
    return axiosInstance.put(HOST_URL + SURVEY_SECTION.UPDATE_SURVEY_SECTION_BY_CODE(params.code), params);
}

export const enableSurveySectionByCode = (code: string) => {
    return axiosInstance.put(HOST_URL + SURVEY_SECTION.ENABLE_SURVEY_SECTION_BY_CODE(code));
}

export const disableSurveySectionByCode = (code: string) => {
    return axiosInstance.put(HOST_URL + SURVEY_SECTION.DISABLE_SURVEY_SECTION_BY_CODE(code));
}