import { BONDS, HOST_URL } from "constant/api.contant";
import axiosInstance from "services/restful.service";

export const getBonds = (params: any) => {
    return axiosInstance.get(HOST_URL + BONDS.LIST, {params});
}

export const addBond = (params: any) => {
    return axiosInstance.post(HOST_URL + BONDS.ADD_BOND, params);
}

export const getBondById = (id: string) => {
    return axiosInstance.get(HOST_URL + BONDS.GET_BOND_BY_ID(id));
}

export const updateBondById = (params?: any) => {
    return axiosInstance.put(HOST_URL + BONDS.UPDATE_BOND_BY_ID(params.id), params);
}

export const uploadBondImage = (file: any) => {
  const formData = new FormData();
  formData.append(
    "file",
    file,
  );
  return axiosInstance
  .post(`${HOST_URL}${BONDS.UPLOAD_IMAGE}`, formData);  
}