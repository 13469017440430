import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "store";
import { getStockMetadataBySymbol, getStockMetadatas, updateStockMetadataBySymbol } from "./stockSymbolMetadataAPI";

export interface StockSymbolMetadataState {
    stockSymbolMetadata: any;
    status: 'idle' | 'loading' | 'failed';
}

const initialState: StockSymbolMetadataState = {
    stockSymbolMetadata: [],
    status: 'idle',
}

export const getStockMetadatasAsync = createAsyncThunk(
    'stockSymbolMetadata/getStockMetadatas',
    async (params: any) => {
        const response = await getStockMetadatas(params);
        return response.data;
    }
);

export const getStockMetadataBySymbolAsync = createAsyncThunk(
    'stockSymbolMetadata/getStockMetadataBySymbol',
    async (params: any) => {
        try {
            const response = await getStockMetadataBySymbol(params);
            return response.data;
        } catch (error: any) {
            return {error: error.response?.data}
        } 
    }
);

export const updateStockMetadataBySymbolAsync = createAsyncThunk(
    'stockSymbolMetadata/updateStockMetadataBySymbol',
    async (params: any) => {
        try {
            const response = await updateStockMetadataBySymbol(params);
            return response.data;
        } catch (error: any) {
            return {error: error.response?.data}
        } 
    }
);

export const stockSymbolMetadataSlice = createSlice({
    name: "stockSymbolMetadata",
    initialState,
    reducers: {
       getList: (state, action: PayloadAction<undefined>) => {
            state.stockSymbolMetadata = action.payload;
        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(getStockMetadatasAsync.pending, (state) => {
                state.status = 'loading';
            })
            .addCase(getStockMetadatasAsync.fulfilled, (state, action) => {
                state.status = 'idle';
                state.stockSymbolMetadata = action.payload;
            })
    },
});

export const { getList} = stockSymbolMetadataSlice.actions;

export const selectStockSymbolMetadata = (state: RootState) => state.stockSymbolMetadata;

export default stockSymbolMetadataSlice.reducer;