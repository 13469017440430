import { HOST_URL, TITITADA_RIGHT } from "constant/api.contant";
import axiosInstance from "services/restful.service";

export const getTititadaRights = (params: any) => {
    return axiosInstance.get(HOST_URL + TITITADA_RIGHT.GET_TITITADA_RIGHTS, {params});
}

export const getTititadaRightById = (id: string) => {
    return axiosInstance.get(HOST_URL + TITITADA_RIGHT.GET_TITITADA_RIGHT_BY_ID(id));
}

export const addTititadaRight = (params: any) => {
    return axiosInstance.post(HOST_URL + TITITADA_RIGHT.ADD_TITITADA_RIGHT, params);
}

export const closeTititadaRight = (id: string) => {
    return axiosInstance.post(HOST_URL + TITITADA_RIGHT.CLOSE_TITITADA_RIGHT_BY_ID(id));
}

export const editActiveDateTititadaRight = (params: any) => {
    return axiosInstance.put(HOST_URL + TITITADA_RIGHT.EDIT_ACTIVE_DATE_TITITADA_RIGHT_BY_ID(params.id), params);
}