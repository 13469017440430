import axios from 'axios';
import { AuthLoginType, AuthLoginMSType } from './../types/Auth.type';
import { AUTH, HOST_URL } from '../constant/api.contant';
import axiosInstance from './restful.service';

const login = (user: AuthLoginType) => {
  return axiosInstance.post(HOST_URL + AUTH.LOGIN, user).then(response => {
    return response.data;
  });
};

const loginMS = (accessToken: string) => {
  const axiosMSInstance = axios.create({
    baseURL: HOST_URL,
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json'
    }
  });
  axiosMSInstance.interceptors.request.use(
    (config: any) => {
      const auth = accessToken ? `Bearer ${accessToken}` : '';
      config.headers.common['Authorization'] = auth;
      return config;
    },
    error => Promise.reject(error)
  );

  return axiosMSInstance.post(AUTH.LOGIN_MS).then(response => {
    return response.data;
  });
};

const logout = async () => {
  //const res = await RestfulService.deleteApi(AUTH.LOGOUT);
  //if (res.status === 204) {
  localStorage.removeItem('user');
  sessionStorage.removeItem('user');
  return true;
  //}
};

const forgotPassword = (payload: { email: string }) => {
  return axios.post(HOST_URL + AUTH.FORGOT_PASSWORD, payload);
};

const resetPassword = (payload: { email: string }) => {
  return axios.post(HOST_URL + AUTH.RESET_PASSWORD, payload);
};

const renewToken = () => {
  return axiosInstance.post(HOST_URL + AUTH.RENEW_TOKEN);
};

const changePin = (params: any) => {
  return axiosInstance.post(HOST_URL + AUTH.CHANGE_PIN, params);
};

const AuthService = {
  login,
  loginMS,
  logout,
  forgotPassword,
  resetPassword,
  renewToken,
  changePin
};

export default AuthService;
