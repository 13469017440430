import * as React from 'react';
import { Routes, Route } from 'react-router-dom';
import MasterLayout from 'layouts/MasterLayout/';
import LoadingBarProcess from 'components/LoadingBarProcess';

const UserManagementPage = React.lazy(() => import('../pages/UserManagement'));
const AccountDetailsPage = React.lazy(
  () => import('../pages/UserManagement/UserDetail')
);

const UserManagementRoutes = () => {
  return (
    <Routes>
      <Route path="/" element={<MasterLayout type="header" isBack={false} />}>
        <Route
          index
          element={
            <React.Suspense fallback={<LoadingBarProcess />}>
              <UserManagementPage />
            </React.Suspense>
          }
        />
        <Route
          path="/add"
          element={
            <React.Suspense fallback={<LoadingBarProcess />}>
              <AccountDetailsPage />
            </React.Suspense>
          }
        />
        <Route
          path=":id"
          element={
            <React.Suspense fallback={<LoadingBarProcess />}>
              <AccountDetailsPage />
            </React.Suspense>
          }
        />
      </Route>
    </Routes>
  );
};

export default UserManagementRoutes;
