import LoadingBarProcess from 'components/LoadingBarProcess';
import MasterLayout from 'layouts/MasterLayout';
import AnnouncedInformationAdd from 'pages/AnnouncedInformationManagement/AnnouncedInformationAdd';
import AnnouncedInformationPage from 'pages/AnnouncedInformationManagement/AnnouncedInformationPage';
import React from 'react';
import { Route, Routes } from 'react-router-dom';

const AnnouncedInformationManagement = () => {
  return (
    <Routes>
      <Route path="/" element={<MasterLayout type="header" />}>
        <Route
          index
          element={
            <React.Suspense fallback={<LoadingBarProcess />}>
              <AnnouncedInformationPage />
            </React.Suspense>
          }
        />
      </Route>
      <Route path="/add" element={<MasterLayout type="header" />}>
        <Route
          index
          element={
            <React.Suspense fallback={<LoadingBarProcess />}>
              <AnnouncedInformationAdd />
            </React.Suspense>
          }
        />
      </Route>
      <Route path="/edit/:id" element={<MasterLayout type="header" />}>
        <Route
          index
          element={
            <React.Suspense fallback={<LoadingBarProcess />}>
              <AnnouncedInformationAdd />
            </React.Suspense>
          }
        />
      </Route>
    </Routes>
  );
};

export default AnnouncedInformationManagement;
