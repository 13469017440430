import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "store";
import { addArticleTopic, deleteArticleTopicById, getArticleTopicByCategoryId, getArticleTopicById, getArticleTopics, updateArticleTopicById } from "./articleTopicsAPI";

export interface ArticleTopicsState {
  articleTopics: any;
  status: 'idle' | 'loading' | 'failed';
}

const initialState: ArticleTopicsState = {
  articleTopics: [],
  status: 'idle',
};

export const getArticleTopicsAsync = createAsyncThunk(
  'articleTopics/getArticleTopics',
  async (params?: any) => {
    const response = await getArticleTopics(params);
    return response.data;
  }
);

export const getArticleTopicByIdAsync = createAsyncThunk(
  'articleTopics/getArticleTopicById',
  async (params?: any) => {
    try {
        const response = await getArticleTopicById(params);
        return response.data;
    }catch (error: any) {
        return {error: error.response?.data}
    }
  }
);

export const getArticleTopicByCategoryIdAsync = createAsyncThunk(
  'articleTopics/getArticleTopicByCategoryId',
  async (params?: any) => {
    try {
        const response = await getArticleTopicByCategoryId(params);
        return response.data;
    }catch (error: any) {
        return {error: error.response?.data}
    }
  }
);

export const addArticleTopicAsync = createAsyncThunk(
  'articleTopics/addArticleTopic',
  async (params?: any) => {
    try {
        const response = await addArticleTopic(params);
        return response.data;
    }catch (error: any) {
        return {error: error.response?.data}
    }
  }
);

export const updateArticleTopicByIdAsync = createAsyncThunk(
  'articleTopics/updateArticleTopicById',
  async (params?: any) => {
    try {
        const response = await updateArticleTopicById(params);
        return response.data;
    }catch (error: any) {
        return {error: error.response?.data}
    }
  }
);

export const deleteArticleTopicByIdAsync = createAsyncThunk(
  'articleTopics/deleteArticleTopicById',
  async (params?: any) => {
    try {
        const response = await deleteArticleTopicById(params);
        return response.data;
    }catch (error: any) {
        return {error: error.response?.data}
    }
  }
);

export const articleTopicsSlice = createSlice({
    name: 'articleTopics',
    initialState,
    reducers: {
        getList: (state, action: PayloadAction<undefined>) => {
        state.articleTopics = action.payload;
        },
        reduxDeleteTopicById: (state, action) => {
          const currentTopicIndex = state.articleTopics.items.findIndex((category: any) => {
            return category.id == action.payload;
          });
          state.articleTopics.items.splice(currentTopicIndex, 1);
        }
    },
    extraReducers: (builder) => {
        builder
        .addCase(getArticleTopicsAsync.pending, (state) => {
            state.status = 'loading';
        })
        .addCase(getArticleTopicsAsync.fulfilled, (state, action) => {
            state.status = 'idle';
            state.articleTopics = action.payload;
        })
    },
});

export const { getList, reduxDeleteTopicById } = articleTopicsSlice.actions;
export const selectArticleTopics = (state: RootState) => state.articleTopics;
export default articleTopicsSlice.reducer;