import { Titleb600z14l20 } from 'components/UiComponents/CustomHeading/Titleb600z14l20';
import Modal from 'components/UiComponents/Modal';
import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import EyeShow from 'assets/images/Eye@3x.png';
import EyeHide from 'assets/images/Eye_Hide@3x.png';
import AuthService from 'services/auth.service';
import { useAuth } from 'contexts/Auth/AuthContext';
import { AdminsService } from 'services/admins.service';

type ChangePasswordProps = {
  isOpen: boolean;
  closeModal: () => void;
  toastMessage: (type: string, message: string) => void;
};

const ChangePassword = ({
  isOpen,
  closeModal,
  toastMessage
}: ChangePasswordProps) => {
  const [showOldPassword, setshowOldPassword] = useState<any>(false);
  const [showNewPassword, setshowNewPassword] = useState<any>(false);
  const [showConfirmNewPassword, setshowConfirmNewPassword] =
    useState<any>(false);
  const [isSubmit, setIsSubmit] = useState<any>(false);
  const [validateConfirmPass, setValidateConfirmPass] = useState<any>();
  const auth = useAuth();

  const {
    register,
    handleSubmit,
    reset,
    trigger,
    formState: { errors }
  } = useForm<any>();

  const resetForm = () => {
    reset();
    setshowOldPassword(false);
    setshowNewPassword(false);
    setshowConfirmNewPassword(false);
    setIsSubmit(false);
  };

  const onSubmit = async (data: any) => {
    setIsSubmit(true);

    if (data?.new_password !== data?.confirm_new_password) {
      setValidateConfirmPass('Xác nhận mật khẩu mới không chính xác');
      setIsSubmit(false);
      return;
    }

    const response = await AdminsService.AdminChangePassword(
      auth.user?.sub || '',
      {
        current_password: data?.old_password,
        new_password: data?.new_password,
        confirm_new_password: data?.confirm_new_password
      }
    );

    if (
      response?.status === 200 ||
      response?.status === 201 ||
      response?.status === 204
    ) {
      if (response?.data?.status === 'Success') {
        toastMessage('success', 'Đổi mật khẩu thành công');

        closeModal();
        resetForm();
      } else {
        toastMessage(
          'error',
          response?.data?.error_message || 'Có lỗi, vui lòng thử lại'
        );
      }
      setIsSubmit(false);
    } else {
      let message = '';

      if (response?.data?.error) {
        response?.data?.error?.forEach((item: any) => {
          message += item?.error_message;
        });
      } else {
        message = response?.data?.error_message;
      }

      toastMessage('error', message || 'Có lỗi, vui lòng thử lại');
      setIsSubmit(false);
    }
  };

  return (
    <Modal
      show={isOpen}
      title={''}
      onClose={() => {
        return;
      }}
      className="max-w-[640px] max-h-[440px] bg-white rounded-xl shadow"
    >
      <form onSubmit={handleSubmit(onSubmit)} className="form" noValidate>
        <div className="w-auto h-auto">
          <div className="text-2xl font-semibold leading-8 text-center">
            Cập nhật mật khẩu
          </div>
          <div className="mt-4">
            <Titleb600z14l20>Mật khẩu hiện tại</Titleb600z14l20>
            <div className="relative">
              <input
                id="old_password"
                type={showOldPassword ? 'text' : 'password'}
                autoComplete="old_password"
                placeholder="Nhập mật khẩu hiện tại"
                className="form-control"
                {...register('old_password', {
                  required: true,
                  setValueAs: (value: string) => value,
                  onChange: () => {
                    trigger('old_password');
                  }
                })}
              />
              <div
                className="absolute right-2 top-[9px] w-[21px] h-[14px] cursor-pointer"
                onClick={() => setshowOldPassword(!showOldPassword)}
              >
                <img
                  src={showOldPassword ? EyeShow : EyeHide}
                  alt="showNewPassword"
                  className=" "
                />
              </div>
            </div>
            {errors?.old_password?.type === 'required' && (
              <span className="text-red-accent text-xs">
                Vui lòng nhập mật khẩu hiện tại
              </span>
            )}
          </div>
          <div className="mt-4">
            <Titleb600z14l20>Mật khẩu mới</Titleb600z14l20>
            <div className="relative">
              <input
                id="new_password"
                type={showNewPassword ? 'text' : 'password'}
                autoComplete="new_password"
                placeholder="Nhập mật khẩu mới"
                className="form-control"
                {...register('new_password', {
                  required: true,
                  setValueAs: (value: string) => value,
                  onChange: () => {
                    trigger('new_password');
                    if (validateConfirmPass) {
                      setValidateConfirmPass('');
                    }
                  }
                })}
              />
              <div
                className="absolute right-2 top-[9px] w-[21px] h-[14px] cursor-pointer"
                onClick={() => setshowNewPassword(!showNewPassword)}
              >
                <img
                  src={showNewPassword ? EyeShow : EyeHide}
                  alt="showNewPassword"
                  className=" "
                />
              </div>
            </div>
            {errors?.new_password?.type === 'required' && (
              <span className="text-red-accent text-xs">
                Vui lòng nhập mật khẩu mới
              </span>
            )}
          </div>
          <div className="mt-4">
            <Titleb600z14l20>Xác nhận mật khẩu mới</Titleb600z14l20>
            <div className="relative">
              <input
                id="confirm_new_password"
                type={showConfirmNewPassword ? 'text' : 'password'}
                autoComplete="confirm_new_password"
                placeholder="Nhập lại mật khẩu"
                className="form-control"
                {...register('confirm_new_password', {
                  required: true,
                  setValueAs: (value: string) => value,
                  onChange: () => {
                    trigger('confirm_new_password');
                    if (validateConfirmPass) {
                      setValidateConfirmPass('');
                    }
                  }
                })}
              />
              <div
                className="absolute right-2 top-[9px] w-[21px] h-[14px] cursor-pointer"
                onClick={() =>
                  setshowConfirmNewPassword(!showConfirmNewPassword)
                }
              >
                <img
                  src={showConfirmNewPassword ? EyeShow : EyeHide}
                  alt="showNewPassword"
                  className=" "
                />
              </div>
            </div>
            {errors?.confirm_new_password?.type === 'required' && (
              <span className="text-red-accent text-xs">
                Vui lòng nhập lại mật khẩu
              </span>
            )}
            {validateConfirmPass && (
              <span className="text-red-accent text-xs">
                {validateConfirmPass}
              </span>
            )}
          </div>
        </div>
        <div className=" mt-10 flex items-center justify-center gap-4">
          <div>
            <button
              type="button"
              className="form-button-seconday !w-[163px] text-center"
              onClick={() => {
                closeModal();
                resetForm();
              }}
            >
              Hủy
            </button>
          </div>
          <div>
            <button
              type="button"
              className="form-button !w-[163px] text-center"
              onClick={handleSubmit(onSubmit)}
              disabled={isSubmit}
            >
              Cập nhật
            </button>
          </div>
        </div>
      </form>
    </Modal>
  );
};

export default ChangePassword;
