import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "store";
import { getArticleTags, updateIsShowArticleTagById } from "./articleTagsAPI";

export interface ArticleTagsState {
  articleTags: any;
  status: 'idle' | 'loading' | 'failed';
}

const initialState: ArticleTagsState = {
  articleTags: [],
  status: 'idle',
};

export const getArticleTagsAsync = createAsyncThunk(
  'articleTags/getArticleTags',
  async (params?: any) => {
    const response = await getArticleTags(params);
    return response.data;
  }
);

export const updateIsShowArticleTagByIdAsync = createAsyncThunk(
  'articleTags/updateIsShowArticleTagById',
  async (params?: any) => {
    try {
        const response = await updateIsShowArticleTagById(params);
        return response.data;
    }catch (error: any) {
        return {error: error.response?.data}
    }
  }
);

export const articleTagsSlice = createSlice({
    name: 'articleTags',
    initialState,
    reducers: {
        getList: (state, action: PayloadAction<undefined>) => {
        state.articleTags = action.payload;
        },
        toggleTagStatus: (state, action) => {
          const currentTag = state.articleTags.items.find((tag: any) => {
            return tag.id === action.payload
          });
          if (currentTag) {
            currentTag.display = !currentTag.display;
          }
        },
    },
    extraReducers: (builder) => {
        builder
        .addCase(getArticleTagsAsync.pending, (state) => {
            state.status = 'loading';
        })
        .addCase(getArticleTagsAsync.fulfilled, (state, action) => {
            state.status = 'idle';
            state.articleTags = action.payload;
        })
    },
});

export const { getList, toggleTagStatus } = articleTagsSlice.actions;
export const selectArticleTags = (state: RootState) => state.articleTags;
export default articleTagsSlice.reducer;