
import React from 'react';
import { useAuth } from 'contexts/Auth/AuthContext';
import { Tag } from './../UiComponents';
import cover from 'assets/images/cover-2-lg.png';
import styles from "./index.module.scss";
import user_avatar from '../../assets/images/user_avatar.png';

const UserInfor: React.FC = () => {
  const auth = useAuth();

  return (
    <>
      <div className='relative flex flex-col items-center'>
        <div className={`${styles.userProfile} h-[135px]`}>
          <div className={styles['userProfile-avatar']} style={{ 'backgroundImage': `url(" ${auth?.user?.avatar ? auth?.user?.avatar : user_avatar}")` }}></div>
          
          <div className='w-full text-truncate'>
            <h3 className='title text-white'>{auth?.user?.fullname}</h3>
            <p className="text-xs text-white">{auth?.user?.position}</p>
            <p className="text-xs text-white">{auth?.user?.role}</p>
            <div className='hidden'>
              <Tag name="Starter" />
            </div>
          </div>
        </div>
        <img
          className='absolute opacity-60 top-0 left-0 right-0 bottom-0 h-auto'
          src={cover}
          alt="tititada"
        />
      </div>
    </>
  )
}

export default UserInfor;