import { ANNOUNCEMENT, HOST_URL } from 'constant/api.contant';
import axiosInstance from 'services/restful.service';

export const getAnnouncement = (params?: any) => {
  return axiosInstance.get(HOST_URL + ANNOUNCEMENT.LIST, { params });
};

export const getAnnouncementById = (id: any) => {
  return axiosInstance.get(HOST_URL + ANNOUNCEMENT.DETAILS(id));
};

export const createAnnouncement = (params?: any) => {
  return axiosInstance.post(HOST_URL + ANNOUNCEMENT.CREATE, params);
};

export const updateAnnouncement = (params?: any) => {
  return axiosInstance.put(HOST_URL + ANNOUNCEMENT.UPDATE(params.id), params);
};

export const getUrlUploadFile = (params?: any) => {
  return axiosInstance.get(HOST_URL + ANNOUNCEMENT.GET_URL_UPLOAD, { params });
};

export const deleteAnnouncement = (id: any) => {
  return axiosInstance.delete(HOST_URL + ANNOUNCEMENT.DELETE(id));
};

export const deleteFile = (announcement_id: any, file_id: any) => {
  return axiosInstance.delete(
    HOST_URL + ANNOUNCEMENT.DELETE_FILE(announcement_id, file_id)
  );
};

export const createFile = (params?: any) => {
  return axiosInstance.post(
    HOST_URL + ANNOUNCEMENT.CREATE_FILE(params?.id),
    params
  );
};

export const updateFile = (params: any) => {
  return axiosInstance.put(
    HOST_URL +
      ANNOUNCEMENT.UPDATE_FILE(params?.announcement_id, params?.file_id),
    params
  );
};
