import { ARTICLE_TOPICS, HOST_URL } from "constant/api.contant";
import axiosInstance from "services/restful.service";

export const getArticleTopics = (params: any) => {
    return axiosInstance.get(HOST_URL + ARTICLE_TOPICS.GET_ARTICLE_TOPICS, {params});
}

export const getArticleTopicById = (id: string) => {
    return axiosInstance.get(HOST_URL + ARTICLE_TOPICS.GET_ARTICLE_TOPIC_BY_ID(id));
}

export const getArticleTopicByCategoryId = (params: any) => {
    return axiosInstance.get(HOST_URL + ARTICLE_TOPICS.GET_ARTICLE_TOPIC_BY_CATEGORY_ID, {params})
}

export const addArticleTopic = (params: any) => {
    return axiosInstance.post(HOST_URL + ARTICLE_TOPICS.ADD_ARTICLE_TOPIC, params);
}

export const updateArticleTopicById = (params: any) => {
    return axiosInstance.put(HOST_URL + ARTICLE_TOPICS.UPDATE_ARTICLE_TOPIC_BY_ID(params.id), params);
}

export const deleteArticleTopicById = (id: string) => {
    return axiosInstance.delete(HOST_URL + ARTICLE_TOPICS.DELELE_ARTICLE_TOPIC_BY_ID(id));
}