import React from 'react';
import { NavBar } from 'components/NavBar';
import UserInfor from 'components/UserInfor';
import styles from "./index.module.scss";
import sbbs_logo from '../../assets/images/sbbs_logo.png'

const SideBar: React.FC = () => {
  return (<>
    <div className={`${styles['page-sidebar']} w-[280px] fixed overflow-auto h-full`}>
      <div className={`${styles['page-logo']} bg-brand-gradient-1 flex items-center justify-center`}>
        <img
          className={`${styles['loginLeftContent-logo']} max-h-[35px] w-auto mr-4`}
          src={sbbs_logo}
          alt="SBBS"
        /> 
      </div>
      <div className={styles['page-sidebar-content']}>
        <div><UserInfor /></div>
        <div className='mt-2'><NavBar /></div>
      </div>
    </div>
  </>)
};

export default SideBar;