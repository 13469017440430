import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '../..';
import { addBond, getBondById, getBonds, updateBondById } from './bondsAPI';

export interface BondsState {
  bonds: any;
  status: 'idle' | 'loading' | 'failed';
}

const initialState: BondsState = {
  bonds: [],
  status: 'idle',
};

export const getBondsAsync = createAsyncThunk(
  'bonds/getBonds',
  async (params?: any) => {
    const response = await getBonds(params);
    return response.data;
  }
);

export const addBondAsync = createAsyncThunk(
  'bonds/addBond',
  async (params?: any) => {
    try {
        const response = await addBond(params);
        return response.data;
    }catch (error: any) {
        return {error: error.response?.data}
    }
  }
);

export const getBondByIdAsync = createAsyncThunk(
    'bonds/getBondById',
    async (params?: any) => {
        try {
            const response = await getBondById(params);
            return response.data;
        }catch (error: any) {
            return {error: error.response?.data}
        }
    }
);

export const updateBondByIdAsync = createAsyncThunk(
  'bonds/updateBondById',
  async (params?: any) => {
    try {
        const response = await updateBondById(params);
        if (response.status == 500) {
          return {error: [{error_message: "Hệ thống lỗi, vui lòng thử lại!"}]}
        }else {
          return response.data;
        }
    }catch (error: any) {
        return {error: error.response?.data}
    }
  }
);

export const bondsSlice = createSlice({
  name: 'bonds',
  initialState,
  reducers: {
    getList: (state, action: PayloadAction<undefined>) => {
      state.bonds = action.payload;
    },
    reduxDeleteBondById: (state, action) => {
      const currentCategoryIndex = state.bonds.items.findIndex((category: any) => {
        return category.id == action.payload;
      });
      state.bonds.items.splice(currentCategoryIndex, 1);
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(getBondsAsync.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(getBondsAsync.fulfilled, (state, action) => {
        state.status = 'idle';
        state.bonds = action.payload;
      })
  },
});

export const { getList, reduxDeleteBondById } = bondsSlice.actions;
export const selectBonds = (state: RootState) => state.bonds;


export default bondsSlice.reducer;