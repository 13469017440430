import { DEPOSITS, HOST_URL } from 'constant/api.contant';
import axiosInstance from 'services/restful.service';

export const getDeposits = (params?: any) => {
  return axiosInstance.get(HOST_URL + DEPOSITS.LIST, { params });
};

export const refundDeposits = (id: string) => {
  return axiosInstance.post(HOST_URL + DEPOSITS.REFUND(id));
};

export const markSuccessDeposits = (id: string, movement_id: string) => {
  return axiosInstance.post(HOST_URL + DEPOSITS.MARK_SUCCESS(id, movement_id));
};

export const exportData = (params?: any) => {
  return axiosInstance.get(HOST_URL + DEPOSITS.EXPORT_DATA, {
    responseType: 'blob',
    params
  });
};
