import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "store";
import { addFAQ, disableFAQById, enableFAQById, getFAQById, getFAQs, updateFAQ } from "./FAQsManagementAPI";

export interface FAQsManagementStage {
    faqsManagement: any;
    status: 'idle' | 'loading' | 'failed';
}

const initialState: FAQsManagementStage = {
    faqsManagement: [],
    status: 'idle',
}

export const getFAQsAsync = createAsyncThunk(
    'faqsManagement/getFAQs',
    async (params: any) => {
        const response = await getFAQs(params);
        return response.data;
    }
);

export const getFAQByIdAsync = createAsyncThunk(
    'faqsManagement/getFAQById',
    async (id: string) => {
        try {
            const response = await getFAQById(id);
            return response.data;
        } catch (error: any) {
            return {error: error.response?.data}
        } 
    }
);

export const addFAQAsync = createAsyncThunk(
    'faqsManagement/addFAQ',
    async (id: string) => {
        try {
            const response = await addFAQ(id);
            return response.data;
        } catch (error: any) {
            return {error: error.response?.data}
        } 
    }
);

export const updateFAQAsync = createAsyncThunk(
    'faqsManagement/updateFAQ',
    async (id: string) => {
        try {
            const response = await updateFAQ(id);
            return response.data;
        } catch (error: any) {
            return {error: error.response?.data}
        } 
    }
);

export const enableFAQByIdAsync = createAsyncThunk(
    'faqsManagement/enableFAQById',
    async (id: string) => {
        try {
            const response = await enableFAQById(id);
            return response.data;
        } catch (error: any) {
            return {error: error.response?.data}
        } 
    }
);

export const disableFAQByIdAsync = createAsyncThunk(
    'faqsManagement/disableFAQById',
    async (id: string) => {
        try {
            const response = await disableFAQById(id);
            return response.data;
        } catch (error: any) {
            return {error: error.response?.data}
        } 
    }
);

export const FAQsManagementSlice = createSlice({
    name: "faqsManagement",
    initialState,
    reducers: {
        getList: (state, action: PayloadAction<undefined>) => {
            state.faqsManagement = action.payload;
        },
        toggleFAQStatusByIdRedux: (state, action) => {
          const currentFAQ = state.faqsManagement.items.find((faq: any) => {
            return faq.id === action.payload
          });
          if (currentFAQ) {
            currentFAQ.is_active = !currentFAQ.is_active;
          }
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(getFAQsAsync.pending, (state) => {
                state.status = 'loading';
            })
            .addCase(getFAQsAsync.fulfilled, (state, action) => {
                state.status = 'idle';
                state.faqsManagement = action.payload;
            })
    },
});

export const { getList, toggleFAQStatusByIdRedux} = FAQsManagementSlice.actions;
export const selectFaqsManagement = (state: RootState) => state.faqsManagement;

export default FAQsManagementSlice.reducer;