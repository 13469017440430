import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "store";
import { addPortfolioPlanningFilter, getCriteriaTypes, getCriteriaTypesDescription, getPortfolioPlanningFilterById, getPortfolioPlanningFilters, syncPortfolioPlanningFilterById, updatePortfolioPlanningFilterById } from "./PortfolioPlanningFiltersAPI";

export interface PortfolioPlanningFiltersState {
    portfolioPlanningFilters: any;
    status: 'idle' | 'loading' | 'failed';
}

const initialState: PortfolioPlanningFiltersState = {
    portfolioPlanningFilters: [],
    status: 'idle',
}

export const getPortfolioPlanningFiltersAsync = createAsyncThunk(
    'portfolioPlanningFilters/getPortfolioPlanningFilters',
    async (params: any) => {
        const response = await getPortfolioPlanningFilters(params);
        return response.data;
    }
);

export const getPortfolioPlanningFilterByIdAsync = createAsyncThunk(
    'portfolioPlanningFilters/getPortfolioPlanningFilterById',
    async (code: string) => {
        try {
            const response = await getPortfolioPlanningFilterById(code);
            return response.data;
        } catch (error: any) {
            return {error: error.response?.data}
        } 
    }
);

export const addPortfolioPlanningFilterAsync = createAsyncThunk(
    'portfolioPlanningFilters/addPortfolioPlanningFilter',
    async (code: string) => {
        try {
            const response = await addPortfolioPlanningFilter(code);
            return response.data;
        } catch (error: any) {
            return {error: error.response?.data}
        } 
    }
);

export const updatePortfolioPlanningFilterByIdAsync = createAsyncThunk(
    'portfolioPlanningFilters/updatePortfolioPlanningFilterById',
    async (code: string) => {
        try {
            const response = await updatePortfolioPlanningFilterById(code);
            return response.data;
        } catch (error: any) {
            return {error: error.response?.data}
        } 
    }
);

export const getCriteriaTypesAsync = createAsyncThunk(
    'portfolioPlanningFilters/getCriteriaTypes',
    async () => {
        try {
            const response = await getCriteriaTypes();
            return response.data;
        } catch (error: any) {
            return {error: error.response?.data}
        } 
    }
);

export const getCriteriaTypesDescriptionAsync = createAsyncThunk(
    'portfolioPlanningFilters/getCriteriaTypesDescription',
    async () => {
        try {
            const response = await getCriteriaTypesDescription();
            return response.data;
        } catch (error: any) {
            return {error: error.response?.data}
        } 
    }
);

export const syncPortfolioPlanningFilterByIdAsync = createAsyncThunk(
    'portfolioPlanningFilters/syncPortfolioPlanningFilterById',
    async (id: string) => {
        try {
            const response = await syncPortfolioPlanningFilterById(id);
            return response.data;
        } catch (error: any) {
            return {error: error.response?.data}
        } 
    }
);

export const PortfolioPlanningFiltersSlice = createSlice({
    name: "portfolioPlanningFilters",
    initialState,
    reducers: {
        getList: (state, action: PayloadAction<undefined>) => {
            state.portfolioPlanningFilters = action.payload;
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(getPortfolioPlanningFiltersAsync.pending, (state) => {
                state.status = 'loading';
            })
            .addCase(getPortfolioPlanningFiltersAsync.fulfilled, (state, action) => {
                state.status = 'idle';
                state.portfolioPlanningFilters = action.payload;
            })
    },
});

export const { getList} = PortfolioPlanningFiltersSlice.actions;

export const selectPortfolioPlanningFilters = (state: RootState) => state.portfolioPlanningFilters;

export default PortfolioPlanningFiltersSlice.reducer;