import { HOST_URL, PORTFOLIO_PLANNING_FILTERS } from "constant/api.contant";
import axiosInstance from "services/restful.service";

export const getPortfolioPlanningFilters = (params: any) => {
    return axiosInstance.get(HOST_URL + PORTFOLIO_PLANNING_FILTERS.GET_PORTFOLIO_PLANNING_FILTERS, {params});
}

export const getPortfolioPlanningFilterById = (id: string) => {
    return axiosInstance.get(HOST_URL + PORTFOLIO_PLANNING_FILTERS.GET_PORTFOLIO_PLANNING_FILTER_BY_ID(id));
}

export const addPortfolioPlanningFilter = (params: any) => {
    return axiosInstance.post(HOST_URL + PORTFOLIO_PLANNING_FILTERS.ADD_PORTFOLIO_PLANNING_FILTERS, params);
}

export const updatePortfolioPlanningFilterById = (params: any) => {
    return axiosInstance.put(HOST_URL + PORTFOLIO_PLANNING_FILTERS.UPDATE_PORTFOLIO_PLANNING_FILTER_BY_ID(params.id), params);
}

export const getCriteriaTypes = () => {
    return axiosInstance.get(HOST_URL + PORTFOLIO_PLANNING_FILTERS.GET_CRITERIA_TYPES);
}

export const getCriteriaTypesDescription = () => {
    return axiosInstance.get(HOST_URL + PORTFOLIO_PLANNING_FILTERS.GET_CRITERIA_TYPES_DESCRIPTION);
}

export const syncPortfolioPlanningFilterById = (id: string) => {
    return axiosInstance.post(HOST_URL + PORTFOLIO_PLANNING_FILTERS.SYNC_PORTFOLIO_PLANNING_FILTERS(id));
}

