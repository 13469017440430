import LoadingBarProcess from 'components/LoadingBarProcess';
import MasterLayout from 'layouts/MasterLayout';
import FaqArticleAdd from 'pages/Faq/FaqArticle/FaqArticleAdd';
import FaqArticleManagement from 'pages/Faq/FaqArticle/FaqArticleManagement';
import React from 'react';
import { Route, Routes } from 'react-router-dom';

const FaqArticleRoutes = () => {
  return (
    <Routes>
      <Route path="/" element={<MasterLayout type="header" />}>
        <Route
          index
          element={
            <React.Suspense fallback={<LoadingBarProcess />}>
              <FaqArticleManagement />
            </React.Suspense>
          }
        />
      </Route>
      <Route path="/add" element={<MasterLayout type="header" />}>
        <Route
          index
          element={
            <React.Suspense fallback={<LoadingBarProcess />}>
              <FaqArticleAdd />
            </React.Suspense>
          }
        />
      </Route>
      <Route path="/:id" element={<MasterLayout type="header" />}>
        <Route
          index
          element={
            <React.Suspense fallback={<LoadingBarProcess />}>
              <FaqArticleAdd />
            </React.Suspense>
          }
        />
      </Route>
    </Routes>
  );
};

export default FaqArticleRoutes;
