import { HOST_URL, SURVEY_QUESTION } from "constant/api.contant";
import axiosInstance from "services/restful.service";

export const getSurveyQuestions = (params: any) => {
    return axiosInstance.get(HOST_URL + SURVEY_QUESTION.GET_SURVEY_QUESTIONS, {params});
}

export const addSurveyQuestion = (params: any) => {
    return axiosInstance.post(HOST_URL + SURVEY_QUESTION.ADD_SURVEY_QUESTION, params);
}

export const getSurveyQuestionByCode = (code: string) => {
    return axiosInstance.get(HOST_URL + SURVEY_QUESTION.GET_SURVEY_QUESTION_BY_CODE(code));
}

export const updateSurveyQuestionByCode = (params: any) => {
    return axiosInstance.put(HOST_URL + SURVEY_QUESTION.UPDATE_SURVEY_QUESTION_BY_CODE(params.code), params);
}

export const enableSurveyQuestionByCode = (code: string) => {
    return axiosInstance.put(HOST_URL + SURVEY_QUESTION.ENABLE_SURVEY_QUESTION_BY_CODE(code));
}

export const disableSurveyQuestionByCode = (code: string) => {
    return axiosInstance.put(HOST_URL + SURVEY_QUESTION.DISABLE_SURVEY_QUESTION_BY_CODE(code));
}

export const swapIndexSurveyQuestion = (params: any) => {
    return axiosInstance.put(HOST_URL + SURVEY_QUESTION.SWAP_INDEX_SURVEY_QUESTION, params);
}