import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '../..';
import { addArticle, approveArticleById, deleteArticleById, getArticleById, getArticles, rejectArticlebyId, updateArticleById } from './articlesAPI';

export interface ArticlesState {
  articles: any;
  status: 'idle' | 'loading' | 'failed';
}

const initialState: ArticlesState = {
  articles: [],
  status: 'idle',
};

export const getArticlesAsync = createAsyncThunk(
  'articles/getArticles',
  async (params?: any) => {
    const response = await getArticles(params);
    return response.data;
  }
);

export const addArticleAsync = createAsyncThunk(
  'articles/addArticle',
  async (params?: any) => {
    try {
        const response = await addArticle(params);
        return {data: response?.data, status: response?.status};
    }catch (error: any) {
        return {error: error.response?.data}
    }
  }
);

export const getArticleByIdAsync = createAsyncThunk(
    'articles/getArticleById',
    async (params?: any) => {
        try {
            const response = await getArticleById(params);
            return response.data;
        }catch (error: any) {
            return {error: error.response?.data}
        }
    }
);

export const updateArticleByIdAsync = createAsyncThunk(
  'articles/updateArticleById',
  async (params?: any) => {
    try {
        const response = await updateArticleById(params);
        
        return {data: response?.data, status: response?.status};
    }catch (error: any) {
        return {error: error.response?.data}
    }
  }
);

export const deleteArticleByIdAsync = createAsyncThunk(
  'articles/deleteArticleById',
  async (params?: any) => {
    try {
        const response = await deleteArticleById(params);
        return response.data;
    }catch (error: any) {
        return {error: error.response?.data}
    }
  }
);

export const approveArticleByIdAsync = createAsyncThunk(
  'articles/approveArticleById',
  async (params?: any) => {
    try {
        const response = await approveArticleById(params);
        return response.data;
    }catch (error: any) {
        return {error: error.response?.data}
    }
  }
);

export const rejectArticlebyIdAsync = createAsyncThunk(
  'articles/rejectArticlebyId',
  async (params?: any) => {
    try {
        const response = await rejectArticlebyId(params);
        return response.data;
    }catch (error: any) {
        return {error: error.response?.data}
    }
  }
);

export const articlesSlice = createSlice({
  name: 'articles',
  initialState,
  reducers: {
    getList: (state, action: PayloadAction<undefined>) => {
      state.articles = action.payload;
    },
    reduxDeleteArticleById: (state, action) => {
      const currentCategoryIndex = state.articles.items.findIndex((category: any) => {
        return category.id == action.payload;
      });
      state.articles.items.splice(currentCategoryIndex, 1);
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(getArticlesAsync.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(getArticlesAsync.fulfilled, (state, action) => {
        state.status = 'idle';
        state.articles = action.payload;
      })
  },
});

export const { getList, reduxDeleteArticleById } = articlesSlice.actions;
export const selectArticles = (state: RootState) => state.articles;


export default articlesSlice.reducer;