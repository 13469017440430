import { HOST_URL, ADMINS } from "constant/api.contant"; 
import axiosInstance from "./restful.service";

const AdminChangePassword = (id: string, params: any) => {
    return axiosInstance.put(HOST_URL + ADMINS.CHANGE_PASSWORD(id), params);
}

export const AdminsService = {
    AdminChangePassword
}

