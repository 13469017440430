import React from "react";
import { useAuth } from "contexts/Auth/AuthContext";
import { BiChevronRight, BiLogOut } from "react-icons/bi";
import { AiOutlineSetting } from "react-icons/ai";
import { Link } from "react-router-dom";
import PersonIcon from "assets/images/person_badge_key.png";

type ProfileDropDown = {
    close: () => void;
    showChangePass: () => void;
};

const ProfileDropDown: React.FC<ProfileDropDown> = ({ close, showChangePass }: ProfileDropDown) => {
    const auth = useAuth();
    return (
        <div className="relative-container bg-white ">
            <div className="w-[280px] absolute z-10  rounded-md top-[66px] right-[30px] bg-white drop-shadow-md pb-2">
                <div className="w-full grid drop-shadow-md">
                    <div className="flex h-[100px] bg-primary-main rounded ">
                        {/* <span className=" absolute top-6 left-6 rounded-full w-[50px] h-[50px] px-4 py-2 bg-cover" style={{ 'backgroundImage': `url(" ${auth?.user?.avatar}")` }}></span> */}
                        <div className="grid text-white">
                            <div className="text-bold text-gray absolute top-[24px] left-[16px]">
                                {auth?.user?.fullname}
                            </div>
                            <span className="block text-sm text-gray absolute left-[16px] top-[60px]">
                                {auth?.user?.email}
                            </span>
                        </div>
                    </div>
                    <div className="w-full  drop-shaw-md"></div>
                    <div className="pt-2">
                        <Link
                            to="/setting-system"
                            onClick={close}
                            className="h-[44px] flex space-x-2 items-center px-4 py-2 hover:bg-primary-main hover:text-white hover:rounded-sm"
                        >
                            <AiOutlineSetting />
                            <span className="text-sm">Cài đặt hệ thống</span>
                        </Link>
                        <div
                            className="h-[44px] flex space-x-2 items-center px-4 py-2 hover:bg-primary-main hover:text-white hover:rounded-sm cursor-pointer"
                            onClick={() => {
                                showChangePass();
                                close();
                            }}
                        >
                            <img src={PersonIcon} alt="PersonIcon" className="w-4 h-4" />
                            <span className="text-sm">Cập nhật mật khẩu</span>
                        </div>
                        <div className="w-full m-1 drop-shaw-md"></div>
                    </div>
                </div>
                <a
                    className="h-[44px] flex items-center space-x-2 px-4 py-2 hover:bg-primary-main hover:text-white hover:rounded-sm cursor-pointer"
                    onClick={() => auth.logout()}
                >
                    <BiLogOut />
                    <span className="text-sm"> Thoát tài khoản</span>
                </a>
            </div>
        </div>
    );
};

export default ProfileDropDown;
