import React, { useEffect } from 'react';
import { HiOutlineMenuAlt2, HiOutlineMenu } from 'react-icons/hi'
import { useAppDispatch, useAppSelector } from 'store/hooks';
import { selectThemeConfig, setSideNavCollapse } from 'store/slices/ThemeConfigSlice';

const SideNavToggle = () => {
    const dispatch = useAppDispatch();
    const { theme } = useAppSelector(selectThemeConfig);

    const onCollapse = () => {
      dispatch(setSideNavCollapse(!theme?.layout?.sideNavCollapse));
    };

    const handleScreenSize = () => {
      if (window.innerWidth < 1200) {
        onCollapse();
      }
    }

    useEffect(() => {
      handleScreenSize();
      window.addEventListener('resize', handleScreenSize); // thêm sự kiện resize để theo dõi sự thay đổi kích thước màn hình
      return () => {
        window.removeEventListener('resize', handleScreenSize); // loại bỏ sự kiện resize khi component bị unmount
      };
    }, [])

  return (
    <div onClick={onCollapse}>
        <div className='text-2xl header-action-item-hoverable header-action-item hover:bg-gray-e0 px-1 py-1'>
            {theme?.layout?.sideNavCollapse ? <HiOutlineMenu /> : <HiOutlineMenuAlt2 />}
        </div>
    </div>
  )
}

export default SideNavToggle