import { REFERRAL_CODE } from 'constant/api.contant';
import { ReferralCodeParams } from 'types/ReferralCode.type';
import axiosInstance from './restful.service';

export const getReferralCodes = async (params: any) => {
  return axiosInstance.get(REFERRAL_CODE(), { params }).then(res => {
    const data = res?.data;
    return data?.data;
  });
};

export const addReferralCode = async (params: ReferralCodeParams) => {
  return axiosInstance.post(REFERRAL_CODE(), params).then(res => {
    const data = res?.data;
    return data;
  });
};

export const updateReferralCode = async (
  id: string,
  params: ReferralCodeParams
) => {
  return axiosInstance.put(REFERRAL_CODE(id), params).then(res => {
    const data = res?.data;
    return data;
  });
};

export const getReferralCodeDetail = async (id: string) => {
  return axiosInstance.get(REFERRAL_CODE(id)).then(res => {
    const data = res?.data;
    return data;
  });
};

export const deleteReferralCode = async (id: string) => {
  return axiosInstance.delete(REFERRAL_CODE(id)).then(res => {
    const data = res?.data;
    return data;
  });
};
