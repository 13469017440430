import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "store";
import { destroyTransactionsById, getTransaction, getTransactionDetails} from "./transactionAPI";

export interface transactionSliceState {
    transaction: any;
    status: 'idle' | 'loading' | 'failed';
}

const initialState: transactionSliceState = {
    transaction: [],
    status: 'idle',
}

export const geTransactionAsync = createAsyncThunk(
    'deposits/geTransaction',
    async (params: any) => {
        const response = await getTransaction(params);
        return response.data;
    }
);
export const getTransactionDetailsAsync = createAsyncThunk(
    'deposits/geTransactionDettails',
    async (id: string) => {
        const response = await getTransactionDetails(id);
        return response.data;
    }
);

export const destroyTransactionsByIdAsync = createAsyncThunk(
    'deposits/destroyTransactionsById',
    async (params: any) => {
        try {
            const response = await destroyTransactionsById(params);
            return response.data;
            
        }catch (error: any) {
            return {error: error.response?.data}
        }
        
    }
);

export const transactionSlice = createSlice({
    name: "transaction",
    initialState,
    reducers: {
        getList: (state, action: PayloadAction<undefined>) => {
            state.transaction = action.payload;
        },
        toggleActionDropdownTransaction: (state, action) => {
            state.transaction.items.map((item: any) => {
                if (item.order_id == action.payload) {
                    item.action_dropdown = !item.action_dropdown;
                }
            })
        },
        changeStatusDestroyAction: (state, action) => {
            const currentTransaction = state.transaction.items.find((item: any) => {
                return item.id == action.payload.id;
            });
            currentTransaction.order_status_name = action.payload.order_status_name;
            currentTransaction.order_status = action.payload.order_status;
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(geTransactionAsync.pending, (state) => {
                state.status = 'loading';
            })
            .addCase(geTransactionAsync.fulfilled, (state, action) => {
                state.status = 'idle';
                state.transaction = action.payload;
                state.transaction.items.map((item: any) => {
                    item = {...item, action_dropdown: false};
                })
            })
    },
});

export const { getList, toggleActionDropdownTransaction, changeStatusDestroyAction} = transactionSlice.actions;
export const selectTransaction = (state: RootState) => state.transaction;

export default transactionSlice.reducer;