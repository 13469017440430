/*eslint no-control-regex: "off"*/
export const FORM_CONST = {
  FORGOT_PASSWORD: 'Enter your registered email below to recieve password reset instructions',
  PASSWORD_RESET_SUCCESS: 'Your password was reset successfully!',
  IS_REQUIRED: 'This field is required',
  MAX_LENGTH: 'You exceeded the max length',
  MIN_LENGTH: 'Password must have at least 6 characters',
  PASSWORD_NOT_MATCH: "Password doesn't match",
  EMAIL_VALIDATE: 'Email is invalid',
  PASSWORD_VALIDATE:
    'Password must at have least 10 characters, at least one capital letter, at least one number AND at least one special character',
  NUMBER_VALIDATE: 'Number only',
  PASSWORD_REGEX:
    /^(?=.*[a-z])(?=.*[A-Z])(?=.*[~!@#$%^&*_\-+=`|\\(){}[\]:;\"\'<>,.?/])(?=.*\d)[A-Za-z\d~!@#$%^&*_\-+=`|\\(){}[\]:;\"\'<>,.?/]{10,64}$/,
  EMAIL_REGEX:
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
  NUMBER_REGEX: /^[0-9]*$/,
};

export const FORM_FORMAT = {
  TABLE_DATE: 'DD/MM/YY',
  TABLE_DATEFULLYEAR: 'DD/MM/YYYY',
  TABLE_DATETIME: 'DD/MM/YY HH:mm:ss',
};

export const AUTHENTICATION = {
  UNAUTHORIZED: 'Incorrect email or password',
  TOO_MANY_REQUESTS:
    'You are making too many requests. You will be frozen in action for a short time',
};

export const STYLE_EDITOR = {
  BLOG: `
    body {
      margin: 0;
      padding: 0;
      font-family: Plus Jakarta Sans, sans-serif;
      font-size:15px;
    }
    p{
      margin:0;
      font-size:15px;
      line-height: 18px;
    }
    h1{
      font-size: 28px;
      line-height: 33px;
    }
    h2{
      font-size:20px;
      line-height: 26px;
    }
    h3{
      font-size:19px;
      line-height: 26px;
    }
  `,
  FASHCARD: `
    body {
      margin: 0;
      padding: 0;
      font-family: 'Plus Jakarta Sans';
      font-size: 19px;
    }
    p{
        font-size:19px;
        line-height: 26px;
    }
    h1{
        font-size: 28px;
        line-height: 35px;
        margin-bottom: 40px;
    }
    h2{
        font-size:20px;
        line-height: 26px;
    }
    h3{
        font-size:19px;
        line-height: 26px;
    }
  `,
};

export const INIT_EDITOR_DEFAULT = `<p style="display: none;">
<title></title>
<meta content="text/html; charset=utf-8" http-equiv="Content-Type">
<meta content="width=device-width, initial-scale=1.0" name="viewport">
<link href="https://fonts.googleapis.com/css?family=Plus Jakarta Sans" rel="stylesheet">
<style>
    body {
      margin: 0;
      padding: 0;
      font-family: 'Plus Jakarta Sans';
      font-size:15px;
    }
    a {
      text-decoration: none;
      color:#0C66E4;
    }
    p{
      font-size:15px;
      text-align:left;
      line-height: 18px;
      font-family: "Plus Jakarta Sans";
    }
    h1{
      font-size: 28px;
      line-height: 33px;
      text-align:left;
      font-family: "Plus Jakarta Sans";
    }
    h2{
      font-size:20px;
      text-align:left;
      font-family: "Plus Jakarta Sans";
      line-height: 26px;
    }
    h3{
      font-size:19px;
      text-align:left;
      font-family: "Plus Jakarta Sans";
      line-height: 26px;
    }
    table > tbody > tr > td {
      border-style: solid; 
      border-color: gray; 
      border-width: 2px;
      text-align: center;
      font-size: 9px;
      font-family: "Plus Jakarta Sans";
    }
    * {
      -webkit-user-select: none; /* Safari */
      -ms-user-select: none; /* IE 10 and IE 11 */
      user-select: none; /* Standard syntax */
    }
</style>
</p>
<p>Text Here</p>`;

export const INIT_FLASHCARD_EDITOR_DEFAULT = `<p style="display: none;">
<title></title>
<meta content="text/html; charset=utf-8" http-equiv="Content-Type">
<meta content="width=device-width, initial-scale=1.0" name="viewport">
<link href="https://fonts.googleapis.com/css?family=Plus Jakarta Sans" rel="stylesheet">
<style>
    body {
      margin: 0;
      padding: 0;
      font-family: 'Plus Jakarta Sans';
      font-size:15px;
    }
    a {
      text-decoration: none;
      color:#0C66E4;
    }
    p{
      font-size:19px;
      text-align:left;
      line-height: 26px;
      font-family: "Plus Jakarta Sans";
    }
    h1{
      font-size: 28px;
      line-height: 35px;
      text-align:left;
      font-family: "Plus Jakarta Sans";
      margin-bottom: 40px;
    }
    h2{
      font-size:20px;
      text-align:left;
      font-family: "Plus Jakarta Sans";
      line-height: 26px;
    }
    h3{
      font-size:19px;
      text-align:left;
      font-family: "Plus Jakarta Sans";
      line-height: 26px;
    }
    table > tbody > tr > td {
      border-style: solid; 
      border-color: gray; 
      border-width: 2px;
      text-align: center;
      font-size: 9px;
      font-family: "Plus Jakarta Sans";
    }
    * {
      -webkit-user-select: none; /* Safari */
      -ms-user-select: none; /* IE 10 and IE 11 */
      user-select: none; /* Standard syntax */
    }
</style>
</p>
<p>Text Here</p>
`;

export const INIT_NOTIFICATION_EMMAIL_CONTENT = `
<div style="max-width: 680px; width: 100%; margin: 0 auto;">
<p style="display: none;">
<title></title>
<meta content="text/html; charset=utf-8" http-equiv="Content-Type">
<meta content="width=device-width, initial-scale=1.0" name="viewport">
<link href="https://fonts.googleapis.com/css?family=Plus Jakarta Sans" rel="stylesheet">
<style>
    body {
      margin: 0;
      padding: 0;
      font-family: 'Plus Jakarta Sans';
    }
    a {
      text-decoration: none;
      color:#0C66E4;
    }
    tr > th, tr > td {
      border: 1px solid #e5e5e5;
    }
    p{
      font-family: "Plus Jakarta Sans";
    }
    h1{
      font-family: "Plus Jakarta Sans";
    }
    h2{
      font-family: "Plus Jakarta Sans";
    }
    h3{
      font-family: "Plus Jakarta Sans";
    }
    * {
      -webkit-user-select: none; /* Safari */
      -ms-user-select: none; /* IE 10 and IE 11 */
      user-select: none; /* Standard syntax */
    }
</style>
</p>
  <div>
      <p>Nội dung</p>
  </div>
  <div style="display: flex; justify-content: center; align-items: center; margin-top: 20px">
    <a href="https://afclick.tititada.com/QA53/k5csmruy" target="_blank" style="cursor: pointer; color: #000000 !important;">
      <div style="width: 160px; height: 38px; background-color: #0BA5EC; font-size: 14px; font-weight: 700; display: flex; justify-content: center; align-items: center; border-radius: 4px;">👉 Vào app ngay!</div>
    </a>
  </div>
  <div style="width: 100%; background-color: #0BA5EC; padding: 20px; margin-top: 20px">
      <div style="display: flex; justify-content: space-between; gap: 4px; padding-top: 20px">
          <div>
              <a href="https://tititada.com/" target="_blank" style="cursor: pointer;">
                  <img src="https://statics.tititada.com/News/20231013/4bbdab0d-acb1-430e-a70d-9869ba12708f_114135" width="140px">
              </a>
          </div>
          <div style="display: flex; gap: 4px; padding-top:4px">
              <a href="https://apps.apple.com/vn/app/tititada-%C4%91%E1%BA%A7u-t%C6%B0-ch%E1%BB%A9ng-kho%C3%A1n/id1606140975" target="_blank" style="cursor: pointer;">
                  <img src="https://statics.tititada.com/News/20231013/8191c628-16d1-4d14-aca0-c84026249954_114144" width="100px">
              </a>
              <a href="https://play.google.com/store/apps/details?id=vn.tititada.trading" target="_blank" style="cursor: pointer;">
                  <img src="https://statics.tititada.com/News/20231013/675c11bb-6eb1-4880-9821-256881243a6f_114152" width="100px">
              </a>
          </div>
      </div>
      <div style="margin-top: 40px; font-size: 14px; font-weight: 500;">
          © Tititada 2023
      </div>
      <div style="margin-top: 8px; margin-bottom: 40px; font-size: 14px; font-weight: 500;">
          Tầng 10, Tòa nhà FiveStar, Số 28bis Mạc Đĩnh Chi, Phường Đa Kao, Quận 1, TP. Hồ Chí Minh
      </div>

      <div style="padding-top: 40px; padding-bottom: 20px; border-top: 1px solid #495057;">
          <div style="display: flex; gap: 8px;">
              <a href="https://www.facebook.com/tititadavn" target="_blank" style="cursor: pointer;">
                  <img src="https://statics.tititada.com/News/20231013/467bea69-7514-47ce-8bd1-f1343c881dba_132830" width="40px">
              </a>
              <a href="https://www.tiktok.com/@tititada_vn" target="_blank" style="cursor: pointer;">
                  <img src="https://statics.tititada.com/News/20231013/b8e007b4-d817-4178-9599-9c9401f92811_114206" width="40px">
              </a>
              <a href="https://www.linkedin.com/company/tititada/" target="_blank" style="cursor: pointer;">
                  <img src="https://statics.tititada.com/News/20231013/c9b58b66-55b2-4fe5-9617-ed4f5c290ece_114212" width="40px">
              </a>
              <a href="https://www.youtube.com/@tititada6777" target="_blank" style="cursor: pointer;">
                  <img src="https://statics.tititada.com/News/20231013/100ce9ad-e034-4557-877a-e50f825338d4_114217" width="40px">
              </a>
          </div>
      </div>
  </div>
</div>
`

export const STYLE_EDITOR_DEFAULT = {
  IMG_TYPE: [
    'image/apng',
    'image/avif',
    'image/bmp',
    'image/gif',
    'image/jpeg',
    'image/jpg',
    'image/png',
    'image/svg+xml',
    'image/tiff',
    'image/webp',
    'image/x-icon',
  ],
  VIDEO_TYPE: [
    'video/mp4',
    'video/webm',
    'video/ogg',
    'video/quicktime',
    'video/x-msvideo',
    'video/x-matroska',
    'video/x-ms-wmv',
  ],
};
