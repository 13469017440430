import React from 'react';

export const AlertIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="26"
      height="26"
      viewBox="0 0 26 26"
      fill="none"
    >
      <path
        d="M13 23.8332C7.01671 23.8332 2.16663 18.9831 2.16663 12.9998C2.16663 7.01659 7.01671 2.1665 13 2.1665C18.9832 2.1665 23.8333 7.01659 23.8333 12.9998C23.8333 18.9831 18.9832 23.8332 13 23.8332ZM13 21.6665C15.2985 21.6665 17.5029 20.7534 19.1282 19.1281C20.7535 17.5028 21.6666 15.2984 21.6666 12.9998C21.6666 10.7013 20.7535 8.49689 19.1282 6.87158C17.5029 5.24626 15.2985 4.33317 13 4.33317C10.7014 4.33317 8.49702 5.24626 6.8717 6.87158C5.24639 8.49689 4.33329 10.7013 4.33329 12.9998C4.33329 15.2984 5.24639 17.5028 6.8717 19.1281C8.49702 20.7534 10.7014 21.6665 13 21.6665ZM11.9166 16.2498H14.0833V18.4165H11.9166V16.2498ZM11.9166 7.58317H14.0833V14.0832H11.9166V7.58317Z"
        fill="#F04438"
      />
    </svg>
  );
};
