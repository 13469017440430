import React, { useState } from 'react';
import styles from './index.module.scss';
import { NavBarItem } from './NavBarItem';
import { FaUserCog, GiTwoCoins } from 'assets/icons';
import { useLocation } from 'react-router-dom';
import { BiChevronRight } from 'react-icons/bi';
import { PERMISSIONS_KEY } from 'constant';

export const NavBar: React.FC = () => {
  const location = useLocation();
  const newPathname = location.pathname.split('/');
  const [isShowAccount, setIsShowAccount] = useState<any>(() => {
    if (newPathname[1] === 'account-management') {
      return true;
    } else {
      return false;
    }
  });

  const [isShowMoney, setIsShowMoney] = useState<any>(() => {
    if (
      newPathname[1] === 'deposit-management' ||
      newPathname[1] === 'withdraw-management' ||
      newPathname[1] === 'approve-withdraw-management'
    ) {
      return true;
    } else {
      return false;
    }
  });

  const [isShowFaq, setIsShowFaq] = useState<any>(() => {
    if (
      newPathname[1] === 'faq-category-management' ||
      newPathname[1] === 'faq-article-management'
    ) {
      return true;
    } else {
      return false;
    }
  });

  const showSubmenu = (name?: string) => {
    if (name === 'accountManagement') {
      setIsShowAccount(!isShowAccount);
    }
    if (name === 'moneyManagement') {
      setIsShowMoney(!isShowMoney);
    }
    if (name === 'faqManagement') {
      setIsShowFaq(!isShowFaq);
    }
  };

  return (
    <>
      <div className={`${styles.navbar} overflow-y-auto min-h-[200px] pb-4`}>
        <NavBarItem
          position="I"
          label="DASHBOARD"
          name={PERMISSIONS_KEY.Dashboard}
          icon={() => <></>}
          url="/"
          isShowSubmenu={showSubmenu}
          selfClassName={'!font-bold'}
        />
        <NavBarItem
          position="II"
          label="QUẢN LÝ TÀI CHÍNH"
          icon={() => <></>}
          url=""
          isShowSubmenu={showSubmenu}
          selfClassName={'!font-bold'}
        />
        <NavBarItem
          position="1"
          label="Quản Lý Tiền"
          name={PERMISSIONS_KEY.ManageCash}
          icon={() => <GiTwoCoins />}
          url=""
          childrenUrl={['/withdraw-management/*', '/deposit-management/*']}
          isShowSubmenu={showSubmenu}
          nameToggleMenu="moneyManagement"
          isRotateIcon={isShowMoney}
          selfClassName={'!pl-2'}
        />
        {isShowMoney && (
          <NavBarItem
            position="1.1"
            label="Nạp tiền"
            name={PERMISSIONS_KEY.ManageDeposit}
            icon={() => <BiChevronRight />}
            url="/deposit-management"
            childrenUrl={['/deposit-management/*']}
            isShowSubmenu={showSubmenu}
            selfClassName={'!pl-5'}
          />
        )}
        {isShowMoney && (
          <NavBarItem
            position="1.2"
            label={`Rút tiền`}
            name={PERMISSIONS_KEY.ManageWithdrawal}
            icon={() => <BiChevronRight />}
            url="/withdraw-management"
            childrenUrl={['/withdraw-management/*']}
            isShowSubmenu={showSubmenu}
            selfClassName={'!pl-5'}
          />
        )}
        {isShowMoney && (
          <NavBarItem
            position="1.3"
            label="Giao dịch cần duyệt"
            name={PERMISSIONS_KEY.ManageTransaction}
            icon={() => <BiChevronRight />}
            url="/approve-withdraw-management"
            childrenUrl={['/approve-withdraw-management/*']}
            isShowSubmenu={showSubmenu}
            selfClassName={'!pl-5'}
          />
        )}
        <NavBarItem
          position="2"
          label="Quản Lý File Đối Soát"
          name={PERMISSIONS_KEY.ManageReconciliationFile}
          icon={() => <FaUserCog />}
          url="/tcb-report-files-management"
          childrenUrl={[]}
          isShowSubmenu={showSubmenu}
          selfClassName={'!pl-2'}
        />
        <NavBarItem
          position="III"
          label="QUẢN LÝ TÀI KHOẢN"
          icon={() => <></>}
          url=""
          isShowSubmenu={showSubmenu}
          selfClassName={'!font-bold'}
        />
        <NavBarItem
          position="1"
          label="Quản Lý Tài Khoản"
          name={PERMISSIONS_KEY.ManageAccounts}
          icon={() => <FaUserCog />}
          url="/account-management"
          childrenUrl={['/account-management*']}
          isShowSubmenu={showSubmenu}
          nameToggleMenu="accountManagement"
          isRotateIcon={isShowAccount}
          selfClassName={'!pl-2'}
        />
        <NavBarItem
          position="2"
          label="Quản Lý Người Dùng"
          name={PERMISSIONS_KEY.ManageUsers}
          icon={() => <FaUserCog />}
          url="/user-management"
          childrenUrl={['/user-management*']}
          isShowSubmenu={showSubmenu}
          nameToggleMenu="userManagement"
          isRotateIcon={isShowAccount}
          selfClassName={'!pl-2'}
        />
        <NavBarItem
          position="IV"
          label="QUẢN LÝ TÍNH NĂNG"
          icon={() => <></>}
          url=""
          isShowSubmenu={showSubmenu}
          selfClassName={'!font-bold'}
        />
        <NavBarItem
          position="1"
          label="Quản Lý Thông Tin Công Bố"
          name={PERMISSIONS_KEY.AnnounceInformation}
          icon={() => <FaUserCog />}
          url="/announced-information-management"
          childrenUrl={['/announced-information-management*']}
          isShowSubmenu={showSubmenu}
          selfClassName={'!pl-2'}
        />
        <NavBarItem
          position="2"
          label="Quản Lý Banner"
          name={PERMISSIONS_KEY.ManageBanners}
          icon={() => <FaUserCog />}
          url="/banner-management"
          childrenUrl={['/banner-management*']}
          isShowSubmenu={showSubmenu}
          selfClassName={'!pl-2'}
        />
        <NavBarItem
          position="3"
          label="FAQ"
          icon={() => <GiTwoCoins />}
          url=""
          childrenUrl={[
            '/faq-category-management/*',
            '/faq-article-management/*'
          ]}
          nameToggleMenu="faqManagement"
          isShowSubmenu={showSubmenu}
          selfClassName={'!pl-2'}
          isRotateIcon={isShowFaq}
        />
        {isShowFaq && (
          <NavBarItem
            position="3.1"
            label="Category"
            name={PERMISSIONS_KEY.ManageFaqCategory}
            icon={() => <BiChevronRight />}
            url="/faq-category-management"
            childrenUrl={['/faq-category-management/*']}
            isShowSubmenu={showSubmenu}
            selfClassName={'!pl-5'}
          />
        )}
        {isShowFaq && (
          <NavBarItem
            position="3.2"
            label={`Bài viết`}
            name={PERMISSIONS_KEY.ManageFaqPost}
            icon={() => <BiChevronRight />}
            url="/faq-article-management"
            childrenUrl={['/faq-article-management/*']}
            isShowSubmenu={showSubmenu}
            selfClassName={'!pl-5'}
          />
        )}
        <NavBarItem
          position="4"
          label="Quản Lý Mã Môi Giới"
          name={PERMISSIONS_KEY.ManageReferralCode}
          icon={() => <FaUserCog />}
          url="/referral-code-management"
          childrenUrl={['/referral-code-management*']}
          isShowSubmenu={showSubmenu}
          selfClassName={'!pl-2'}
        />

        <NavBarItem
          position="5"
          label="Quản Lý Tin Tức Sự Kiện"
          name={PERMISSIONS_KEY.ManageNewEvents}
          icon={() => <FaUserCog />}
          url="/news-management"
          childrenUrl={['/news-management*']}
          isShowSubmenu={showSubmenu}
          selfClassName={'!pl-2'}
        />
        <NavBarItem
          position="V"
          label="QUẢN LÝ HỆ THỐNG"
          icon={() => <></>}
          url=""
          isShowSubmenu={showSubmenu}
          selfClassName={'!font-bold'}
        />
        <NavBarItem
          position="1"
          label="Vận Hành"
          name={PERMISSIONS_KEY.ManageDE}
          icon={() => <FaUserCog />}
          url="/de-management"
          childrenUrl={['/de-management*']}
          isShowSubmenu={showSubmenu}
          selfClassName={'!pl-2'}
        />
      </div>
    </>
  );
};
