import React from 'react';
import { cloneElement } from 'react';
import { usePermissions, PERMISSIONS_ACTIONS } from 'hooks';
import { PERMISSIONS_KEY } from 'constant';

export default function PermissionsGate({
  children,
  RenderError = () => <></>,
  errorProps = null,
  name = '',
  action = PERMISSIONS_ACTIONS.VIEW
}: {
  children: any;
  RenderError?: any;
  errorProps?: any;
  name?: string;
  action?: string;
}) {
  const { check } = usePermissions();

  if (name) {
    const permissionGranted =
      name === PERMISSIONS_KEY.ManageTransaction ||
      name === PERMISSIONS_KEY.ManageCash
        ? check(PERMISSIONS_KEY.ManageDeposit, action) ||
          check(PERMISSIONS_KEY.ManageWithdrawal, action)
        : check(name, action);
    if (!permissionGranted && !errorProps) return <RenderError />;
    if (!permissionGranted && errorProps)
      return cloneElement(children, { ...errorProps });
    return <>{children}</>;
  } else {
    return <>{children}</>;
  }
}
