import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "store";
import { broadcastNotification, getListBroadcastTemplate, getListNotifications } from "./NotificationsManagementAPI";

export interface notificationsManagementState {
    notificationsManagement: any;
    status: 'idle' | 'loading' | 'failed';
}

const initialState: notificationsManagementState = {
    notificationsManagement: [],
    status: 'idle',
}

export const getListNotificationsAsync = createAsyncThunk(
    'notificationsManagement/getListNotifications',
    async (params: any) => {
        const response = await getListNotifications(params);
        return response.data;
    }
);

export const getListBroadcastTemplateAsync = createAsyncThunk(
    'notificationsManagement/getListBroadcastTemplate',
    async () => {
        try {
            const response = await getListBroadcastTemplate();
            return response.data;
        } catch (error: any) {
            return {error: error.response?.data}
        } 
    }
);

export const broadcastNotificationAsync = createAsyncThunk(
    'notificationsManagement/broadcastNotification',
    async (params: any) => {
        try {
            const response = await broadcastNotification(params);
            return response.data;
        } catch (error: any) {
            return {error: error.response?.data}
        } 
    }
);

export const NotificationsManagementSlice = createSlice({
    name: "notificationsManagement",
    initialState,
    reducers: {
       getList: (state, action: PayloadAction<undefined>) => {
            state.notificationsManagement = action.payload;
        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(getListNotificationsAsync.pending, (state) => {
                state.status = 'loading';
            })
            .addCase(getListNotificationsAsync.fulfilled, (state, action) => {
                state.status = 'idle';
                state.notificationsManagement = action.payload;
            })
    },
});

export const { getList} = NotificationsManagementSlice.actions;

export const selectNotifications = (state: RootState) => state.notificationsManagement;

export default NotificationsManagementSlice.reducer;