import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '../..';
import { getBondTransactions } from './bondTransactionsAPI';


export interface BondTransactionsState {
  bondTransactions: any;
  status: 'idle' | 'loading' | 'failed';
}

const initialState: BondTransactionsState = {
  bondTransactions: [],
  status: 'idle',
};

export const getBondTransactionsAsync = createAsyncThunk(
  'bondTransactions/getBondTransactions',
  async (params?: any) => {
    const response = await getBondTransactions(params);
    return response.data;
  }
);

export const bondTransactionsSlice = createSlice({
  name: 'bondTransaction',
  initialState,
  reducers: {
    getList: (state, action: PayloadAction<undefined>) => {
      state.bondTransactions = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getBondTransactionsAsync.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(getBondTransactionsAsync.fulfilled, (state, action) => {
        state.status = 'idle';
        state.bondTransactions = action.payload;
      })
  },
});

export const { getList } = bondTransactionsSlice.actions;
export const selectBondTransactions = (state: RootState) => state.bondTransactions;


export default bondTransactionsSlice.reducer;