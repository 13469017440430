import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "store";
import { getSuggestionAssets, updateSuggestionAssetById } from "./PortfolioPlanningSuggestionAssetsAPI";

export interface PortfolioPlanningSuggestionAssetsState {
    portfolioPlanningSuggestionAssets: any;
    status: 'idle' | 'loading' | 'failed';
}

const initialState: PortfolioPlanningSuggestionAssetsState = {
    portfolioPlanningSuggestionAssets: [],
    status: 'idle',
}

export const getSuggestionAssetsAsync = createAsyncThunk(
    'portfolioPlanningSuggestionAssets/getSuggestionAssets',
    async (params: any) => {
        const response = await getSuggestionAssets(params);
        return response.data;
    }
);

export const updateSuggestionAssetByIdAsync = createAsyncThunk(
    'portfolioPlanningSuggestionAssets/updateSuggestionAssetById',
    async (params: any) => {
        try {
            const response = await updateSuggestionAssetById(params);
            return response.data;
        } catch (error: any) {
            return {error: error.response?.data}
        } 
    }
);

export const PortfolioPlanningSuggestionAssetsSlice = createSlice({
    name: "portfolioPlanningSuggestionAssets",
    initialState,
    reducers: {
       getList: (state, action: PayloadAction<undefined>) => {
            state.portfolioPlanningSuggestionAssets = action.payload;
        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(getSuggestionAssetsAsync.pending, (state) => {
                state.status = 'loading';
            })
            .addCase(getSuggestionAssetsAsync.fulfilled, (state, action) => {
                state.status = 'idle';
                state.portfolioPlanningSuggestionAssets = action.payload;
            })
    },
});

export const { getList} = PortfolioPlanningSuggestionAssetsSlice.actions;

export const selectPortfolioPlanningSuggestionAssets = (state: RootState) => state.portfolioPlanningSuggestionAssets;

export default PortfolioPlanningSuggestionAssetsSlice.reducer;