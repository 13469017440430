import * as React from "react";
import {
  Routes,
  Route
} from "react-router-dom";
import MasterLayout from 'layouts/MasterLayout/';
import LoadingBarProcess from "components/LoadingBarProcess";
import WithdrawManagementDetails from "pages/WithdrawManagement/WithdrawManagementDetails";

const WithdrawManagementPage = React.lazy(() => import("../../src/pages/WithdrawManagement/index"));

const WithdrawManagementRoutes = () => {
  return (
    <Routes>
      <Route path="/" element={<MasterLayout type="header" />}>
        <Route 
          path="" 
          element={
            <React.Suspense fallback={<LoadingBarProcess />}>
              <WithdrawManagementPage />
            </React.Suspense>
          }
        />
        <Route 
          path=":id" 
          element={
            <React.Suspense fallback={<LoadingBarProcess />}>
                <WithdrawManagementDetails />
            </React.Suspense>} 
        />
      </Route>
    </Routes>
  )
}

export default WithdrawManagementRoutes
