import * as React from 'react';
import {
    Routes,
    Route
  } from "react-router-dom";
import MasterLayout from 'layouts/MasterLayout/';
import LoadingBarProcess from "components/LoadingBarProcess";

const DepositManagementPage = React.lazy(() => import("./../pages/DepositManagement"));
const DepositManagementDetailsPage = React.lazy(() => import("./../pages/DepositManagement/DepositManagementDetails"));
const DepositManagementEditPage = React.lazy(() => import("./../pages/DepositManagement/DepositManagementEdit"));
const DepositManagementRefundPage = React.lazy(() => import("./../pages/DepositManagement/DepositManagementRefund"));

const DepositManagementRoutes = () => {
  return (
    <Routes>
        <Route path="/" element={<MasterLayout type="header" />}>
            <Route 
                index
                element={
                    <React.Suspense fallback={<LoadingBarProcess />}>
                        <DepositManagementPage />
                    </React.Suspense>} 
            />
            <Route 
                path=":id" 
                element={
                  <React.Suspense fallback={<LoadingBarProcess />}>
                      <DepositManagementDetailsPage />
                  </React.Suspense>} 
            />
            <Route 
                path=":id/edit" 
                element={
                  <React.Suspense fallback={<LoadingBarProcess />}>
                      <DepositManagementEditPage />
                  </React.Suspense>} 
            />
            <Route 
                path=":id/refund" 
                element={
                  <React.Suspense fallback={<LoadingBarProcess />}>
                      <DepositManagementRefundPage />
                  </React.Suspense>} 
            />
        </Route>
    </Routes>
  )
}

export default DepositManagementRoutes
