import LoadingBarProcess from 'components/LoadingBarProcess';
import MasterLayout from 'layouts/MasterLayout';
import NewsManagementAdd from 'pages/NewsManagement/NewsManagementAdd';
import NewsManagement from 'pages/NewsManagement';
import React from 'react';
import { Route, Routes } from 'react-router-dom';

const NewsManagementRoutes = () => {
  return (
    <Routes>
      <Route path="/" element={<MasterLayout type="header" />}>
        <Route
          index
          element={
            <React.Suspense fallback={<LoadingBarProcess />}>
              <NewsManagement />
            </React.Suspense>
          }
        />
      </Route>
      <Route path="/add" element={<MasterLayout type="header" />}>
        <Route
          index
          element={
            <React.Suspense fallback={<LoadingBarProcess />}>
              <NewsManagementAdd />
            </React.Suspense>
          }
        />
      </Route>
      <Route path="/edit/:id" element={<MasterLayout type="header" />}>
        <Route
          index
          element={
            <React.Suspense fallback={<LoadingBarProcess />}>
              <NewsManagementAdd />
            </React.Suspense>
          }
        />
      </Route>
    </Routes>
  );
};

export default NewsManagementRoutes;
