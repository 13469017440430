import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "store";
import { getAccountRights } from "./AccountRightsAPI";

export interface AccountRightsSliceState {
    accountRights: any;
    status: 'idle' | 'loading' | 'failed';
}

const initialState: AccountRightsSliceState = {
    accountRights: [],
    status: 'idle',
}

export const getAccountRightsAsync = createAsyncThunk(
    'accountRights/getAccountRights',
    async (params: any) => {
        const response = await getAccountRights(params);
        return response.data;
    }
);

export const AccountRightsSlice = createSlice({
    name: "accountRights",
    initialState,
    reducers: {
       getList: (state, action: PayloadAction<undefined>) => {
            state.accountRights = action.payload;
        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(getAccountRightsAsync.pending, (state) => {
                state.status = 'loading';
            })
            .addCase(getAccountRightsAsync.fulfilled, (state, action) => {
                state.status = 'idle';
                state.accountRights = action.payload;
            })
    },
});

export const { getList} = AccountRightsSlice.actions;

export const selectAccountRights = (state: RootState) => state.accountRights;

export default AccountRightsSlice.reducer;