import { FAQ_PORTAL } from 'constant/api.contant';
import axiosInstance from './restful.service';

// category

const getFaqCategoryList = async (params: any) => {
  return axiosInstance
    .get(FAQ_PORTAL.GET_LIST_CATEGORY, { params })
    .then(res => {
      const data = res?.data;
      return data;
    });
};

const addFaqCategory = async (params: any) => {
  return axiosInstance.post(FAQ_PORTAL.ADD_CATEGORY, params).then(res => {
    const data = res?.data;
    return data;
  });
};

const updateFaqCategory = async (id: string, params: any) => {
  return axiosInstance.put(FAQ_PORTAL.UPDATE_CATEGORY(id), params).then(res => {
    const data = res?.data;
    return data;
  });
};

const getFaqCategoryDetail = async (id: string) => {
  return axiosInstance.get(FAQ_PORTAL.GET_DETAIL_CATEGORY(id)).then(res => {
    const data = res?.data;
    return data;
  });
};

const deleteFaqCategory = async (id: string) => {
  return axiosInstance.delete(FAQ_PORTAL.DELETE_CATEGORY(id)).then(res => {
    const data = res?.data;
    return data;
  });
};

const changeStatusFaqCategory = async (id: string, params: any) => {
  return axiosInstance.put(FAQ_PORTAL.STATUS_CATEGORY(id), params).then(res => {
    const data = res?.data;
    return data;
  });
};

// article

const getFaqArticleList = async (params: any) => {
  return axiosInstance
    .get(FAQ_PORTAL.GET_LIST_ARTICLE, { params })
    .then(res => {
      const data = res?.data;
      return data;
    });
};

const addFaqArticle = async (params: any) => {
  return axiosInstance.post(FAQ_PORTAL.ADD_ARTICLE, params).then(res => {
    const data = res?.data;
    return data;
  });
};

const updateFaqArticle = async (id: string, params: any) => {
  return axiosInstance.put(FAQ_PORTAL.UPDATE_ARTICLE(id), params).then(res => {
    const data = res?.data;
    return data;
  });
};

const getFaqArticleDetail = async (id: string) => {
  return axiosInstance.get(FAQ_PORTAL.DETAIL_ARTICLE(id)).then(res => {
    const data = res?.data;
    return data;
  });
};

const deleteFaqArticle = async (id: string) => {
  return axiosInstance.delete(FAQ_PORTAL.DELETE_ARTICLE(id)).then(res => {
    const data = res?.data;
    return data;
  });
};

export const faqService = {
  getFaqCategoryList,
  addFaqCategory,
  updateFaqCategory,
  deleteFaqCategory,
  changeStatusFaqCategory,
  getFaqArticleList,
  addFaqArticle,
  updateFaqArticle,
  getFaqCategoryDetail,
  deleteFaqArticle,
  getFaqArticleDetail
};
