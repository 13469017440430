import { BANNER } from 'constant/api.contant';
import { BannerParams } from 'types/Banner.type';
import axiosInstance from './restful.service';

export const getBannerList = async (params: any) => {
  return axiosInstance.get(BANNER(), { params }).then(res => {
    const data = res?.data;
    return data?.data;
  });
};

export const addBanner = async (params: BannerParams) => {
  return axiosInstance.post(BANNER(), params).then(res => {
    const data = res?.data;
    return data;
  });
};

export const updateBanner = async (id: string, params: BannerParams) => {
  return axiosInstance.put(BANNER(id), params).then(res => {
    const data = res?.data;
    return data;
  });
};

export const getBannerDetail = async (id: string) => {
  return axiosInstance.get(BANNER(id)).then(res => {
    const data = res?.data;
    return data;
  });
};

export const deleteBanner = async (id: string) => {
  return axiosInstance.delete(BANNER(id)).then(res => {
    const data = res?.data;
    return data;
  });
};
