import React, { ReactNode } from "react";

type Titleb600z14l20Props = {
    addClass?: string;
    children: ReactNode;
};

export const Titleb600z14l20 = ({ addClass, children }: Titleb600z14l20Props) => {
    return <div className={`text-sm font-semibold leading-5 ${addClass}`}>{children}</div>;
};

export const Titleb700z14l20 = ({ addClass, children }: Titleb600z14l20Props) => {
    return <div className={`text-sm font-bold leading-5 ${addClass}`}>{children}</div>;
};
