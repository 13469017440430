import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "store";
import { getWithdraws, getWithdrawDetail, approveWithDraw, exportData } from "./withdrawsAPI";

export interface WithdrawsStage {
    withdraws: any;
    status: 'idle' | 'loading' | 'failed';
}

const initialState: WithdrawsStage = {
    withdraws: [],
    status: 'idle',
}

export const getWithdrawsAsync = createAsyncThunk(
    'withdraws/getWithdraws',
    async (params: any) => {
        const response = await getWithdraws(params);
        return response.data;
    }
);

export const getWithdrawDetailsAsync = createAsyncThunk(
    'withdraws/getWithdrawDetails',
    async (id: string) => {
        const response = await getWithdrawDetail(id);
        return response?.data?.data;
    }
);

export const approveWithDrawAsync = createAsyncThunk(
    'withdraws/approveWithDraw',
    async (id: string) => {
        const response = await approveWithDraw(id);
        return response?.data;
    }
);

export const exportDataAsync = createAsyncThunk(
    'withdraws/exportData',
    async (params: any) => {
        try {
            const response : any = await exportData(params);
            return response;
        }catch (error: any) {
            return {error: error.response?.data}
        }
    }

);


// export const exportExcelFailWithdrawAsync = createAsyncThunk(
//     'withdraws/exportExcelFailWithdraw',
//     async (params: any) => {
//         try {
//             const response = await exportExcelFailWithdraw(params);
//             console.log(response.);
//             return response;
//         }catch (error: any) {
//             return {error: error.response?.data}
//         }
//     }
// );

export const withdrawsSlice = createSlice({
    name: "withdraws",
    initialState,
    reducers: {
        getList: (state, action: PayloadAction<undefined>) => {
            state.withdraws = action.payload;
        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(getWithdrawsAsync.pending, (state) => {
                state.status = 'loading';
            })
            .addCase(getWithdrawsAsync.fulfilled, (state, action) => {
                state.status = 'idle';
                state.withdraws = action.payload;
            })
    },
});

export const { getList} = withdrawsSlice.actions;
export const selectWithdraws = (state: RootState) => state.withdraws;

export default withdrawsSlice.reducer;