import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "store";
import { getConfigMatrixRisks, updateConfigMatrixRiskById } from "./PortfolioPlanningConfigMatrixRisksAPI";

export interface PortfolioPlanningConfigMatrixRisksState {
    portfolioPlanningConfigMatrixRisks: any;
    status: 'idle' | 'loading' | 'failed';
}

const initialState: PortfolioPlanningConfigMatrixRisksState = {
    portfolioPlanningConfigMatrixRisks: [],
    status: 'idle',
}

export const getConfigMatrixRisksAsync = createAsyncThunk(
    'portfolioPlanningConfigMatrixRisks/getConfigMatrixRisks',
    async (params: any) => {
        const response = await getConfigMatrixRisks(params);
        return response.data;
    }
);

export const updateConfigMatrixRiskByIdAsync = createAsyncThunk(
    'portfolioPlanningConfigMatrixRisks/updateConfigMatrixRiskById',
    async (params: any) => {
        try {
            const response = await updateConfigMatrixRiskById(params);
            return response.data;
        } catch (error: any) {
            return {error: error.response?.data}
        } 
    }
);

export const PortfolioPlanningConfigMatrixRisksSlice = createSlice({
    name: "portfolioPlanningConfigMatrixRisks",
    initialState,
    reducers: {
       getList: (state, action: PayloadAction<undefined>) => {
            state.portfolioPlanningConfigMatrixRisks = action.payload;
        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(getConfigMatrixRisksAsync.pending, (state) => {
                state.status = 'loading';
            })
            .addCase(getConfigMatrixRisksAsync.fulfilled, (state, action) => {
                state.status = 'idle';
                state.portfolioPlanningConfigMatrixRisks = action.payload;
            })
    },
});

export const { getList} = PortfolioPlanningConfigMatrixRisksSlice.actions;

export const selectPortfolioPlanningConfigMatrixRisks = (state: RootState) => state.portfolioPlanningConfigMatrixRisks;

export default PortfolioPlanningConfigMatrixRisksSlice.reducer;