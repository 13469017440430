import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "store";
import { getCronJobHistories } from "./cronjobHistoriesAPI";

export interface cronjobHistoriesStage {
    cronjobHistories: any;
    status: 'idle' | 'loading' | 'failed';
}

const initialState: cronjobHistoriesStage = {
    cronjobHistories: [],
    status: 'idle',
}

export const getCronJobHistoriesAsync = createAsyncThunk(
    'deposits/getCronJobHistories',
    async (params: any) => {
        const response = await getCronJobHistories(params);
        return response.data;
    }
);

export const cronjobHistoriesSlice = createSlice({
    name: "cronjobHistories",
    initialState,
    reducers: {
        getList: (state, action: PayloadAction<undefined>) => {
            state.cronjobHistories = action.payload;
        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(getCronJobHistoriesAsync.pending, (state) => {
                state.status = 'loading';
            })
            .addCase(getCronJobHistoriesAsync.fulfilled, (state, action) => {
                state.status = 'idle';
                state.cronjobHistories = action.payload;
            })
    },
});

export const { getList} = cronjobHistoriesSlice.actions;
export const selectCronjobHistories = (state: RootState) => state.cronjobHistories;

export default cronjobHistoriesSlice.reducer;