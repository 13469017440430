import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "store";
import { addTititadaRight, closeTititadaRight, editActiveDateTititadaRight, getTititadaRightById, getTititadaRights } from "./TititadaRightAPI";

export interface TititadaRightSliceState {
    tititadaRights: any;
    status: 'idle' | 'loading' | 'failed';
}

const initialState: TititadaRightSliceState = {
    tititadaRights: {},
    status: 'idle',
}

export const getTititadaRightsAsync = createAsyncThunk(
    'tititadaRightSlice/getTititadaRights',
    async (params: any) => {
        const response = await getTititadaRights(params);
        return response.data;
    }
);

export const getTititadaRightByIdAsync = createAsyncThunk(
    'tititadaRightSlice/getTititadaRightById',
    async (params: any) => {
        try {
            const response = await getTititadaRightById(params);
            return response.data;
        } catch (error: any) {
            return {error: error.response?.data}
        } 
    }
);

export const addTititadaRightAsync = createAsyncThunk(
    'tititadaRightSlice/addTititadaRight',
    async (params: any) => {
        try {
            const response = await addTititadaRight(params);
            return response.data;
        } catch (error: any) {
            return {error: error.response?.data}
        } 
    }
);

export const closeTititadaRightAsync = createAsyncThunk(
    'tititadaRightSlice/closeTititadaRight',
    async (params: any) => {
        try {
            const response = await closeTititadaRight(params);
            return response.data;
        } catch (error: any) {
            return {error: error.response?.data}
        } 
    }
);

export const editActiveDateTititadaRightAsync = createAsyncThunk(
    'tititadaRightSlice/editActiveDateTititadaRight',
    async (params: any) => {
        try {
            const response = await editActiveDateTititadaRight(params);
            return response.data;
        } catch (error: any) {
            return {error: error.response?.data}
        } 
    }
);

export const TititadaRightSlice = createSlice({
    name: "tititadaRightSlices",
    initialState,
    reducers: {
        getList: (state, action: PayloadAction<undefined>) => {
            state.tititadaRights = action.payload;
        },
        handleCloseSuccessRedux: (state, action) => {
            const currentTititadaRight = state?.tititadaRights?.items?.find((item: any) => {
                return item.id == action.payload.id;
            });

            if (currentTititadaRight) {
                currentTititadaRight.right_status = action.payload.right_status;
                currentTititadaRight.right_status_name = action.payload.right_status_name;
            }
        },
        handleEditActiveDateSuccessRedux: (state, action) => {
            const currentTititadaRight = state?.tititadaRights?.items.find((item: any) => {
                return item.id == action.payload.id;
            });

            if (currentTititadaRight) {
                currentTititadaRight.active_date = action.payload.active_date;
            }
        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(getTititadaRightsAsync.pending, (state) => {
                state.status = 'loading';
            })
            .addCase(getTititadaRightsAsync.fulfilled, (state, action) => {
                state.status = 'idle';
                state.tititadaRights = action.payload;
            })
    },
});

export const { getList, handleCloseSuccessRedux, handleEditActiveDateSuccessRedux} = TititadaRightSlice.actions;

export const selectTititadaRights = (state: RootState) => state.tititadaRights;

export default TititadaRightSlice.reducer;

