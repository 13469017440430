import { MdClose } from 'assets/icons';
import React from 'react';
import { BiSearch } from 'react-icons/bi';

type SearchButtonProps = {
  onSearch?: (status: boolean) => void;
  keyword?: string;
  setKeyword: (value: any) => void;
  _handleKeyDown: (value: any) => void;
};

const SearchButton = ({
  onSearch,
  keyword,
  setKeyword,
  _handleKeyDown
}: SearchButtonProps) => {
  return (
    <div className={`relative w-250px`}>
      <button
        className="absolute left-[14px] top-[18px]"
        onClick={() => {
          onSearch && onSearch(false);
        }}
      >
        <BiSearch />
      </button>
      <input
        id="search"
        type="text"
        required
        value={keyword ? keyword : ''}
        placeholder={'Keyword search...'}
        className="form-input-search"
        onChange={(e: any) => setKeyword(e.target.value)}
        onKeyDown={_handleKeyDown}
      />
      {keyword && (
        <button
          className="absolute right-[14px] top-[18px]"
          onClick={() => {
            setKeyword('');
            setTimeout(() => {
              onSearch && onSearch(true);
            }, 200);
          }}
        >
          <MdClose />
        </button>
      )}
    </div>
  );
};

export default SearchButton;
