import { STOCK_METADATA, HOST_URL } from "constant/api.contant";
import axiosInstance from "services/restful.service";

export const getStockMetadatas = (params: any) => {
    return axiosInstance.get(HOST_URL + STOCK_METADATA.GET_STOCK_METADATAS, {params}); 
}

export const getStockMetadataBySymbol = (symbol: string) => {
    return axiosInstance.get(HOST_URL + STOCK_METADATA.GET_STOCK_METADATA_BY_SYMBOL(symbol));
}

export const updateStockMetadataBySymbol = (params: any) => {
    return axiosInstance.put(HOST_URL + STOCK_METADATA.UPDATE_STOCK_METADATA_BY_SYMBOL(params.id), params);
}