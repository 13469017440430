import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "store";
import { addSurveyQuestion, disableSurveyQuestionByCode, enableSurveyQuestionByCode, getSurveyQuestionByCode, getSurveyQuestions, swapIndexSurveyQuestion, updateSurveyQuestionByCode } from "./PortfolioPlanningQuestionsAPI";

export interface PortfolioPlanningQuestionsState {
    portfolioPlanningQuestions: any;
    status: 'idle' | 'loading' | 'failed';
}

const initialState: PortfolioPlanningQuestionsState = {
    portfolioPlanningQuestions: [],
    status: 'idle',
}

export const getSurveyQuestionsAsync = createAsyncThunk(
    'portfolioPlanningQuestions/getSurveyQuestions',
    async (params: any) => {
        const response = await getSurveyQuestions(params);
        return response.data;
    }
);

export const getSurveyQuestionByCodeAsync = createAsyncThunk(
    'portfolioPlanningQuestions/getSurveyQuestionByCode',
    async (code: string) => {
        try {
            const response = await getSurveyQuestionByCode(code);
            return response.data;
        } catch (error: any) {
            return {error: error.response?.data}
        } 
    }
);

export const addSurveyQuestionAsync = createAsyncThunk(
    'portfolioPlanningQuestions/addSurveyQuestion',
    async (params: any) => {
        try {
            const response = await addSurveyQuestion(params);
            return response.data;
        } catch (error: any) {
            return {error: error.response?.data}
        } 
    }
);

export const updateSurveyQuestionByCodeAsync = createAsyncThunk(
    'portfolioPlanningQuestions/updateSurveyQuestionByCode',
    async (params: any) => {
        try {
            const response = await updateSurveyQuestionByCode(params);
            return response.data;
        } catch (error: any) {
            return {error: error.response?.data}
        } 
    }
);

export const enableSurveyQuestionByCodeAsync = createAsyncThunk(
    'portfolioPlanningQuestions/enableSurveyQuestionByCode',
    async (code: string) => {
        try {
            const response = await enableSurveyQuestionByCode(code);
            return response.data;
        } catch (error: any) {
            return {error: error.response?.data}
        } 
    }
);

export const disableSurveyQuestionByCodeAsync = createAsyncThunk(
    'portfolioPlanningQuestions/disableSurveyQuestionByCode',
    async (code: string) => {
        try {
            const response = await disableSurveyQuestionByCode(code);
            return response.data;
        } catch (error: any) {
            return {error: error.response?.data}
        } 
    }
);

export const swapIndexSurveyQuestionAsync = createAsyncThunk(
    'portfolioPlanningQuestions/disableSurveyQuestionByCode',
    async (params: any) => {
        try {
            const response = await swapIndexSurveyQuestion(params);
            return response.data;
        } catch (error: any) {
            return {error: error.response?.data}
        } 
    }
);

export const PortfolioPlanningQuestionsSlice = createSlice({
    name: "portfolioPlanningQuestions",
    initialState,
    reducers: {
        getList: (state, action: PayloadAction<undefined>) => {
            state.portfolioPlanningQuestions = action.payload;
        },
        toggleSurveyQuestionStatusByIdRedux: (state, action) => {
            const currentSurveyQuestion = state.portfolioPlanningQuestions.items.find((surveyQuestion: any) => {
                return surveyQuestion.id === action.payload
            });
            if (currentSurveyQuestion) {
                currentSurveyQuestion.is_active = !currentSurveyQuestion.is_active;
            }
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(getSurveyQuestionsAsync.pending, (state) => {
                state.status = 'loading';
            })
            .addCase(getSurveyQuestionsAsync.fulfilled, (state, action) => {
                state.status = 'idle';
                state.portfolioPlanningQuestions = action.payload;
            })
    },
});

export const { getList} = PortfolioPlanningQuestionsSlice.actions;

export const selectPortfolioPlanningQuestions = (state: RootState) => state.portfolioPlanningQuestions;

export default PortfolioPlanningQuestionsSlice.reducer;