import LoadingBarProcess from 'components/LoadingBarProcess';
import MasterLayout from 'layouts/MasterLayout';
import ReferralCodeAdd from 'pages/ReferralCodeManagement/ReferralCodeAdd';
import ReferralCodeManagement from 'pages/ReferralCodeManagement';
import React from 'react';
import { Route, Routes } from 'react-router-dom';

const ReferralCodeManagementRouter = () => {
  return (
    <Routes>
      <Route path="/" element={<MasterLayout type="header" />}>
        <Route
          index
          element={
            <React.Suspense fallback={<LoadingBarProcess />}>
              <ReferralCodeManagement />
            </React.Suspense>
          }
        />
      </Route>
      <Route path="/add" element={<MasterLayout type="header" />}>
        <Route
          index
          element={
            <React.Suspense fallback={<LoadingBarProcess />}>
              <ReferralCodeAdd />
            </React.Suspense>
          }
        />
      </Route>
      <Route path="/edit/:id" element={<MasterLayout type="header" />}>
        <Route
          index
          element={
            <React.Suspense fallback={<LoadingBarProcess />}>
              <ReferralCodeAdd />
            </React.Suspense>
          }
        />
      </Route>
    </Routes>
  );
};

export default ReferralCodeManagementRouter;
