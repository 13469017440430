import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "store";
import { getAccountAssetWaitingAvailablesStocks } from "./accountAssetWaitingAvailablesStocksAPI";

export interface accountAssetWaitingAvailablesStocksSliceState {
    accountAssetWaitingAvailablesStocks: any;
    status: 'idle' | 'loading' | 'failed';
}

const initialState: accountAssetWaitingAvailablesStocksSliceState = {
    accountAssetWaitingAvailablesStocks: [],
    status: 'idle',
}

export const getAccountAssetWaitingAvailablesStocksAsync = createAsyncThunk(
    'accountAssetWaitingAvailablesStocks/getAccountAssetWaitingAvailablesStocks',
    async (params: any) => {
        const response = await getAccountAssetWaitingAvailablesStocks(params);
        return response.data;
    }
);

export const accountAssetWaitingAvailablesStocksSlice = createSlice({
    name: "accountAssetWaitingAvailablesStocks",
    initialState,
    reducers: {
        getList: (state, action: PayloadAction<undefined>) => {
            state.accountAssetWaitingAvailablesStocks = action.payload;
        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(getAccountAssetWaitingAvailablesStocksAsync.pending, (state) => {
                state.status = 'loading';
            })
            .addCase(getAccountAssetWaitingAvailablesStocksAsync.fulfilled, (state, action) => {
                state.status = 'idle';
                state.accountAssetWaitingAvailablesStocks = action.payload;
            })
    },
});

export const { getList} = accountAssetWaitingAvailablesStocksSlice.actions;
export const selectAccountAssetWaitingAvailablesStocks = (state: RootState) => state.accountAssetWaitingAvailablesStocks;

export default accountAssetWaitingAvailablesStocksSlice.reducer;