export enum ARTICLE_STATUS {
  Draft = 0,
  Unpublished = 1,
  Published = 2,
}

export enum ARTICLE_TYPE {
  Blog = '0',
  Flashcard = '1',
  InfographicVideo = '2',
}

export const ARTICLE_OPTIONS = [
  {
    title: 'Blog',
    value: ARTICLE_TYPE.Blog,
  },
  {
    title: 'Flashcard',
    value: ARTICLE_TYPE.Flashcard,
  },
  {
    title: 'Infographic Video',
    value: ARTICLE_TYPE.InfographicVideo,
  },
];
