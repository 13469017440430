import { ARTICLES, HOST_URL } from "constant/api.contant";
import axiosInstance from "services/restful.service";

function parseParams(params: any) {
    const keys = Object.keys(params)
    let options = ''
  
    keys.forEach((key) => {
      const isParamTypeObject = typeof params[key] === 'object'
      const isParamTypeArray = isParamTypeObject && params[key].length >= 0
  
      if (!isParamTypeObject) {
        options += `${key}=${params[key]}&`
      }
  
      if (isParamTypeObject && isParamTypeArray) {
        params[key].forEach((element: any) => {
          options += `${key}=${element}&`
        })
      }
    })
  
    return options ? options.slice(0, -1) : options
}

export const getArticles = (params?: any) => {
    return axiosInstance.get(HOST_URL + ARTICLES.LIST, {params, paramsSerializer: (params) => parseParams(params),});
}

export const addArticle = (params?: any) => {
    return axiosInstance.post(HOST_URL + ARTICLES.ADD, params);
}

export const getArticleById = (id: string) => {
    return axiosInstance.get(HOST_URL + ARTICLES.GET_ARTICLE_BY_ID(id));
}

export const updateArticleById = (params?: any) => {
    return axiosInstance.put(HOST_URL + ARTICLES.UPDATE_ARTICLE_BY_ID(params.id), params);
}

export const deleteArticleById = (id: string) => {
    return axiosInstance.delete(HOST_URL + ARTICLES.DELETE_ARTICLE_BY_ID(id));
}

export const approveArticleById = (id: string) => {
    return axiosInstance.put(HOST_URL + ARTICLES.APPROVE(id));
}

export const rejectArticlebyId = (id: string) => {
    return axiosInstance.put(HOST_URL + ARTICLES.REJECT(id));
}