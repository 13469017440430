import LoadingBarProcess from 'components/LoadingBarProcess';
import MasterLayout from 'layouts/MasterLayout';
import React, { useEffect } from 'react';
import { Route, Routes, useNavigate } from 'react-router-dom';
import { usePermissions, PERMISSIONS_ACTIONS } from 'hooks';

import ApproveWithdrawPage from 'pages/ApproveWithdraw/ApproveWithdraw';
import ApproveWithdrawDetailPage from 'pages/ApproveWithdraw/ApproveWithdrawDetail';
import { PERMISSIONS_KEY } from 'constant';

const ApproveWithdrawRoutes = () => {
  const navigate = useNavigate();
  const { check } = usePermissions();

  useEffect(() => {
    const permissionGranted = check(
      PERMISSIONS_KEY.ManageWithdrawal,
      PERMISSIONS_ACTIONS.APPROVE
    );

    if (!permissionGranted) {
      if (!permissionGranted) navigate('/forbidden');
    }
  }, []);
  return (
    <Routes>
      <Route path="/" element={<MasterLayout type="header" />}>
        <Route
          path=""
          element={
            <React.Suspense fallback={<LoadingBarProcess />}>
              <ApproveWithdrawPage />
            </React.Suspense>
          }
        />
        <Route
          path=":id"
          element={
            <React.Suspense fallback={<LoadingBarProcess />}>
              <ApproveWithdrawDetailPage />
            </React.Suspense>
          }
        />
      </Route>
    </Routes>
  );
};

export default ApproveWithdrawRoutes;
