import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "store";
import { getFSStockAdvanceOrders } from "./fsStockAdvanceOrdersAPI";

export interface fsStockAdvanceOrdersSliceState {
    fsStockAdvanceOrders: any;
    status: 'idle' | 'loading' | 'failed';
}

const initialState: fsStockAdvanceOrdersSliceState = {
    fsStockAdvanceOrders: [],
    status: 'idle',
}

export const getFSStockAdvanceOrdersAsync = createAsyncThunk(
    'fsStockAdvanceOrders/getFSStockAdvanceOrders',
    async (params: any) => {
        const response = await getFSStockAdvanceOrders(params);
        return response.data;
    }
);

export const fsStockAdvanceOrdersSlice = createSlice({
    name: "fsStockAdvanceOrders",
    initialState,
    reducers: {
        getList: (state, action: PayloadAction<undefined>) => {
            state.fsStockAdvanceOrders = action.payload;
        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(getFSStockAdvanceOrdersAsync.pending, (state) => {
                state.status = 'loading';
            })
            .addCase(getFSStockAdvanceOrdersAsync.fulfilled, (state, action) => {
                state.status = 'idle';
                state.fsStockAdvanceOrders = action.payload;
            })
    },
});

export const { getList} = fsStockAdvanceOrdersSlice.actions;
export const selectFSStockAdvanceOrders = (state: RootState) => state.fsStockAdvanceOrders;

export default fsStockAdvanceOrdersSlice.reducer;