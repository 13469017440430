import { HOST_URL, TCB } from 'constant/api.contant';
import axiosInstance from 'services/restful.service';

export const getListTcbReportFiles = (params?: any) => {
  return axiosInstance.get(HOST_URL + TCB.LIST, { params });
};

export const getUrlTcbReportFiles = (id?: any) => {
  return axiosInstance.get(HOST_URL + TCB.GET_URL_DOWNLOAD(id));
};
