import { HOST_URL, SUGGESTION_ASSET } from "constant/api.contant";
import axiosInstance from "services/restful.service";

export const getSuggestionAssets = (params: any) => {
    return axiosInstance.get(HOST_URL + SUGGESTION_ASSET.GET_SUGGESTION_ASSETS, {params});
}

export const updateSuggestionAssetById = (params: any) => {
    return axiosInstance.put(HOST_URL + SUGGESTION_ASSET.UPDATE_SUGGESTION_ASSET_BY_ID(params.id), params);
}
