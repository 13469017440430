import { NEWS } from 'constant/api.contant';
import { NewsParams } from 'types/News.type';
import axiosInstance from './restful.service';

export const getNewsList = async (params: any) => {
  return axiosInstance.get(NEWS(), { params }).then(res => {
    const data = res?.data;
    return data?.data;
  });
};

export const addNews = async (params: NewsParams) => {
  return axiosInstance.post(NEWS(), params).then(res => {
    const data = res?.data;
    return data;
  });
};

export const updateNews = async (id: string, params: NewsParams) => {
  return axiosInstance.put(NEWS(id), params).then(res => {
    const data = res?.data;
    return data;
  });
};

export const getNewsDetail = async (id: string) => {
  return axiosInstance.get(NEWS(id)).then(res => {
    const data = res?.data;
    return data;
  });
};

export const deleteNews = async (id: string) => {
  return axiosInstance.delete(NEWS(id)).then(res => {
    const data = res?.data;
    return data;
  });
};
