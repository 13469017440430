import { HOST_URL, UPLOAD } from 'constant/api.contant';
import axiosInstance from './restful.service';

export const getUploadImageUrl = async (
  type:
    | 'Banner'
    | 'CccdFront'
    | 'CccdBack'
    | 'FaceLiveness'
    | 'SignContract'
    | 'SbbsReport'
    | 'Announcement'
    | 'Other'
) => {
  const params = { FileType: type };
  return axiosInstance
    .get(`${HOST_URL}${UPLOAD.UPLOAD_IMAGE_URL}`, { params })
    .then(res => {
      const data = res?.data;
      return data?.data;
    });
};
