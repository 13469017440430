import * as React from 'react';
import { Routes, Route, useLocation, Navigate } from 'react-router-dom';
import { useAuth } from './../contexts/Auth/AuthContext';
import MasterLayout from 'layouts/MasterLayout/';
import LoadingBarProcess from 'components/LoadingBarProcess';
import DashboardPage from 'pages/DashBoard/DashBoardPage';
import AccountManagementRoutes from './AccountManagement';
import DepositManagementRoutes from './DepositManagement';
import WithdrawManagementRoutes from './WithdrawManagement';
import ApproveWithdrawRoutes from './ApproveWithdrawRoutes';
import AnnouncedInformationManagement from './AnnouncedInformationManagement';
import TcbreportfilesManagement from './TcbreportfilesManagement';
import ReferralCodeManagement from './ReferralCodeManagement';
import DEManagementRoutes from './DEManagementRoutes';
import FaqCategoryRoutes from './FaqCategoryRoutes';
import FaqArticleRoutes from './FaqArticleRoutes';
import BannerManagement from 'pages/BannerManagement';
import UserManagementRoutes from './UserManagement';
import NewsManagementRoutes from './NewsManagementRoutes';
import BannerManagementRoutes from './BannerManagementRoutes';

const Login = React.lazy(() => import('pages/Login'));
const SettingSystem = React.lazy(() => import('pages/SettingSystem'));
const _404 = React.lazy(() => import('pages/_404/_404'));
const _500 = React.lazy(() => import('pages/_500/_500'));
const _forbidden = React.lazy(() => import('pages/_forbidden/_forbidden'));
const LoginWithMicrosoftPage = React.lazy(
  () => import('components/Login/LoginWithMicrosoft/LoginWithMicrosoft')
);

const RequireAuth: React.FC<{ children: JSX.Element }> = ({
  children
}: {
  children: JSX.Element;
}) => {
  const auth = useAuth();
  const location = useLocation();

  if (!auth.user) {
    return <Navigate to="/login" state={{ from: location }} replace />;
  }

  return children;
};

const routes = () => {
  return (
    <>
      <Routes>
        <Route path="/" element={<MasterLayout type="header" />}>
          <Route
            index
            element={
              <React.Suspense fallback={<LoadingBarProcess />}>
                <RequireAuth>
                  <DashboardPage />
                </RequireAuth>
              </React.Suspense>
            }
          />
          <Route
            path="setting-system"
            element={
              <React.Suspense fallback={<LoadingBarProcess />}>
                <RequireAuth>
                  <SettingSystem />
                </RequireAuth>
              </React.Suspense>
            }
          />
        </Route>
        <Route
          path="login"
          element={
            <React.Suspense fallback={<LoadingBarProcess />}>
              <Login />
            </React.Suspense>
          }
        />
        <Route
          path="ms-callback"
          element={
            <React.Suspense fallback={<LoadingBarProcess />}>
              <LoginWithMicrosoftPage />
            </React.Suspense>
          }
        />
        <Route
          path="404"
          element={
            <React.Suspense fallback={<LoadingBarProcess />}>
              <_404 />
            </React.Suspense>
          }
        />
        <Route
          path="forbidden"
          element={
            <React.Suspense fallback={<LoadingBarProcess />}>
              <_forbidden />
            </React.Suspense>
          }
        />
        <Route
          path="500"
          element={
            <React.Suspense fallback={<LoadingBarProcess />}>
              <_500 />
            </React.Suspense>
          }
        />
        <Route
          path="*"
          element={
            <React.Suspense fallback={<LoadingBarProcess />}>
              <_404 />
            </React.Suspense>
          }
        />
        {/* Quan lý tài khoản */}
        <Route
          path="account-management/*"
          element={<AccountManagementRoutes />}
        />

        {/* Quản lý tiền */}
        <Route
          path="deposit-management/*"
          element={<DepositManagementRoutes />}
        />
        <Route
          path="withdraw-management/*"
          element={<WithdrawManagementRoutes />}
        />
        <Route
          path="approve-withdraw-management/*"
          element={<ApproveWithdrawRoutes />}
        />

        {/* Quản lý thông tin công bố */}
        <Route
          path="announced-information-management/*"
          element={<AnnouncedInformationManagement />}
        />

        {/* Quản lý file đối soát */}
        <Route
          path="tcb-report-files-management/*"
          element={<TcbreportfilesManagement />}
        />

        {/* Quản lý banner */}
        <Route path="banner-management/*" element={<BannerManagementRoutes />} />

        {/* Quản lý mã môi giới */}
        <Route
          path="referral-code-management/*"
          element={<ReferralCodeManagement />}
        />

        {/* Quản lý đánh dấu DE */}
        <Route path="de-management/*" element={<DEManagementRoutes />} />

        {/* FAQ */}
        <Route
          path="faq-category-management/*"
          element={<FaqCategoryRoutes />}
        />

        <Route path="faq-article-management/*" element={<FaqArticleRoutes />} />

        <Route path="user-management/*" element={<UserManagementRoutes />} />
        <Route path="news-management/*" element={<NewsManagementRoutes />} />
      </Routes>
    </>
  );
};

export default routes;
