import React from 'react';

type CloseIconProps = {
  className?: string;
  color?: string;
};

export const CloseIcon = ({ className, color }: CloseIconProps) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      className={className}
    >
      <path
        d="M0.417577 15.5817C0.607729 15.7686 0.830111 15.8928 1.08472 15.954C1.33933 16.0153 1.59152 16.0153 1.8413 15.954C2.09108 15.8928 2.3046 15.7735 2.48185 15.5962L8.00271 10.0743L13.5139 15.5914C13.6976 15.7751 13.9143 15.8968 14.1641 15.9564C14.4139 16.0161 14.6645 16.0145 14.9159 15.9516C15.1673 15.8887 15.3864 15.7638 15.5734 15.5768C15.7603 15.3899 15.8852 15.1707 15.948 14.9193C16.0109 14.6678 16.0125 14.4172 15.9529 14.1674C15.8932 13.9176 15.7731 13.7008 15.5927 13.5171L10.0815 7.99516L15.5927 2.48295C15.7731 2.29921 15.894 2.08243 15.9552 1.8326C16.0165 1.58278 16.0149 1.33295 15.9504 1.08313C15.886 0.83331 15.7603 0.614917 15.5734 0.427953C15.3832 0.237766 15.1632 0.111243 14.9135 0.0483845C14.6637 -0.014474 14.4139 -0.0160858 14.1641 0.0435493C13.9143 0.103185 13.6976 0.224872 13.5139 0.408613L8.00271 5.92083L2.48185 0.403777C2.3046 0.226484 2.09027 0.10802 1.83889 0.0483845C1.5875 -0.0112505 1.3345 -0.0112505 1.07989 0.0483845C0.825277 0.10802 0.604506 0.232931 0.417577 0.423118C0.233871 0.610083 0.112206 0.830087 0.0525816 1.08313C-0.0070423 1.33618 -0.0070423 1.58842 0.0525816 1.83985C0.112206 2.09129 0.229037 2.30404 0.403073 2.47811L5.92393 7.99516L0.403073 13.5267C0.229037 13.7008 0.110594 13.9127 0.0477473 14.1625C-0.0150996 14.4123 -0.0159053 14.6646 0.0453301 14.9193C0.106566 15.1739 0.230648 15.3947 0.417577 15.5817Z"
        fill={`${color || 'black'}`}
        fillOpacity="0.85"
      />
    </svg>
  );
};
