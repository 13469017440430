import { FAQS, HOST_URL } from "constant/api.contant";
import axiosInstance from "services/restful.service";

export const getFAQs = (params: any) => {
    return axiosInstance.get(HOST_URL + FAQS.GET_FAQS, {params});
}

export const getFAQById = (id: string) => {
    return axiosInstance.get(HOST_URL + FAQS.GET_FAQ_BY_ID(id));
}

export const addFAQ = (params: any) => {
    return axiosInstance.post(HOST_URL + FAQS.ADD_FAQ, params);
}

export const updateFAQ = (params: any) => {
    return axiosInstance.put(HOST_URL + FAQS.UPDATE_FAQ_BY_ID(params.id), params);
}

export const enableFAQById = (id: string) => {
    return axiosInstance.put(HOST_URL + FAQS.ENABLE_FAQ_BY_ID(id));
}

export const disableFAQById = (id: string) => {
    return axiosInstance.put(HOST_URL + FAQS.DISABLE_FAQ_BY_ID(id));
}