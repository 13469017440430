import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { RootState } from "store";
import { updateSettingSystem } from './settingSystemAPI';

export interface updateSettingSystemState {
    settingSystem: any;
    status: 'idle' | 'loading' | 'failed';
}

const initialState: updateSettingSystemState = {
    settingSystem: null,
    status: 'idle',
}

export const updateSettingSystemAsync = createAsyncThunk(
    'deposits/updateSettingSystem',
    async (params: any) => {
        const response = await updateSettingSystem(params);
        return response.data;
    }
);

export const settingSystemSlice = createSlice({
    name: "updateSettingSystem",
    initialState,
    reducers: {
       
    },
    extraReducers: (builder) => {
        builder
            .addCase(updateSettingSystemAsync.pending, (state) => {
                state.status = 'loading';
            })
            .addCase(updateSettingSystemAsync.fulfilled, (state, action) => {
                state.status = 'idle';
                state.settingSystem = action.payload;
            })
    },
});

export const selectDeposits = (state: RootState) => state.settingSystem;

export default settingSystemSlice.reducer;