import { TRANSACTION, HOST_URL } from "constant/api.contant";
import axiosInstance from "services/restful.service";


export const getTransaction = (params?: any) => {
    return axiosInstance.get(HOST_URL + TRANSACTION.LIST, {params});
}

export const getTransactionDetails = (id: string) => {
    return axiosInstance.get(HOST_URL + TRANSACTION.DETAILS(id));
}

export const destroyTransactionsById = (params?: any) => {
    return axiosInstance.post(HOST_URL + TRANSACTION.DESTROY_ORDERS, params);
}