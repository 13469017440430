import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "store";
import { getAccountRightTransactions } from "./AccountRightsAPI";

export interface AccountRightTransactionsSliceState {
    accountRightTransactions: any;
    status: 'idle' | 'loading' | 'failed';
}

const initialState: AccountRightTransactionsSliceState = {
    accountRightTransactions: [],
    status: 'idle',
}

export const getAccountRightTransactionsAsync = createAsyncThunk(
    'accountRightTransactions/getAccountRightTransactions',
    async (params: any) => {
        const response = await getAccountRightTransactions(params);
        return response.data;
    }
);

export const AccountRightTransactionsSlice = createSlice({
    name: "accountRightTransactions",
    initialState,
    reducers: {
       getList: (state, action: PayloadAction<undefined>) => {
            state.accountRightTransactions = action.payload;
        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(getAccountRightTransactionsAsync.pending, (state) => {
                state.status = 'loading';
            })
            .addCase(getAccountRightTransactionsAsync.fulfilled, (state, action) => {
                state.status = 'idle';
                state.accountRightTransactions = action.payload;
            })
    },
});

export const { getList} = AccountRightTransactionsSlice.actions;

export const selectAccountRightTransactions = (state: RootState) => state.accountRightTransactions;

export default AccountRightTransactionsSlice.reducer;