import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "store";
import { disableSurveySectionByCode, enableSurveySectionByCode, getSurveySectionByCode, getSurveySections, updateSurveySectionByCode } from "./PortfolioPlanningSectionsAPI";

export interface PortfolioPlanningSectionsState {
    portfolioPlanningSections: any;
    status: 'idle' | 'loading' | 'failed';
}

const initialState: PortfolioPlanningSectionsState = {
    portfolioPlanningSections: [],
    status: 'idle',
}

export const getSurveySectionsAsync = createAsyncThunk(
    'portfolioPlanningSections/getSurveySections',
    async (params: any) => {
        const response = await getSurveySections(params);
        return response.data;
    }
);

export const getSurveySectionByCodeAsync = createAsyncThunk(
    'portfolioPlanningSections/getSurveySectionByCode',
    async (code: string) => {
        try {
            const response = await getSurveySectionByCode(code);
            return response.data;
        } catch (error: any) {
            return {error: error.response?.data}
        } 
    }
);

export const enableSurveySectionByCodeAsync = createAsyncThunk(
    'portfolioPlanningSections/enableSurveySectionByCode',
    async (code: string) => {
        try {
            const response = await enableSurveySectionByCode(code);
            return response.data;
        } catch (error: any) {
            return {error: error.response?.data}
        } 
    }
);

export const disableSurveySectionByCodeAsync = createAsyncThunk(
    'portfolioPlanningSections/disableSurveySectionByCode',
    async (code: string) => {
        try {
            const response = await disableSurveySectionByCode(code);
            return response.data;
        } catch (error: any) {
            return {error: error.response?.data}
        } 
    }
);

export const updateSurveySectionByCodeAsync = createAsyncThunk(
    'portfolioPlanningSections/updateSurveySectionByCode',
    async (params: any) => {
        try {
            const response = await updateSurveySectionByCode(params);
            return response.data;
        } catch (error: any) {
            return {error: error.response?.data}
        } 
    }
);

export const PortfolioPlanningSectionsSlice = createSlice({
    name: "portfolioPlanningSections",
    initialState,
    reducers: {
       getList: (state, action: PayloadAction<undefined>) => {
            state.portfolioPlanningSections = action.payload;
        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(getSurveySectionsAsync.pending, (state) => {
                state.status = 'loading';
            })
            .addCase(getSurveySectionsAsync.fulfilled, (state, action) => {
                state.status = 'idle';
                state.portfolioPlanningSections = action.payload;
            })
    },
});

export const { getList} = PortfolioPlanningSectionsSlice.actions;

export const selectPortfolioPlanningSections = (state: RootState) => state.portfolioPlanningSections;

export default PortfolioPlanningSectionsSlice.reducer;