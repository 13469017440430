import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { RootState } from 'store';
import { getListTcbReportFiles, getUrlTcbReportFiles } from './TcbReportFilesAPI';

export interface TcbReportFilesState {
  tcbReportFiles: any;
  status: 'idle' | 'loading' | 'failed';
}

const initialState: TcbReportFilesState = {
  tcbReportFiles: [],
  status: 'idle'
};

export const getTcbReportFilesAsync = createAsyncThunk(
  'tcbReportFiles/getTcbReportFiles',
  async (params?: any) => {
    const response = await getListTcbReportFiles(params);
    return response.data;
  }
);

export const getUrlTcbReportFilesAsync = createAsyncThunk(
  'tcbReportFiles/getUrlTcbReportFiles',
  async (id?: any) => {
    const response = await getUrlTcbReportFiles(id);
    return response.data;
  }
);

export const tcbReportFilesSlice = createSlice({
  name: 'tcbReportFiles',
  initialState,
  reducers: {
    getList: (state, action) => {
      state.tcbReportFiles = action.payload;
    }
  },
  extraReducers: builder => {
    builder
      .addCase(getTcbReportFilesAsync.pending, state => {
        state.status = 'loading';
      })
      .addCase(getTcbReportFilesAsync.fulfilled, (state, action) => {
        state.status = 'idle';
        state.tcbReportFiles = action.payload;
      })
      .addCase(getTcbReportFilesAsync.rejected, state => {
        state.status = 'failed';
      });
  }
});

export const selectTcbReportFiles = (state: RootState) => state.tcbReportFiles;

export const { getList } = tcbReportFilesSlice.actions;
export default tcbReportFilesSlice.reducer;
