import React from "react";

const Footer = () => {
  return (
    <>
      <div className="flex min-h-[45px] px-10 items-center">
        <div><p>Bản quyền © 2023 CTCP Chứng Khoán SaigonBank Berjaya</p></div>
        <div className=""></div>
      </div>
    </>
  )
}

export default Footer;