import { HOST_URL, NOTIFICATIONS } from "constant/api.contant";
import axiosInstance from "services/restful.service";

export const getListNotifications = (params:any) => {
    return axiosInstance.get(HOST_URL + NOTIFICATIONS.LIST_NOTIFICATIONS, {params});
};

export const getListBroadcastTemplate = () => {
    return axiosInstance.get(HOST_URL + NOTIFICATIONS.LIST_BROADCAST_TEMPLATE);
}

export const broadcastNotification = (params: any) => {
    return axiosInstance.post(HOST_URL + NOTIFICATIONS.BROADCAST, params);
}