import React from 'react';
import { BiFilter } from 'react-icons/bi';

type FilterButtonProps = {
  toggleFilter: () => void;
};

const FilterButton = ({ toggleFilter }: FilterButtonProps) => {
  return (
    <button
      onClick={toggleFilter}
      className="flex items-center min-h-[50px] rounded p y-2 px-3 space-x-2 bg-white border border-[#E5E5E5]"
    >
      <BiFilter />
      <span className="text-sm">Bộ Lọc</span>
    </button>
  );
};

export default FilterButton;
