import React from "react";
import { useNavigate } from 'react-router-dom';
import { ChevronLeftIcon } from "assets/icons/ChevronLeftIcon";
import styles from "./index.module.scss";

export const BackButton: React.FC = () => {
  const navigate = useNavigate();
  return (
    <>
      <button onClick={() => navigate(-1)} className={`${styles.backButton}`}>
        <span>
          <ChevronLeftIcon />
        </span>
        <span className={styles['backButton-text']}>Back</span>
      </button>
    </>
  )
}