import { createSlice } from "@reduxjs/toolkit";
import { RootState } from '../../store';

export interface ThemeConfigState {
    layout: any;
}

const initialState: ThemeConfigState = {
    layout: {
        sideNavCollapse: false,
    },
}

export const ThemeConfigSlice = createSlice({
    name: "theme",
    initialState,
    reducers: {
        setSideNavCollapse: (state, action) => {
            state.layout = {
                ...state.layout,
                ...{ sideNavCollapse: action.payload },
            }
        },
    }
});

export const { setSideNavCollapse } = ThemeConfigSlice.actions;
export const selectThemeConfig = (state: RootState) => state;

export default ThemeConfigSlice.reducer;