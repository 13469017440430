import { USER_ROLES, PERMISSIONS } from 'constant';

export const PERMISSIONS_ACTIONS = {
  ALL: 'All',
  CREATE: 'Insert',
  UPDATE: 'Update',
  VIEW: 'View',
  DELETE: 'Delete',
  APPROVE_EKYC: 'ApproveKYC',
  EDIT_INFO: 'EditInfo',
  HISTORY_CHANGE: 'HistoryChange',
  ADD_CONTRACT_DOC: 'AddContractDoc',
  DELETE_CONTRACT_DOC: 'DeleteContractDoc',
  SYNC_G3_DATA: 'SyncG3Data',
  APPROVE: 'Approve',
  EXPORT_DATA: 'ExportData'
};

export const usePermissions = () => {
  const groupPermissions = (data: string | string[]) => {
    if (typeof data === 'string') {
      const key = data.split('_')[0];
      const action = data.split('_')[1];
      return {
        [key]: [action]
      };
    }
    const length = data.length;
    if (length > 0) {
      const result = data.reduce<any>((prev, value) => {
        const key = value.split('_')[0];
        const action = value.split('_')[1];
        // compare the current value with the last item in the collected array
        if (prev[key]) {
          // append the current value to it if it is matching
          const v = action;

          prev[key].push(v);
        } else {
          // append the new value at the end of the collected array
          prev[key] = [action];
        }

        return prev;
      }, {});

      return result;
    }
    return {};
  };
  const getPermissions = () => {
    const permissionJson = localStorage.getItem(PERMISSIONS);
    const permission = JSON.parse(permissionJson || '{}');
    return permission;
  };

  const getRole = () => {
    const userInfoJson = localStorage.getItem('user');
    const userInfo = JSON.parse(userInfoJson || '{}');
    const role = userInfo.role;
    return role;
  };

  const isSystemAdmin = (): boolean => {
    const role = getRole();
    return role === USER_ROLES.SYSTEM_ADMIN;
  };

  const check = (feature: string, action: string) => {
    const key = feature.split('_')[0];
    const permissionsUser = getPermissions();
    const featurePermission = permissionsUser[key];

    return (
      featurePermission?.indexOf(PERMISSIONS_ACTIONS.ALL) > -1 ||
      featurePermission?.indexOf(action) > -1
    );
  };

  const checkView = (key: string) => {
    return check(key, PERMISSIONS_ACTIONS.VIEW);
  };

  const checkCreate = (key: string) => {
    return check(key, PERMISSIONS_ACTIONS.CREATE);
  };

  const checkEdit = (key: string) => {
    return check(key, PERMISSIONS_ACTIONS.UPDATE);
  };

  const checkDelete = (key: string) => {
    return check(key, PERMISSIONS_ACTIONS.DELETE);
  };

  return {
    check,
    checkCreate,
    checkView,
    checkEdit,
    checkDelete,
    getPermissions,
    groupPermissions
  };
};
