import React from 'react';

type TagProps = {
  name: string
};

export const Tag = ({name}: TagProps) => {
  return (
    <>
      <span className="inline-block bg-primary-50 px-2 py-0.5 text-xs text-primary-500 font-medium rounded">{name}</span>
    </>
  )
}