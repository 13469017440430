import React, { useState } from 'react';
import { useAuth } from 'contexts/Auth/AuthContext';
import style from './index.module.scss';
import ProfileDropDown from 'components/ProfileDropDown';
import useComponentVisible from 'hooks/use-component-visible';
import user_avatar from '../../assets/images/user_avatar.png';
import SideNavToggle from 'components/UiComponents/SideNavToggle';
import { toast } from 'react-toastify';
import ChangePassword from 'components/ProfileDropDown/components/ChangePassword';

const Header = () => {
  const { ref, isComponentVisible, setIsComponentVisible } = useComponentVisible(false);

  const [isShowChangePassword, setIsShowChangePassword] = useState(false);

  const auth = useAuth();

  const toastMessage = (type: string, message: string) => {
    if (type == 'success') {
      toast.success(message, {
        position: 'top-right',
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    }

    if (type == 'error') {
      toast.error(message, {
        position: 'top-right',
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    }
  };

  return (
    <>
      <div
        className={`${style['page-header']}  bg-white min-h-[50px] xl:min-h-[66px] flex items-center justify-between`}>
        <div>
          <SideNavToggle />
        </div>
        <div>
          <button className="ml-auto rounded-full w-[40px] xl:w-[50px] h-[40px] xl:h-[50px]">
            <div
              onClick={() => setIsComponentVisible(!isComponentVisible)}
              className={`${style['page-avatar']} ml-auto rounded-full w-[40px] xl:w-[50px] h-[40px] xl:h-[50px]`}
              style={{
                backgroundImage: `url("${auth?.user?.avatar ? auth?.user?.avatar : user_avatar}")`,
              }}></div>
          </button>
          {isComponentVisible && (
            <div ref={ref}>
              <ProfileDropDown
                close={() => setIsComponentVisible(!isComponentVisible)}
                showChangePass={() => setIsShowChangePassword(true)}
              />
            </div>
          )}
        </div>
      </div>
      {/* <ChangePassword
        isOpen={isShowChangePassword}
        closeModal={() => setIsShowChangePassword(false)}
        toastMessage={toastMessage}
      /> */}
      <ChangePassword
        isOpen={isShowChangePassword}
        closeModal={() => setIsShowChangePassword(false)}
        toastMessage={toastMessage}
      />
    </>
  );
};

export default Header;
