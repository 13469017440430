import { configureStore, ThunkAction, Action } from '@reduxjs/toolkit';
import accountsReducer from './slices/accounts/accountsSlice';
import depositsReducer from './slices/deposits/depositsSlice';
import withdrawsReducer from './slices/withdraws/withdrawsSlice';
import transactionReducer from './slices/transaction/transactionSlice';
import settingSystemReducer from './slices/setting-system/settingSystemSlice';
import articlesReducer from './slices/articles/articlesSlice';
import fsStockAdvanceOrdersReducer from './slices/fs-stock-advance-orders/fsStockAdvanceOrdersSlice';
import accountAssetWaitingAvailablesStocksReducer from './slices/account-asset-waiting-availables-stocks/accountAssetWaitingAvailablesStocksSlice';
import cronjobHistoriesReducer from './slices/cronjob-histories/cronjobHistoriesSlice';
import articleTagsReducer from './slices/article-tags/articleTagsSlice';
import bondsReducer from './slices/bonds/bondsSlice';
import bondTransactionsReducer from './slices/bond-transactions/bondTransactionsSlice';
import notificationTemplatesReducer from './slices/notifications-template/NotificationTemplatesSlice';
import notificationsManagementReducer from './slices/notifications-management/NotificationsManagementSlice';
import portfolioPlanningSectionsReducer from './slices/portfolio-planning-management/portfolio-planning-section/PortfolioPlanningSectionsSlice';
import portfolioPlanningQuestionsReducer from './slices/portfolio-planning-management/portfolio-planning-question/PortfolioPlanningQuestionsSlice';
import portfolioPlanningConfigMatrixRisksReducer from './slices/portfolio-planning-management/portfolio-planning-config-matrix-risks/PortfolioPlanningConfigMatrixRisksSlice';
import portfolioPlanningAssetRatioReducer from './slices/portfolio-planning-management/portfolio-planning-asset-ratio/PortfolioPlanningAssetRatioSlice';
import portfolioPlanningSuggestionAssetsReducer from './slices/portfolio-planning-management/portfolio-planning-suggestion-asset/PortfolioPlanningSuggestionAssetsSlice';
import tititadaRightsReducer from './slices/tititada-right-management/tititada-right/TititadaRightSlice';
import accountRightsReducer from './slices/tititada-right-management/account-right/AccountRightsSlice';
import accountRightTransactionsReducer from './slices/tititada-right-management/account-right/AccountRightTransactionsSlice';
import portfolioPlanningFiltersReducer from './slices/portfolio-planning-management/portfolio-planning-filters/PortfolioPlanningFiltersSlice';
import faqsManagementReducer from './slices/faqs-management/FAQsManagementSlice';
import articleTopicsReducer from './slices/article-topics/articleTopicsSlice';
import stockSymbolMetadataReducer from './slices/stock-symbol-management/stock-symbol-metadata/stockSymbolMetadataSlice';
import themeReducer from './slices/ThemeConfigSlice';
import announcementReducer from './slices/announcement/announcementSlice';
import tcbReportFilesReducer from './slices/tcb-report-files/TcbReportFilesSlice';

export const store = configureStore({
  reducer: {
    accounts: accountsReducer,
    deposits: depositsReducer,
    withdraws: withdrawsReducer,
    transaction: transactionReducer,
    settingSystem: settingSystemReducer,
    articles: articlesReducer,
    fsStockAdvanceOrders: fsStockAdvanceOrdersReducer,
    accountAssetWaitingAvailablesStocks:
      accountAssetWaitingAvailablesStocksReducer,
    cronjobHistories: cronjobHistoriesReducer,
    articleTags: articleTagsReducer,
    bonds: bondsReducer,
    bondTransactions: bondTransactionsReducer,
    notificationTemplates: notificationTemplatesReducer,
    notificationsManagement: notificationsManagementReducer,
    portfolioPlanningSections: portfolioPlanningSectionsReducer,
    portfolioPlanningQuestions: portfolioPlanningQuestionsReducer,
    portfolioPlanningConfigMatrixRisks:
      portfolioPlanningConfigMatrixRisksReducer,
    portfolioPlanningAssetRatios: portfolioPlanningAssetRatioReducer,
    portfolioPlanningSuggestionAssets: portfolioPlanningSuggestionAssetsReducer,
    tititadaRights: tititadaRightsReducer,
    accountRights: accountRightsReducer,
    accountRightTransactions: accountRightTransactionsReducer,
    portfolioPlanningFilters: portfolioPlanningFiltersReducer,
    faqsManagement: faqsManagementReducer,
    articleTopics: articleTopicsReducer,
    stockSymbolMetadata: stockSymbolMetadataReducer,
    theme: themeReducer,
    announcement: announcementReducer,
    tcbReportFiles: tcbReportFilesReducer
  }
});

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action<string>
>;
