import Breadcrumb from 'components/Breadcrumb';
import { PermissionsGate } from 'components/PermissionsGate';
import { PaginationOwn } from 'components/Shared';
import TitlePage from 'components/TitlePage';
import { CircleSpin, ItemsPerPage } from 'components/UiComponents';
import FilterButton from 'components/UiComponents/FilterButton/FilterButton';
import PanelSiderBar from 'components/UiComponents/PanelSiderBar/PanelSiderBar';
import SearchButton from 'components/UiComponents/SearchButton/SearchButton';
import { PERMISSIONS_KEY } from 'constant';
import { PERMISSIONS_ACTIONS } from 'hooks';
import React, { useCallback, useEffect, useState } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import { AiOutlineBook } from 'react-icons/ai';
import { BiEdit, BiTrash } from 'react-icons/bi';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import {
  Link,
  useNavigate,
  useParams,
  useSearchParams
} from 'react-router-dom';
import Modal from 'components/UiComponents/Modal';
import moment from 'moment';
import { faqService } from 'services/faq.service';
import { toast } from 'react-toastify';
import { FORM_FORMAT } from 'constant/form.constant';

const FaqArticleManagement = () => {
  const { register, handleSubmit, trigger, reset, getValues, setValue } =
    useForm<any>();
  const navigate = useNavigate();
  const _paramsURL = useParams();
  const [paramsURL, setParamsURL] = useState({ ..._paramsURL });
  const [keyword, setKeyword] = useState<string>();
  const [loading, setLoading] = useState(false); // change to true when map data
  const [reloadPage, setReloadPage] = useState<any>();
  const [openedFilter, setOpenedFilter] = useState(false);
  const [itemPerPage, setItemPerPage] = useState<number>(20);
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [idPost, setIdPost] = useState<string>();
  const [searchParams] = useSearchParams();
  const [fromDate, setStart] = useState<any>(
    new Date(new Date().setHours(0, 0, 0, 0))
  );
  const [toDate, setEnd] = useState<any>(
    new Date(new Date().setHours(23, 59, 59, 999))
  );
  const [submitDelete, setSubmitDelete] = useState(false);
  const [confirmDeleteModal, setConfirmDeleteModal] = useState(false);
  const [filters, setFilters] = useState<any>({});
  const [data, setData] = useState<any>();
  const [idForShowSecondary, setIdForShowSecondary] = useState<any>();
  const [primaryCategories, setPrimaryCategories] = useState<any>();
  const [secondaryCategories, setSecondaryCategories] = useState<any>();

  const page = searchParams.get('page') || 1;

  const selected = (url: any) => {
    if (url) {
      navigate(url);
    } else {
      navigate(-1);
    }
  };

  const crumbs = [
    {
      name: 'Quản Lý Bài Viết',
      url: '/faq-article-management'
    }
  ];

  const onSearch = (reset?: boolean) => {
    navigate(`/faq-article-management`);
    const newKeywork = reset ? '' : keyword?.trim();
    setKeyword(newKeywork);
    setReloadPage(!reloadPage);
  };

  const _handleKeyDown = (e: any) => {
    if (e.key === 'Enter') {
      setKeyword(e.target.value);
      onSearch();
    }
  };

  const toggleFilter = () => {
    setOpenedFilter(!openedFilter);
  };

  const handlePageSizeChange = (value: any) => {
    navigate(`/faq-article-management`);
    setItemPerPage(value);
  };

  const getData = async (params: any) => {
    setLoading(true);
    const res = await faqService.getFaqArticleList(params);
    if (res?.status === 'Success') {
      setData(res?.data);
    } else {
      toast.error('Lấy danh sách bài viết thất bại!', {
        position: 'top-right',
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true
      });
    }
    setLoading(false);
  };

  const submitHandler: SubmitHandler<any> = async value => {
    let filerValues = value;

    delete filerValues['primary_category'];

    delete filerValues['secondary_category'];

    if ((idForShowSecondary && getValues('primary_category')) === '') {
      delete filerValues['CategoryId'];
    }

    if (filerValues.secondary_category === '') {
      delete filerValues['secondary_category'];
    }

    if (filerValues.status === '') {
      delete filerValues['status'];
    }

    if (fromDate) {
      const from_date = moment(fromDate)
        .startOf('day')
        .format('YYYY-MM-DD HH:mm:ss');
      filerValues = {
        ...filerValues,
        FromDate: from_date
      };
    }
    if (toDate) {
      const to_date = moment(toDate).endOf('day').format('YYYY-MM-DD HH:mm:ss');
      filerValues = {
        ...filerValues,
        ToDate: to_date
      };
    }

    setOpenedFilter(false);
    navigate(`/faq-article-management`);
    setFilters(filerValues);
  };

  const getCategoryList = async (id?: string) => {
    const param = {
      CategoryId: id,
      IsHaveFaq: null
    };

    const res = await faqService.getFaqCategoryList(param);
    if (res?.status === 'Success') {
      if (id) {
        setSecondaryCategories(res?.data);
      } else {
        setPrimaryCategories(res?.data);
      }
    } else {
      toast.error('Có lỗi, vui lòng thử lại!', {
        position: 'top-right',
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true
      });
    }
  };

  const handleDeletePost = async (id: string) => {
    setSubmitDelete(true);
    const res = await faqService.deleteFaqArticle(id);
    if (res?.status === 'Success') {
      toast.success('Xoá bài viết thành công!', {
        position: 'top-right',
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true
      });
    } else {
      toast.error('Xoá bài viết thất bại!', {
        position: 'top-right',
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true
      });
    }

    setSubmitDelete(false);
    setConfirmDeleteModal(false);
    setReloadPage(!reloadPage);
  };

  const onPageChange = useCallback(async (event: number) => {
    navigate(`/faq-article-management?page=${event}`);
  }, []);

  const clearFilter = () => {
    reset();
    setOpenedFilter(false);
    setStart(null);
    setEnd(null);
    setFilters({});
    setValue('primary_category', '');
    setValue('secondary_category', '');
    setIdForShowSecondary(null);
    navigate(`/faq-article-management`);
  };

  useEffect(() => {
    if (page) {
      setCurrentPage(Number(page));
      let params = {
        ...filters,
        CategoryId: getValues('secondary_category') || idForShowSecondary,
        Status: filters?.Status || null,
        page_index: page,
        page_size: itemPerPage
      };
      if (keyword) {
        params = {
          ...params,
          Keyword: keyword
        };
      }
      getData(params);
    }
  }, [filters, itemPerPage, keyword, page, reloadPage]);

  useEffect(() => {
    getCategoryList(idForShowSecondary);
  }, [idForShowSecondary]);

  return (
    <>
      <Breadcrumb crumbs={crumbs} selected={selected} />
      <div className="flex justify-between items-center">
        <TitlePage icon={() => <AiOutlineBook />} name="Quản Lý Bài Viết" />
        <PermissionsGate
          name={PERMISSIONS_KEY.ManageFaqPost}
          action={PERMISSIONS_ACTIONS.CREATE}
        >
          <Link to={'/faq-article-management/add'}>
            <span className="flex justify-center w-full md:w-52 form-button">
              Tạo Bài Viết Mới
            </span>
          </Link>
        </PermissionsGate>
      </div>
      <div className="mt-10">
        <div className="flex">
          <div className="flex w-1/2 space-x-2">
            <SearchButton
              onSearch={onSearch}
              keyword={keyword}
              setKeyword={setKeyword}
              _handleKeyDown={_handleKeyDown}
            />
            <div className="space-x-2">
              <FilterButton toggleFilter={toggleFilter} />
            </div>
          </div>
          <div className="ml-auto mt-2">
            <ItemsPerPage
              choice={itemPerPage}
              setChoice={(val: number) => {
                handlePageSizeChange(val);
              }}
            />
          </div>
        </div>
        <div className="w-full h-auto relative mt-2 overflow-auto rounded shadow">
          <table className="table w-full rounded-lg">
            <thead className="bg-gray-f2 border-b-2 border-gray-200">
              <tr>
                <th style={{ width: '60px' }}>STT</th>
                <th>
                  <span>Câu hỏi</span>
                </th>
                <th>
                  <span>Ngày tạo</span>
                </th>
                <th>
                  <span>Primary category</span>
                </th>
                <th>
                  <span>Secondary category</span>
                </th>
                <th>
                  <span>Ngày đăng</span>
                </th>
                <th>
                  <span>Trạng thái</span>
                </th>
                <th></th>
              </tr>
            </thead>
            {!loading && (
              <tbody>
                {data?.items?.map((item: any, index: number) => (
                  <tr>
                    <td>{(currentPage - 1) * itemPerPage + index + 1}</td>
                    <td className="font-semibold max-w-[300px]">
                      <span>{item?.question}</span>
                    </td>
                    <td>
                      <span>
                        {item.created_date
                          ? moment(
                              new Date(item?.created_date.replace('Z', ''))
                            ).format(FORM_FORMAT.TABLE_DATEFULLYEAR)
                          : '-'}
                      </span>
                    </td>
                    <td>{item?.primary_category_name}</td>
                    <td>{item?.seconary_category_name}</td>
                    <td>
                      <span>
                        {item.published_date
                          ? moment(
                              new Date(item.published_date.replace('Z', ''))
                            ).format(FORM_FORMAT.TABLE_DATEFULLYEAR)
                          : '-'}
                      </span>
                    </td>
                    <td>
                      <span className={`status status-${item?.status}`}>
                        {item?.status}
                      </span>
                    </td>
                    <td>
                      <div className="flex gap-2">
                        <PermissionsGate
                          name={PERMISSIONS_KEY.ManageFaqPost}
                          action={PERMISSIONS_ACTIONS.UPDATE}
                        >
                          <div className="items-center text-base table-action-btn">
                            <Link to={`/faq-article-management/${item?.id}`}>
                              <BiEdit className="text-0BA5EC text-lg" />
                            </Link>
                          </div>
                        </PermissionsGate>
                        <PermissionsGate
                          name={PERMISSIONS_KEY.ManageFaqPost}
                          action={PERMISSIONS_ACTIONS.DELETE}
                        >
                          <div className="flex gap-3 items-center text-base table-action-btn">
                            <div
                              onClick={() => {
                                setIdPost(item?.id);
                                setConfirmDeleteModal(true);
                              }}
                            >
                              <BiTrash className="text-red-main text-lg" />
                            </div>
                          </div>
                        </PermissionsGate>
                      </div>
                    </td>
                  </tr>
                ))}
              </tbody>
            )}
          </table>
        </div>
        {data?.items?.length === 0 && !loading && (
          <p className="text-center text-sm mt-3">No article found</p>
        )}
        {data?.total_results > itemPerPage && !loading && (
          <div className="my-6 flex text-right">
            <PaginationOwn
              totalItems={data?.total_results}
              itemPerPage={itemPerPage}
              pageChange={onPageChange}
              pageCurrent={currentPage}
            />
          </div>
        )}
        {loading && (
          <>
            <div className="flex justify-center mt-4 items-center tex-sm">
              <CircleSpin color="text-primary-e2" /> Loading...
            </div>
          </>
        )}
      </div>
      <PanelSiderBar
        open={openedFilter}
        close={() => {
          setOpenedFilter(false);
        }}
        submit={handleSubmit(submitHandler)}
        clear={clearFilter}
      >
        <form>
          <div className="form-group">
            <div>
              <label>Primary category</label>
              <select
                className="form-control mt-1"
                placeholder="---"
                {...register('primary_category', {
                  setValueAs: (value: string) => value?.trim(),
                  onChange: () => {
                    trigger('primary_category');
                    setIdForShowSecondary(getValues('primary_category'));
                  }
                })}
              >
                <option value="">{'--'}</option>
                {primaryCategories?.map((item: any) => (
                  <option key={item?.id} value={item?.id}>
                    {item?.category_name}
                  </option>
                ))}
              </select>
            </div>
          </div>
          <div className="form-group">
            <div>
              <label>Secondary category</label>
              <select
                className="form-control mt-1"
                placeholder="---"
                {...register('secondary_category', {
                  setValueAs: (value: string) => value?.trim(),
                  onChange: () => {
                    trigger('secondary_category');
                  }
                })}
              >
                <option value="">{'--'}</option>
                {secondaryCategories?.map((item: any) => (
                  <option key={item?.id} value={item?.id}>
                    {item?.category_name}
                  </option>
                ))}
              </select>
            </div>
          </div>
          <div className="form-group">
            <div>
              <label>Trạng thái</label>
              <select
                className="form-control mt-1"
                placeholder="---"
                {...register('Status', {
                  setValueAs: (value: string) => value?.trim(),
                  onChange: () => {
                    trigger('Status');
                  }
                })}
              >
                <option value="">{'--'}</option>
                <option value="Published">Published</option>
                <option value="Unpublished">Unpublished</option>
              </select>
            </div>
          </div>
          <div className="">
            <label>Ngày đăng</label>
            <div className="flex form-group space-x-2">
              <div className="w-1/2">
                <DatePicker
                  selected={fromDate}
                  dateFormat={'dd/MM/yyyy'}
                  onChange={(date: any) => {
                    setStart(date);
                  }}
                  placeholderText="Từ"
                  className="form-control"
                  maxDate={toDate}
                />
              </div>
              <div className="w-1/2">
                <DatePicker
                  selected={toDate}
                  dateFormat={'dd/MM/yyyy'}
                  onChange={(date: any) => {
                    setEnd(date);
                  }}
                  placeholderText="Đến"
                  className="form-control"
                />
              </div>
            </div>
          </div>
        </form>
      </PanelSiderBar>
      <Modal
        show={confirmDeleteModal}
        title={'Xác Nhận Xoá'}
        onClose={() => {
          setConfirmDeleteModal(false);
        }}
        className="max-w-[420px]"
      >
        <div className="flex space-x-4">
          <button
            type="button"
            className="flex justify-center w-full md:w-52 form-button-seconday"
            onClick={() => {
              setConfirmDeleteModal(false);
            }}
          >
            Huỷ
          </button>
          <button
            type="submit"
            className="flex justify-center w-full md:w-52 form-button"
            disabled={submitDelete}
            onClick={() => {
              handleDeletePost(idPost || '');
            }}
          >
            {submitDelete && <CircleSpin />}
            Đồng ý
          </button>
        </div>
      </Modal>
    </>
  );
};

export default FaqArticleManagement;
