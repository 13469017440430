import {
  DAY_END_LOGS,
  HOST_URL,
  HOST_URL_BIZ,
  SYSTEM
} from 'constant/api.contant';
import axiosInstance from './restful.service';

const getDELogs = (params?: any) => {
  return axiosInstance
    .get(HOST_URL + DAY_END_LOGS.LIST, { params })
    .then(res => {
      const data = res?.data;
      return data?.data;
    });
};

const startDE = () => {
  return axiosInstance.post(HOST_URL + DAY_END_LOGS.START_DE).then(res => {
    const data = res?.data;
    return data;
  });
};

const endDE = () => {
  return axiosInstance.post(HOST_URL + DAY_END_LOGS.END_DE).then(res => {
    const data = res?.data;
    return data;
  });
};

const getDEStatus = () => {
  return axiosInstance.get(HOST_URL_BIZ + DAY_END_LOGS.DE_STATUS).then(res => {
    const data = res?.data;
    return data?.data;
  });
};

const restartApiG2G3 = () => {
  return axiosInstance.post(HOST_URL + SYSTEM.RESTART_G2G3).then(res => {
    const data = res?.data;
    return data;
  });
};

const restartOrderPush = () => {
  return axiosInstance.post(HOST_URL + SYSTEM.RESTART_ORDER_PUSH).then(res => {
    const data = res?.data;
    return data;
  });
};

export const DelogsService = {
  getDELogs,
  startDE,
  endDE,
  getDEStatus,
  restartApiG2G3,
  restartOrderPush
};
