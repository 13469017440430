import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '../..';
import {
  cashBalanceAccountById,
  getAccountById,
  getAccounts,
  portfolioAccountById,
  updateAccountById,
  lockAccountById,
  unlockAccountById,
} from './accountsAPI';
import { ProfileItem } from 'types/User.type';

export interface AccountsState {
  accounts: any;
  accountDetail?: ProfileItem;
  isLoading: boolean;
}

const initialState: AccountsState = {
  accounts: { items: [] },
  isLoading: false,
};

export const getAccountsAsync = createAsyncThunk('accounts/getAccounts', async (params?: any) => {
  const response = await getAccounts(params);
  return response.data;
});

export const getAccountByIdAsync = createAsyncThunk(
  'accounts/getAccountById',
  async (id: string) => {
    const response = await getAccountById(id);
    return response.data;
  }
);

export const updateAccount = async (params?: any) => {
  try {
    const response = await updateAccountById(params);
    return response.data;
  } catch (error: any) {
    return { error: error.response?.data };
  }
};

export const lockAccount = async (id: string) => {
  try {
    const response = await lockAccountById(id);
    return response.data;
  } catch (error: any) {
    return { error_message: error };
  }
};

export const unlockAccount = async (id: string) => {
  try {
    const response = await unlockAccountById(id);
    return response.data;
  } catch (error: any) {
    return { error_message: error };
  }
};

export const portfolioAccountByIdAsync = createAsyncThunk(
  'accounts/portfolioAccountById',
  async (params?: any) => {
    const response = await portfolioAccountById(params);
    return response.data;
  }
);

export const cashBalanceAccountByIdAsync = createAsyncThunk(
  'accounts/cashBalanceAccountById',
  async (params?: any) => {
    const response = await cashBalanceAccountById(params);
    return response.data;
  }
);

export const accountsSlice = createSlice({
  name: 'accounts',
  initialState,
  reducers: {
    resetAccountDetail: state => {
      state.accountDetail = undefined;
    },
  },
  extraReducers: builder => {
    builder
      .addCase(getAccountsAsync.pending, state => {
        state.isLoading = true;
      })
      .addCase(getAccountsAsync.fulfilled, (state, action) => {
        state.isLoading = false;
        state.accounts = action.payload?.data;
      })
      .addCase(getAccountByIdAsync.pending, state => {
        state.isLoading = true;
      })
      .addCase(getAccountByIdAsync.fulfilled, (state, action) => {
        state.isLoading = false;
        const data = action.payload?.data;
        state.accountDetail = data;
      });
  },
});

export const { resetAccountDetail } = accountsSlice.actions;
export const selectAccounts = (state: RootState) => state.accounts;

export default accountsSlice.reducer;
