import axios, {AxiosResponse} from 'axios';
import { HOST_URL } from 'constant/api.contant';
const axiosInstance = axios.create({
  baseURL: HOST_URL,
  headers: {
    Accept: 'application/json',
    'Content-Type': 'application/json'
  }
});
axiosInstance.interceptors.request.use(
  (config: any) => {
    let user: any = localStorage.getItem('user');
    // let user: any = sessionStorage.getItem('user');
    if (user) {
      user = JSON.parse(user);
      const token = user.access_token;
      const auth = token ? `Bearer ${token}` : '';
      config.headers.common['Authorization'] = auth;
    }     
    return config;
  },
  (error) => Promise.reject(error),
);
export default axiosInstance;
export class RestfulService {
  static deleteApi = (
    url: string,
    body?: null
  ): Promise<AxiosResponse<null>> => {
    return axiosInstance
      .delete(url, {
        data: body ? body : null,
      })
      .then((res) => res)
      .catch(function (error) {
        if (error.response) {
          // The request was made and the server responded with a status code
          // that falls out of the range of 2xx
          return error.response;
        }
      });
    };
}