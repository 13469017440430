import { ArticleCard } from 'components/ArticleCard';
import Breadcrumb from 'components/Breadcrumb';
import TitlePage from 'components/TitlePage';
import { CircleSpin } from 'components/UiComponents';
import Modal from 'components/UiComponents/Modal';
import React, { useEffect, useState } from 'react';
import { Controller, SubmitHandler, useForm } from 'react-hook-form';
import { AiOutlineBook, AiOutlineDelete, AiOutlineEdit } from 'react-icons/ai';
import { BiImage } from 'react-icons/bi';
import { useNavigate, useParams } from 'react-router-dom';
import Select from 'react-select';
import Switch from 'react-switch';
import { toast } from 'react-toastify';
import { faqService } from 'services/faq.service';
import { useAppDispatch } from 'store/hooks';
import { getUrlUploadFilesAsync } from 'store/slices/announcement/announcementSlice';
import { generateSlug } from 'utils';

const OPTIONS_ACTION = [
  {
    value: 'Primary',
    label: 'Primary',
  },
  {
    value: 'Secondary',
    label: 'Secondary',
  },
];

const FaqCategoryAdd = () => {
  const {
    register,
    handleSubmit,
    setValue,
    getValues,
    trigger,
    setError,
    control,
    formState: { errors },
  } = useForm<any>({});
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [confirmShowModal, setConfirmShowModal] = useState(false);
  const [submit, setSubmit] = useState(false);
  const [status, setStatus] = useState(false);
  const paramsURL = useParams();
  const paramsId = paramsURL?.id;
  const [listPrimaryCategory, setListPrimaryCategory] = useState<any>();
  const [imageURL, setImageUrl] = useState<any>();
  const [onErrorImage, setOnErrorImage] = useState(false);
  const [uploadImageStatus, setUploadImageStatus] = useState<boolean>(false);
  const [uploadImageErrorMessage, setUploadImageErrorMessage] = useState<string>('');
  const [currentFile, setCurrentFile] = useState<any>();

  const [crumbs] = useState([
    {
      name: 'Quản Lý Category',
      url: '/faq-category-management',
    },
    {
      name: `${paramsId ? 'Chỉnh Sửa' : 'Tạo Mới'} Category`,
      url: `${paramsId ? `/faq-category-management/${paramsId}` : '/faq-category-management/add'}`,
    },
  ]);

  const selected = (url: any) => {
    if (url) {
      navigate(url);
    } else {
      navigate(-1);
    }
  };

  const getFaqCategoryDetail = async (id: string) => {
    setLoading(true);
    const res = await faqService.getFaqCategoryDetail(id);
    if (res?.status === 'Success') {
      setStatus(res?.data?.is_active);
      setValue('category_name', res?.data?.category_name);
      setValue('category_name_eng', res?.data?.category_name_eng);
      setValue('category_order', res?.data?.index.toString());
      setValue('slug', res?.data?.slug);
      setImageUrl(res?.data?.icon_url);
      if (res?.data?.primary_category_id) {
        setValue('category_type', {
          value: 'Secondary',
          label: 'Secondary',
        });
        setValue('primary_category', {
          value: res?.data?.primary_category_id,
          label: res?.data?.primary_category_name,
        });
      } else {
        setValue('category_type', {
          value: 'Primary',
          label: 'Primary',
        });
      }
    }
    setLoading(false);
  };

  const getPrimaryCategory = async () => {
    const res = await faqService.getFaqCategoryList({ IsHaveFaq: false });

    if (res?.status === 'Success') {
      setListPrimaryCategory(
        res?.data?.map((item: any) => {
          return {
            value: item?.id,
            label: item?.category_name,
          };
        })
      );
    } else {
      toast.error('Có lỗi, không lấy được danh sách primary category!', {
        position: 'top-right',
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
      });
    }
  };

  const submitHandler: SubmitHandler<any> = async values => {
    setSubmit(true);
    if (!values?.primary_category?.value && values?.category_type?.value !== 'Primary') {
      setError('primary_category', {
        type: 'manual',
        message: 'Vui lòng chọn primary category !',
      });
      setSubmit(false);
      setConfirmShowModal(false);
      return;
    }

    let params = {
      parent_id: values?.primary_category?.value,
      category_name: values?.category_name,
      category_name_eng: values?.category_name_eng,
      index: Number(values?.category_order),
      is_active: status,
      slug: values?.slug,
      icon_url: imageURL,
    };

    if (values?.category_type?.value === 'Primary') {
      delete params.parent_id;
    }

    if (paramsId) {
      if (currentFile) {
        const paramsGetUrlUpload = {
          file_type: 'Faq',
        };

        const resLinkStore = await dispatch(getUrlUploadFilesAsync(paramsGetUrlUpload));
        if (resLinkStore?.payload?.status === 'Success') {
          let urlUpload = resLinkStore?.payload?.data?.url;
          if (urlUpload) {
            const res = await fetch(urlUpload, {
              method: 'PUT',
              body: currentFile,
              headers: {
                'Content-Type': currentFile?.type,
              },
            });
            if (res.status === 200) {
              try {
                params = {
                  ...params,
                  icon_url: resLinkStore?.payload?.data?.url_image,
                };
              } catch (error: any) {
                error.response.data.forEach((item: any) => {
                  toast.error(item?.error_message, {
                    position: 'top-right',
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                  });
                });
              }
            } else {
              toast.error('Có lỗi, không upload được icon!', {
                position: 'top-right',
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
              });
            }
          } else {
            toast.error('Có lỗi, không lấy được đường dẫn upload icon!', {
              position: 'top-right',
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
            });
          }
        } else {
          toast.error('Có lỗi, không lấy được đường dẫn upload icon!', {
            position: 'top-right',
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
          });
        }
      }
      const res = await faqService.updateFaqCategory(paramsId, params);

      if (res?.status === 'Success') {
        toast.success('Cập nhật category thành công!', {
          position: 'top-right',
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
        });
      } else {
        toast.error(res?.error_message || 'Có lỗi, không cập nhật được category!', {
          position: 'top-right',
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
        });
      }
    }

    if (!paramsId) {
      if (currentFile) {
        const paramsGetUrlUpload = {
          file_type: 'Faq',
        };

        const resLinkStore = await dispatch(getUrlUploadFilesAsync(paramsGetUrlUpload));
        if (resLinkStore?.payload?.status === 'Success') {
          let urlUpload = resLinkStore?.payload?.data?.url;
          if (urlUpload) {
            const res = await fetch(urlUpload, {
              method: 'PUT',
              body: currentFile,
              headers: {
                'Content-Type': currentFile?.type,
              },
            });
            if (res.status === 200) {
              try {
                params = {
                  ...params,
                  icon_url: resLinkStore?.payload?.data?.url_image,
                };
              } catch (error: any) {
                error.response.data.forEach((item: any) => {
                  toast.error(item?.error_message, {
                    position: 'top-right',
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                  });
                });
              }
            } else {
              toast.error('Có lỗi, không upload được icon!', {
                position: 'top-right',
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
              });
            }
          } else {
            toast.error('Có lỗi, không lấy được đường dẫn upload icon!', {
              position: 'top-right',
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
            });
          }
        } else {
          toast.error('Có lỗi, không lấy được đường dẫn upload icon!', {
            position: 'top-right',
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
          });
        }
      }

      const res = await faqService.addFaqCategory(params);

      if (res?.status === 'Success') {
        toast.success('Thêm mới category thành công!', {
          position: 'top-right',
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
        });
        navigate(`/faq-category-management`);
      } else {
        toast.error(res?.error_message || 'Có lỗi, không thêm mới được category!', {
          position: 'top-right',
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
        });
      }
    }
    setConfirmShowModal(false);
    setSubmit(false);
  };

  const onError = (errors: any, e: any) => {
    if (errors) {
      setSubmit(false);
      setConfirmShowModal(false);
    }

    const category_type = getValues('category_type');
    const primary_category = getValues('primary_category');
    if (!category_type?.value) {
      setError('category_type', {
        type: 'manual',
        message: 'Vui lòng chọn loại category !',
      });
    }
    if (!primary_category?.value && category_type?.value !== 'Primary') {
      setError('primary_category', {
        type: 'manual',
        message: 'Vui lòng chọn primary category !',
      });
    }
  };

  const uploadImage = async (e: any) => {
    if (e.target.files[0]) {
      setUploadImageStatus(false);
      setUploadImageErrorMessage('');
      setImageUrl(URL.createObjectURL(e.target.files[0]));
      setCurrentFile(e.target.files[0]);
    }
    setOnErrorImage(false);
  };

  useEffect(() => {
    getPrimaryCategory();
  }, []);

  useEffect(() => {
    if (paramsId) {
      getFaqCategoryDetail(paramsId);
    }
  }, [paramsId]);

  return (
    <>
      <Breadcrumb crumbs={crumbs} selected={selected} />
      <TitlePage
        icon={() => <AiOutlineBook />}
        name={`${paramsId ? 'Chỉnh sửa' : 'Tạo mới'} Category`}
      />
      <div className="mt-10">
        <form
          className="form"
          onSubmit={handleSubmit(submitHandler, onError)}
          noValidate
          autoComplete="off"
        >
          <div className="flex gap-3">
            <ArticleCard className="w-full pt-14 relative">
              {!loading && (
                <>
                  <div className="absolute right-3 top-3 flex gap-5">
                    <label htmlFor="category_type">Trạng thái</label>
                    <Switch
                      onChange={(value: any) => {
                        setStatus(value);
                      }}
                      checked={status}
                      className="react-switch"
                      boxShadow="0px 1px 5px rgba(0, 0, 0, 0.6)"
                      activeBoxShadow="0px 0px 1px 10px rgba(0, 0, 0, 0.2)"
                      height={24}
                      width={44}
                      handleDiameter={18}
                      onColor={'#61CB2F'}
                      checkedIcon={false}
                      uncheckedIcon={false}
                    />
                  </div>
                  <div className="lg:flex lg:gap-4">
                    <div className="w-1/2">
                      <div className="form-group">
                        <label htmlFor="category_type">Icon</label>
                        <div className="flex gap-3 items-start">
                          <div className="user-info-img w-[200px] h-auto">
                            <div className="relative w-[200px] h-auto items-center justify-center">
                              {!imageURL && !onErrorImage && (
                                <div className="flex items-center justify-center top-0 left-0 z-50 w-[200px] h-[200px] bg-[#e4f4ff] border border-dashed border-[#0795fe] rounded-lg cursor-pointer">
                                  <input
                                    type="file"
                                    className="opacity-0 absolute top-0 left-0 z-50 w-full h-full cursor-pointer"
                                    onChange={uploadImage}
                                    accept="image/*"
                                  />
                                  <span className="text-[#0795fe] text-sm font-semibold line leading-6 cursor-pointer">
                                    Tải hình ảnh lên
                                  </span>
                                </div>
                              )}
                              {imageURL && !onErrorImage && (
                                <div>
                                  <div className="absolute flex items-center justify-center top-0 left-0 z-50 w-full h-full bg-gray-33/70 user-info-img-upload rounded-xl">
                                    <input
                                      type="file"
                                      className="opacity-0 absolute top-0 left-0 z-50 w-full h-full rounded-xl"
                                      onChange={uploadImage}
                                      accept="image/*"
                                    />
                                    <AiOutlineEdit className="text-white" />
                                  </div>
                                  <img
                                    src={imageURL}
                                    alt=""
                                    className="mt-2 mb-4 w-full h-full rounded-xl"
                                    onError={() => setOnErrorImage(true)}
                                  />
                                </div>
                              )}
                              {onErrorImage && (
                                <div>
                                  <div className="absolute flex items-center justify-center top-0 left-0 z-50 w-full h-full bg-gray-33/70 user-info-img-upload rounded-xl">
                                    <input
                                      type="file"
                                      className="opacity-0 absolute top-0 left-0 z-50 w-full h-full rounded-xl"
                                      onChange={uploadImage}
                                      accept="image/*"
                                    />
                                    <AiOutlineEdit className="text-white" />
                                  </div>
                                  <BiImage className="text-[100px] ml-1 self-center" />
                                </div>
                              )}
                              <input
                                id="image"
                                type="hidden"
                                autoComplete="image"
                                className="form-control"
                                {...register('image', {
                                  setValueAs: (value: string) => value?.trim(),
                                  onChange: () => {
                                    trigger('image');
                                  },
                                  //   value: categorysData?.image_url,
                                })}
                              />
                              {uploadImageStatus && (
                                <span className="text-red-main text-xs">
                                  {uploadImageErrorMessage}
                                </span>
                              )}
                            </div>
                          </div>
                          {imageURL && (
                            <div className="flex gap-2 items-center text-base table-action-btn mt-2">
                              <div
                                onClick={e => {
                                  setImageUrl('');
                                  setCurrentFile('');
                                  setOnErrorImage(false);
                                }}
                              >
                                <AiOutlineDelete className="text-red-main text-lg" />
                              </div>
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="lg:flex lg:gap-4">
                    <div className="form-group flex-1 w-1/2">
                      <label htmlFor="category_type" className="required-before">
                        Loại category
                      </label>
                      <Controller
                        name="category_type"
                        control={control}
                        render={({ field }) => {
                          return (
                            <Select
                              {...field}
                              id="category_type"
                              placeholder="Vui lòng chọn loại category"
                              options={OPTIONS_ACTION}
                              onChange={option => {
                                field.onChange(option);
                                setValue('category_type', option, {
                                  shouldValidate: true,
                                });
                              }}
                              value={getValues('category_type')}
                            />
                          );
                        }}
                      />
                      <span className="text-red-main text-xs">
                        {errors?.category_type?.message as string}
                      </span>
                    </div>
                    <div className="form-group flex-1 w-1/2">
                      <label htmlFor="primary_category" className="required-before">
                        Primary category
                      </label>
                      <Controller
                        name="primary_category"
                        control={control}
                        render={({ field }) => {
                          return (
                            <Select
                              {...field}
                              id="primary_category"
                              placeholder="Vui lòng chọn primary category"
                              options={listPrimaryCategory}
                              isDisabled={getValues('category_type')?.value === 'Primary'}
                              onChange={option => {
                                field.onChange(option);
                                setValue('primary_category', option, {
                                  shouldValidate: true,
                                });
                              }}
                              value={getValues('primary_category')}
                            />
                          );
                        }}
                      />
                      <span className="text-red-main text-xs">
                        {errors?.primary_category?.message as string}
                      </span>
                    </div>
                  </div>
                  <div className="lg:flex lg:gap-4">
                    <div className="form-group w-1/2">
                      <label htmlFor="category_name" className="required-before">
                        Tên category
                      </label>
                      <input
                        id="category_name"
                        type="text"
                        autoComplete="category_name"
                        placeholder="Nhập tên category"
                        className="form-control"
                        {...register('category_name', {
                          required: 'Vui lòng nhập tên category !',
                          setValueAs: (value: string) => value?.trim(),
                          onChange: () => {
                            trigger('category_name');
                            setValue('slug', generateSlug(getValues('category_name')));
                          },
                        })}
                      />
                      <span className="text-red-main text-xs">
                        {errors?.category_name?.message as string}
                      </span>
                    </div>
                    <div className="form-group w-1/2">
                      <label htmlFor="category_name_eng" className="required-before">
                        Tên category (EN)
                      </label>
                      <input
                        id="category_name_eng"
                        type="text"
                        autoComplete="category_name_eng"
                        placeholder="Nhập tên category"
                        className="form-control"
                        {...register('category_name_eng', {
                          required: 'Vui lòng nhập tên category (EN) !',
                          setValueAs: (value: string) => value?.trim(),
                          onChange: () => {
                            trigger('category_name_eng');
                          },
                        })}
                      />
                      <span className="text-red-main text-xs">
                        {errors?.category_name_eng?.message as string}
                      </span>
                    </div>
                  </div>
                  <div className="lg:flex lg:gap-4">
                    <div className="form-group w-1/2">
                      <label htmlFor="category_order" className="required-before">
                        Slug
                      </label>
                      <input
                        id="slug"
                        type="text"
                        autoComplete="slug"
                        placeholder="Nhập thứ tự hiển thị"
                        className="form-control"
                        {...register('slug', {
                          required: 'Vui lòng nhập slug !',
                          setValueAs: (value: string) => value?.trim(),
                          onChange: () => {
                            trigger('slug');
                          },
                        })}
                      />
                      <span className="text-red-main text-xs">
                        {errors?.slug?.message as string}
                      </span>
                    </div>
                    <div className="form-group w-1/2">
                      <label htmlFor="category_order" className="required-before">
                        Thứ tự hiển thị
                      </label>
                      <input
                        id="category_order"
                        type="number"
                        autoComplete="category_order"
                        placeholder="Nhập thứ tự hiển thị"
                        className="form-control"
                        {...register('category_order', {
                          required: 'Vui lòng nhập thứ tự ưu tiên !',
                          setValueAs: (value: string) => value?.trim(),
                          onChange: () => {
                            trigger('category_order');
                          },
                        })}
                      />
                      <span className="text-red-main text-xs">
                        {errors?.category_order?.message as string}
                      </span>
                    </div>
                  </div>
                </>
              )}
              {loading && (
                <div className="flex justify-center">
                  <CircleSpin color="text-primary-e2" /> Loading...
                </div>
              )}
            </ArticleCard>
          </div>
          <div className={`flex mt-2 gap-5`}>
            {paramsId ? (
              <button
                type="button"
                className="form-button !min-w-[211px]"
                onClick={() => {
                  setConfirmShowModal(true);
                }}
              >
                Lưu
              </button>
            ) : (
              <button
                type="button"
                className="form-button !min-w-[211px]"
                onClick={() => {
                  setConfirmShowModal(true);
                }}
              >
                Thêm
              </button>
            )}
          </div>
          <Modal
            show={confirmShowModal}
            title={`Xác Nhận ${paramsId ? 'Chỉnh Sửa' : 'Tạo Mới'}`}
            onClose={() => {
              setConfirmShowModal(false);
            }}
            className="max-w-[420px]"
          >
            <div className="flex space-x-4">
              <button
                type="button"
                className="flex justify-center w-full md:w-52 form-button-seconday"
                onClick={() => {
                  setConfirmShowModal(false);
                }}
              >
                Huỷ
              </button>
              <button
                type="submit"
                className="flex justify-center w-full md:w-52 form-button"
                disabled={submit}
                onClick={handleSubmit(submitHandler, onError)}
              >
                {submit && <CircleSpin />}
                Đồng ý
              </button>
            </div>
          </Modal>
        </form>
      </div>
    </>
  );
};

export default FaqCategoryAdd;
