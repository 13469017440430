import React, { useState } from 'react';
import Breadcrumb from 'components/Breadcrumb';
import { AiOutlineAreaChart } from 'assets/icons';
import TitlePage from 'components/TitlePage';
import { ColumnChart, PieChart, LineChart, AreaChart } from 'components/Chart';

const DashboardPage = () => {
  const [crumbs] = useState(['DashBoard']);
  const selected = (crumb: any) => {
    console.log(crumb);
  };
  return (
    <>
      <Breadcrumb crumbs={crumbs} selected={selected} />
      <TitlePage icon={() => <AiOutlineAreaChart />} name="DashBoard" />
      <div className="flex mt-8">
        <div className="w-1/2">
          <ColumnChart />
        </div>
        <div className="w-1/2">
          <PieChart />
        </div>
      </div>
      <div className="flex">
        <div className="w-1/2">
          <LineChart />
        </div>
        <div className="w-1/2">
          <AreaChart />
        </div>
      </div>
    </>
  );
};

export default DashboardPage;
