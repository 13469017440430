import { PayloadAction, createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import {
  createAnnouncement,
  deleteAnnouncement,
  deleteFile,
  getAnnouncement,
  getAnnouncementById,
  updateAnnouncement,
  getUrlUploadFile,
  createFile,
  updateFile
} from './announcementAPI';
import { RootState } from 'store';

export interface AnnouncementState {
  annoncement: any;
  status: 'idle' | 'loading' | 'failed';
}

const initialState: AnnouncementState = {
  annoncement: [],
  status: 'idle'
};

export const getAnnouncementAsync = createAsyncThunk(
  'announcement/getAnnouncement',
  async (params?: any) => {
    const response = await getAnnouncement(params);
    return response.data;
  }
);

export const getAnnouncementByIdAsync = createAsyncThunk(
  'announcement/getAnnouncementById',
  async (id: any) => {
    const response = await getAnnouncementById(id);
    return response.data;
  }
);

export const createAnnouncementAsync = createAsyncThunk(
  'announcement/createAnnouncement',
  async (params?: any) => {
    const response = await createAnnouncement(params);
    return response.data;
  }
);

export const updateAnnouncementAsync = createAsyncThunk(
  'announcement/updateAnnouncement',
  async (params?: any) => {
    const response = await updateAnnouncement(params);
    return response.data;
  }
);

export const getUrlUploadFilesAsync = createAsyncThunk(
  'announcement/uploadFiles',
  async (formData?: any) => {
    const response = await getUrlUploadFile(formData);
    return response.data;
  }
);

export const deleteAnnouncementAsync = createAsyncThunk(
  'announcement/deleteAnnouncement',
  async (param: any) => {
    const response = await deleteAnnouncement(param.id);
    return response.data;
  }
);

export const deleteFileAsync = createAsyncThunk(
  'announcement/deleteFile',
  async (param: any) => {
    const response = await deleteFile(param.announcement_id, param.file_id);
    return response.data;
  }
);

export const createFileAsync = createAsyncThunk(
  'announcement/createFile',
  async (params?: any) => {
    const response = await createFile(params);
    return response.data;
  }
);

export const updateFileAsync = createAsyncThunk(
  'announcement/updateFile',
  async (params?: any) => {
    const response = await updateFile(params);
    return response.data;
  }
);

export const AnnouncementsSlice = createSlice({
  name: 'announcement',
  initialState,
  reducers: {
    getList: (state, action: PayloadAction<undefined>) => {
      state.annoncement = action.payload;
    }
  },
  extraReducers: builder => {
    builder
      .addCase(getAnnouncementAsync.pending, state => {
        state.status = 'loading';
      })
      .addCase(getAnnouncementAsync.fulfilled, (state, action) => {
        state.status = 'idle';
        state.annoncement = action.payload;
      });
  }
});

export const { getList } = AnnouncementsSlice.actions;
export const selectAnnouncement = (state: RootState) => state.announcement;

export default AnnouncementsSlice.reducer;
