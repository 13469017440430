import { HOST_URL, PORTFOLIO_PLANNING_ASSET_RATIO } from "constant/api.contant";
import axiosInstance from "services/restful.service";

export const getPortfolioPlanningAssetRatios = (params: any) => {
    return axiosInstance.get(HOST_URL + PORTFOLIO_PLANNING_ASSET_RATIO.GET_ASSET_RATIOS, {params});
}

export const getPortfolioPlanningAssetRatioById = (id: number) => {
    return axiosInstance.get(HOST_URL + PORTFOLIO_PLANNING_ASSET_RATIO.GET_ASSET_RATIO_BY_ID(id));
}

export const updatePortfolioPlanningAssetRatioById = (params: any) => {
    return axiosInstance.put(HOST_URL + PORTFOLIO_PLANNING_ASSET_RATIO.UPDATE_ASSET_RATIO_BY_ID(params.asset_ratio), params);
}