import { HOST_URL, NOTIFICATIONS } from "constant/api.contant";
import axiosInstance from "services/restful.service";

export const getListNotificationConfigs = (params: any) => {
    return axiosInstance.get(HOST_URL + NOTIFICATIONS.LIST_CONFIGS, {params});
}

export const getNotificationDetail = (id: string) => {
    return axiosInstance.get(HOST_URL + NOTIFICATIONS.CONFIG_DETAIL(id));
}

export const getListNotificationTemplates = (params: any) => {
    return axiosInstance.get(HOST_URL + NOTIFICATIONS.LIST_TEMPLATES, {params});
}

export const getNotificationTemplateDetail = (id: string) => {
    return axiosInstance.get(HOST_URL + NOTIFICATIONS.TEMPLATE_DETAIL(id));
}

export const updateNotificationTemplateById = (params: any) => {
    return axiosInstance.put(HOST_URL + NOTIFICATIONS.UPDATE_TEMPLATE(params.id), params);
}

