import React, { useEffect, useState } from 'react';
import { ArticleCard } from 'components/ArticleCard';
import Breadcrumb from 'components/Breadcrumb';
import TitlePage from 'components/TitlePage';
import { CircleSpin } from 'components/UiComponents';
import Modal from 'components/UiComponents/Modal';
import { SubmitHandler, useForm, Controller } from 'react-hook-form';
import { AiOutlineBook } from 'react-icons/ai';
import { BiImage } from 'react-icons/bi';
import { AiOutlineEdit } from 'assets/icons';
import { useNavigate, useParams, useLocation } from 'react-router-dom';
import Select from 'react-select';
import { toast } from 'react-toastify';
import { getUploadImageUrl } from 'services/upload.service';
import {
  addBanner,
  getBannerDetail,
  updateBanner
} from 'services/banner.service';

const OPTIONS_ACTION = [
  {
    value: 'NewTab',
    label: 'Mở link trong cửa sổ trình duyệt mới'
  },
  {
    value: 'SameTab',
    label: 'Mở link trong cửa sổ trình duyệt hiện tại'
  }
];

const BannerAdd = () => {
  const {
    register,
    handleSubmit,
    setValue,
    trigger,
    control,
    formState: { errors }
  } = useForm<any>({});
  const paramsURL = useParams();
  const location = useLocation();
  const navigate = useNavigate();
  const state = location?.state as { data: any };
  const paramsId = paramsURL?.id;
  const bannerDataState = state?.data;
  const [loading, setLoading] = useState(false);
  const [confirmAddModal, setConfirmAddModal] = useState(false);
  const [confirmUpdateModal, setConfirmUpdateModal] = useState(false);
  const [submitAdd, setSubmitAdd] = useState(false);
  const [submitUpdate, setSubmitUpdate] = useState(false);
  const [bannerDetail, setBannerDetail] = useState<any>(bannerDataState);
  const [onErrorImage, setOnErrorImage] = useState(false);
  const [imageURL, setImageUrl] = useState<any>();
  const [uploadImageErrorMessage, setUploadImageErrorMessage] =
    useState<string>('');

  const selected = (url: any) => {
    if (url) {
      navigate(url);
    } else {
      navigate(-1);
    }
  };

  const crumbs = [
    {
      name: 'Quản Lý Banner',
      url: '/banner-management'
    },
    {
      name: paramsId ? 'Chỉnh sửa Banner' : 'Tạo mới Banner'
    }
  ];

  const getDetai = async (id: string) => {
    setLoading(true);
    try {
      const res = await getBannerDetail(id);
      if (res.data) {
        const action = OPTIONS_ACTION.find(
          i => i.value === res.data?.button_type
        );
        setValue('title', res.data?.title);
        setValue('title_eng', res.data?.title_eng);
        setValue('content', res.data?.content);
        setValue('content_eng', res.data?.content_eng);
        setValue('button_text', res.data?.button_text);
        setValue('button_text_eng', res.data?.button_text_eng);
        setValue('button_type', action);
        setValue('button_url', res.data?.button_url);
        setValue('button_url_eng', res.data?.button_url_eng);
        setValue('index', res.data?.index);
        setImageUrl(res.data?.image_url);
        setBannerDetail(res.data);
      }
    } catch (error: any) {}
    setLoading(false);
  };

  const submitAddHandler: SubmitHandler<any> = async values => {
    setSubmitAdd(true);
    const params = {
      image_url: imageURL,
      title: values?.title,
      title_eng: values?.title_eng,
      content: values?.content,
      content_eng: values?.content_eng,
      button_url: values?.button_url,
      button_url_eng: values?.button_url_eng,
      button_text: values?.button_text,
      button_text_eng: values?.button_text_eng,
      button_type: values?.button_type?.value,
      index: values?.index || 0
    };

    const res = await addBanner(params);
    if (res?.status === 'Success') {
      const data = res?.data;
      toast.success('Tạo banner thành công!', {
        position: 'top-right',
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined
      });
      setSubmitAdd(false);
      setConfirmAddModal(false);
      navigate(`/banner-management/edit/${data.id}`, {
        state: { data }
      });
    } else {
      toast.error(res?.error_message || 'Có lỗi, vui lòng kiểm tra lại!', {
        position: 'top-right',
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined
      });
      setSubmitAdd(false);
      setConfirmAddModal(false);
    }
  };

  const submitUpdateHandler: SubmitHandler<any> = async values => {
    setSubmitUpdate(true);
    const params = {
      image_url: imageURL,
      title: values?.title,
      title_eng: values?.title_eng,
      content: values?.content,
      content_eng: values?.content_eng,
      button_url: values?.button_url,
      button_url_eng: values?.button_url_eng,
      button_text: values?.button_text,
      button_text_eng: values?.button_text_eng,
      button_type: values?.button_type?.value,
      index: values?.index || 0
    };
    const res = await updateBanner(paramsId || '', params);
    if (res?.status === 'Success') {
      const data = res?.data;
      toast.success('Chỉnh sửa banner thành công!', {
        position: 'top-right',
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined
      });
      setSubmitUpdate(false);
      setConfirmUpdateModal(false);
      navigate(`/banner-management/edit/${data.id}`, {
        state: { data }
      });
    } else {
      toast.error(res?.error_message || 'Có lỗi, vui lòng kiểm tra lại!', {
        position: 'top-right',
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined
      });
      setSubmitUpdate(false);
      setConfirmUpdateModal(false);
    }
  };

  const onError = (errors: any, e: any) => {
    if (errors) {
      setSubmitAdd(false);
      setConfirmAddModal(false);
      setSubmitUpdate(false);
      setConfirmUpdateModal(false);
      if (!imageURL) {
        setUploadImageErrorMessage('Vui lòng chọn banner');
      }
    }
  };

  useEffect(() => {
    if (paramsId && !bannerDataState) {
      getDetai(paramsId);
    }
  }, [paramsId, bannerDataState]);

  const uploadImage = async (e: any) => {
    if (e.target.files[0]) {
      const file = e?.target?.files[0];
      setUploadImageErrorMessage('');
      const currentSize = Math.floor(e.target.files[0].size / 1024);
      if (currentSize > 500) {
        setUploadImageErrorMessage('Dung lượng tối đa 500KB!');
      } else {
        const urlRes = await getUploadImageUrl('Banner');
        const upload_url = urlRes?.url;
        fetch(upload_url, {
          method: 'PUT',
          body: file,
          headers: {
            'Content-Type': file?.type
          }
        })
          .then(async () => {
            setImageUrl(urlRes?.url_image);
          })
          .catch(() => {
            console.log('Upload failed');
          });
      }
    }
  };

  return (
    <>
      {loading ? (
        <>
          <div className="flex justify-center mt-4 items-center tex-sm">
            <CircleSpin color="text-primary-e2" /> Loading...
          </div>
        </>
      ) : (
        <>
          <Breadcrumb crumbs={crumbs} selected={selected} />
          <TitlePage
            icon={() => <AiOutlineBook />}
            name={`${paramsId ? 'Chỉnh sửa' : 'Tạo mới'} Banner`}
          />
          <div className="mt-10">
            <form
              className="form"
              onSubmit={handleSubmit(submitAddHandler, onError)}
              noValidate
              autoComplete="off"
            >
              <div className="flex gap-3">
                <ArticleCard className="w-full">
                  <div className="form-group">
                    <label htmlFor="title" className="required-before">
                      Tiêu đề
                    </label>
                    <input
                      id="title"
                      type="text"
                      autoComplete="title"
                      placeholder="Nhập tiêu đề"
                      className="form-control"
                      {...register('title', {
                        required: 'Vui lòng nhập tiêu đề',
                        setValueAs: (value: string) => value?.trim(),
                        onChange: () => {
                          trigger('title');
                        }
                      })}
                    />
                    <span className="text-red-main text-xs">
                      {errors?.title?.message as string}
                    </span>
                  </div>
                  <div className="form-group">
                    <label htmlFor="title_eng" className="required-before">
                      Tiêu đề (EN)
                    </label>
                    <input
                      id="title_eng"
                      type="text"
                      autoComplete="title_eng"
                      placeholder="Nhập tiêu đề (EN)"
                      className="form-control"
                      {...register('title_eng', {
                        required: 'Vui lòng nhập tiêu đề (EN)',
                        setValueAs: (value: string) => value?.trim(),
                        onChange: () => {
                          trigger('title_eng');
                        }
                      })}
                    />
                    <span className="text-red-main text-xs">
                      {errors?.title_eng?.message as string}
                    </span>
                  </div>
                  <div className="form-group">
                    <label htmlFor="content" className={`form-label`}>
                      Nội dung
                    </label>
                    <textarea
                      id="content"
                      placeholder="Nhập nội dung"
                      className="form-area"
                      required
                      rows={3}
                      cols={50}
                      {...register('content', {
                        required: false,
                        setValueAs: (value: string) => value?.trim()
                      })}
                    ></textarea>
                  </div>
                  <div className="form-group">
                    <label htmlFor="content_eng" className={`form-label`}>
                      Nội dung (EN)
                    </label>
                    <textarea
                      id="content_eng"
                      placeholder="Nhập nội dung (EN)"
                      className="form-area"
                      required
                      rows={3}
                      cols={50}
                      {...register('content_eng', {
                        required: false,
                        setValueAs: (value: string) => value?.trim()
                      })}
                    ></textarea>
                  </div>
                  <div className="lg:flex lg:gap-4">
                    <div className="form-group lg:flex-1">
                      <label htmlFor="button_url" className="required-before">
                        Link
                      </label>
                      <input
                        id="button_url"
                        type="text"
                        autoComplete="button_url"
                        placeholder="Nhập link"
                        className="form-control"
                        {...register('button_url', {
                          required: 'Vui lòng nhập link',
                          setValueAs: (value: string) => value?.trim(),
                          onChange: () => {
                            trigger('button_url');
                          }
                        })}
                      />
                      <span className="text-red-main text-xs">
                        {errors?.button_url?.message as string}
                      </span>
                    </div>
                    <div className="form-group lg:flex-1">
                      <label htmlFor="button_url" className="required-before">
                        Link (EN)
                      </label>
                      <input
                        id="button_url_eng"
                        type="text"
                        autoComplete="button_url_eng"
                        placeholder="Nhập link (EN)"
                        className="form-control"
                        {...register('button_url_eng', {
                          required: 'Vui lòng nhập link (EN)',
                          setValueAs: (value: string) => value?.trim(),
                          onChange: () => {
                            trigger('button_url_eng');
                          }
                        })}
                      />
                      <span className="text-red-main text-xs">
                        {errors?.button_url_eng?.message as string}
                      </span>
                    </div>
                  </div>
                  <div className="lg:flex lg:gap-4">
                    <div className="form-group flex-1">
                      <label htmlFor="button_text" className="required-before">
                        Nội dung CTA
                      </label>
                      <input
                        id="button_text"
                        type="text"
                        autoComplete="button_text"
                        placeholder="Nhập nội dung"
                        className="form-control"
                        {...register('button_text', {
                          required: 'Vui lòng nhập nội dung',
                          setValueAs: (value: string) => value?.trim(),
                          onChange: () => {
                            trigger('button_text');
                          }
                        })}
                      />
                      <span className="text-red-main text-xs">
                        {errors?.button_text?.message as string}
                      </span>
                    </div>
                    <div className="form-group flex-1">
                      <label
                        htmlFor="button_text_eng"
                        className="required-before"
                      >
                        Nội dung CTA (EN)
                      </label>
                      <input
                        id="button_text_eng"
                        type="text"
                        autoComplete="button_text_eng"
                        placeholder="Nhập nội dung CTA (EN)"
                        className="form-control"
                        {...register('button_text_eng', {
                          required: 'Vui lòng nhập nội dung CTA (EN)',
                          setValueAs: (value: string) => value?.trim(),
                          onChange: () => {
                            trigger('button_text_eng');
                          }
                        })}
                      />
                      <span className="text-red-main text-xs">
                        {errors?.button_text_eng?.message as string}
                      </span>
                    </div>
                  </div>
                  <div className="lg:flex lg:gap-4">
                    <div className="form-group flex-1">
                      <label htmlFor="">Hành động</label>
                      <Controller
                        name="button_type"
                        control={control}
                        render={({ field }) => {
                          return (
                            <Select
                              {...field}
                              id="button_type"
                              placeholder="Vui lòng chọn hành động"
                              options={OPTIONS_ACTION}
                            />
                          );
                        }}
                      />
                      <span className="text-red-main text-xs">
                        {errors?.button_type?.message as string}
                      </span>
                    </div>
                  </div>
                  <div className="form-group required-before">
                    <label htmlFor="">Thứ tự hiển thị banner</label>
                    <input
                      id="index"
                      type="number"
                      autoComplete="index"
                      required
                      placeholder="Vui lòng nhập số"
                      className="form-control"
                      {...register('index', {
                        required: false,
                        validate: value => {
                          if (value < 1) {
                            return 'Vui lòng nhập số lớn hơn 0';
                          }
                        }
                      })}
                    />
                    <span className="text-red-main text-xs">
                      {errors?.index?.message as string}
                    </span>
                  </div>
                  <div className="">
                    <label htmlFor="" className="required-before">
                      Banner
                    </label>
                    <div className="user-info-img w-[720px] h-auto">
                      <div className="relative w-[720px] h-auto items-center justify-center">
                        {!imageURL && !onErrorImage && (
                          <div className="flex items-center justify-center top-0 left-0 w-[720px] h-[200px] bg-[#e4f4ff] border border-dashed border-[#0795fe] cursor-pointer">
                            <input
                              type="file"
                              className="opacity-0 absolute top-0 left-0 w-full h-full cursor-pointer"
                              onChange={uploadImage}
                              accept="image/*"
                            />
                            <span className="text-[#0795fe] text-sm font-semibold line leading-6 cursor-pointer">
                              Tải hình ảnh lên
                            </span>
                          </div>
                        )}
                        {imageURL && !onErrorImage && (
                          <div>
                            <div className="absolute flex items-center justify-center top-0 left-0 w-full h-full bg-gray-33/70 user-info-img-upload ">
                              <input
                                type="file"
                                className="opacity-0 absolute top-0 left-0 w-full h-full "
                                onChange={uploadImage}
                                accept="image/*"
                              />
                              <AiOutlineEdit className="text-white" />
                            </div>
                            <img
                              src={imageURL}
                              alt="Banner"
                              className="mt-2 mb-4 w-full h-full "
                              onError={() => setOnErrorImage(true)}
                            />
                          </div>
                        )}
                        {onErrorImage && (
                          <div>
                            <div className="absolute flex items-center justify-center top-0 left-0 w-full h-full bg-gray-33/70 user-info-img-upload ">
                              <input
                                type="file"
                                className="opacity-0 absolute top-0 left-0 w-full h-full "
                                onChange={uploadImage}
                                accept="image/*"
                              />
                              <AiOutlineEdit className="text-white" />
                            </div>
                            <BiImage className="text-[100px] ml-1 self-center" />
                          </div>
                        )}
                        <input
                          id="image_url"
                          type="hidden"
                          autoComplete="image_url"
                          className="form-control"
                          {...register('image_url', {
                            required: !imageURL
                              ? 'Vui lòng chọn banner'
                              : false,
                            setValueAs: (value: string) => value?.trim(),
                            onChange: () => {
                              trigger('image_url');
                            }
                          })}
                        />
                        {uploadImageErrorMessage && (
                          <span className="text-red-main text-xs">
                            {uploadImageErrorMessage}
                          </span>
                        )}
                      </div>
                    </div>
                  </div>
                </ArticleCard>
              </div>

              <div className={`flex mt-2 gap-5`}>
                {paramsId ? (
                  <button
                    type="button"
                    className="form-button !min-w-[211px]"
                    onClick={() => {
                      setConfirmUpdateModal(true);
                    }}
                  >
                    Lưu
                  </button>
                ) : (
                  <button
                    type="button"
                    className="form-button !min-w-[211px]"
                    onClick={() => {
                      setConfirmAddModal(true);
                    }}
                  >
                    Thêm
                  </button>
                )}
              </div>
              <Modal
                show={confirmAddModal}
                title={'Xác Nhận Tạo Mới'}
                onClose={() => {
                  setConfirmAddModal(false);
                }}
                className="max-w-[420px]"
              >
                <div className="flex space-x-4">
                  <button
                    type="button"
                    className="flex justify-center w-full md:w-52 form-button-seconday"
                    onClick={() => {
                      setConfirmAddModal(false);
                    }}
                  >
                    Huỷ
                  </button>
                  <button
                    type="submit"
                    className="flex justify-center w-full md:w-52 form-button"
                    disabled={submitAdd}
                    onClick={handleSubmit(submitAddHandler, onError)}
                  >
                    {submitAdd && <CircleSpin />}
                    Đồng ý
                  </button>
                </div>
              </Modal>
              <Modal
                show={confirmUpdateModal}
                title={'Xác Nhận Chỉnh Sửa'}
                onClose={() => {
                  setConfirmUpdateModal(false);
                }}
                className="max-w-[420px]"
              >
                <div className="flex space-x-4">
                  <button
                    type="button"
                    className="flex justify-center w-full md:w-52 form-button-seconday"
                    onClick={() => {
                      setConfirmUpdateModal(false);
                    }}
                  >
                    Huỷ
                  </button>
                  <button
                    id="submitUpdate"
                    type="submit"
                    className="flex justify-center w-full md:w-52 form-button"
                    disabled={submitUpdate}
                    onClick={handleSubmit(submitUpdateHandler, onError)}
                  >
                    {submitUpdate && <CircleSpin />}
                    Đồng ý
                  </button>
                </div>
              </Modal>
            </form>
          </div>
        </>
      )}
    </>
  );
};

export default BannerAdd;
