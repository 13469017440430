import React, { useEffect, useState } from 'react';
import { ArticleCard } from 'components/ArticleCard';
import Breadcrumb from 'components/Breadcrumb';
import TitlePage from 'components/TitlePage';
import { CircleSpin } from 'components/UiComponents';
import Modal from 'components/UiComponents/Modal';
import { SubmitHandler, useForm, Controller } from 'react-hook-form';
import { AiOutlineBook } from 'react-icons/ai';
import { BiImage } from 'react-icons/bi';
import { AiOutlineEdit } from 'assets/icons';
import { useNavigate, useParams, useLocation } from 'react-router-dom';
import Select from 'react-select';
import { toast } from 'react-toastify';
import { getUploadImageUrl } from 'services/upload.service';
import {
  addReferralCode,
  getReferralCodeDetail,
  updateReferralCode
} from 'services/referralCode.service';

const OPTIONS_ACTION = [
  {
    value: 'AEID',
    label: 'AEID'
  },
  {
    value: 'RUNNER',
    label: 'RUNNER'
  }
];

const ReferralCodeAdd = () => {
  const {
    register,
    handleSubmit,
    setValue,
    getValues,
    trigger,
    setError,
    control,
    formState: { errors }
  } = useForm<any>({});
  const paramsURL = useParams();
  const location = useLocation();
  const navigate = useNavigate();
  const state = location?.state as { data: any };
  const paramsId = paramsURL?.id;
  const referralCodeDataState = state?.data;
  const [loading, setLoading] = useState(false);
  const [confirmAddModal, setConfirmAddModal] = useState(false);
  const [confirmUpdateModal, setConfirmUpdateModal] = useState(false);
  const [submitAdd, setSubmitAdd] = useState(false);
  const [submitUpdate, setSubmitUpdate] = useState(false);

  const selected = (url: any) => {
    if (url) {
      navigate(url);
    } else {
      navigate(-1);
    }
  };

  const crumbs = [
    {
      name: 'Quản Lý Mã Môi Giới',
      url: '/referral-code-management'
    },
    {
      name: paramsId ? 'Chỉnh sửa Mã Môi Giới' : 'Tạo mới Mã Môi Giới'
    }
  ];

  const getDetail = async (id: string) => {
    setLoading(true);
    try {
      const res = await getReferralCodeDetail(id);
      if (res.data) {
        const action = OPTIONS_ACTION.find(i => i.value === res.data?.type);
        setValue('referral_code', res.data?.referral_code);
        setValue('short_referral_code', res.data?.short_referral_code);
        setValue('full_name', res.data?.full_name);
        setValue('type', action);
      }
    } catch (error: any) {}
    setLoading(false);
  };

  const submitAddHandler: SubmitHandler<any> = async values => {
    setSubmitAdd(true);
    if (!values?.type?.value) {
      setError('type', {
        type: 'manual',
        message: 'Vui lòng chọn loại mã'
      });
      setSubmitAdd(false);
      setConfirmAddModal(false);
      return;
    }

    const params = {
      referral_code: values?.referral_code,
      short_referral_code: values?.short_referral_code,
      full_name: values?.full_name,
      type: values?.type?.value
    };

    const res = await addReferralCode(params);
    if (res?.status === 'Success') {
      const data = res?.data;
      toast.success('Tạo Mã Môi Giới thành công!', {
        position: 'top-right',
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined
      });
      setSubmitAdd(false);
      setConfirmAddModal(false);
      navigate(`/referral-code-management/edit/${data.referral_code}`, {
        state: { data }
      });
    } else {
      toast.error(res?.error_message || 'Có lỗi, vui lòng kiểm tra lại!', {
        position: 'top-right',
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined
      });
      setSubmitAdd(false);
      setConfirmAddModal(false);
    }
  };

  const submitUpdateHandler: SubmitHandler<any> = async values => {
    setSubmitUpdate(true);
    if (!values?.type?.value) {
      setError('type', {
        type: 'manual',
        message: 'Vui lòng chọn loại mã'
      });
      setSubmitUpdate(false);
      setConfirmUpdateModal(false);
      return;
    }
    const params = {
      referral_code: values?.referral_code,
      short_referral_code: values?.short_referral_code,
      full_name: values?.full_name,
      type: values?.type?.value
    };
    const res = await updateReferralCode(paramsId || '', params);
    if (res?.status === 'Success') {
      const data = res?.data;
      toast.success('Chỉnh sửa Mã Môi Giới thành công!', {
        position: 'top-right',
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined
      });
      setSubmitUpdate(false);
      setConfirmUpdateModal(false);
      navigate(`/referral-code-management/edit/${data.referral_code}`, {
        state: { data }
      });
    } else {
      toast.error(res?.error_message || 'Có lỗi, vui lòng kiểm tra lại!', {
        position: 'top-right',
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined
      });
      setSubmitUpdate(false);
      setConfirmUpdateModal(false);
    }
  };

  const onError = (errors: any, e: any) => {
    if (errors) {
      setSubmitAdd(false);
      setConfirmAddModal(false);
      setSubmitUpdate(false);
      setConfirmUpdateModal(false);
    }

    const type = getValues('type');
    if (!type?.value) {
      setError('type', {
        type: 'manual',
        message: 'Vui lòng chọn loại mã'
      });
    }
  };

  useEffect(() => {
    if (paramsId && !referralCodeDataState) {
      getDetail(paramsId);
    }
  }, [paramsId, referralCodeDataState]);

  return (
    <>
      {loading ? (
        <>
          <div className="flex justify-center mt-4 items-center tex-sm">
            <CircleSpin color="text-primary-e2" /> Loading...
          </div>
        </>
      ) : (
        <>
          <Breadcrumb crumbs={crumbs} selected={selected} />
          <TitlePage
            icon={() => <AiOutlineBook />}
            name={`${paramsId ? 'Chỉnh sửa' : 'Tạo mới'} Mã Môi Giới`}
          />
          <div className="mt-10">
            <form
              className="form"
              onSubmit={handleSubmit(submitAddHandler, onError)}
              noValidate
              autoComplete="off"
            >
              <div className="flex gap-3">
                <ArticleCard className="w-full">
                  <div className="form-group">
                    <label htmlFor="full_name" className="required-before">
                      Tên MG/ CTV
                    </label>
                    <input
                      id="full_name"
                      type="text"
                      autoComplete="full_name"
                      placeholder="Nhập tên MG/ CTV"
                      className="form-control"
                      {...register('full_name', {
                        required: 'Vui lòng nhập tên MG/ CTV',
                        setValueAs: (value: string) => value?.trim(),
                        onChange: () => {
                          trigger('full_name');
                        }
                      })}
                    />
                    <span className="text-red-main text-xs">
                      {errors?.full_name?.message as string}
                    </span>
                  </div>
                  <div className="form-group">
                    <label htmlFor="referral_code" className="required-before">
                      AEID
                    </label>
                    <input
                      id="referral_code"
                      type="text"
                      autoComplete="referral_code"
                      placeholder="Nhập AEID"
                      className="form-control"
                      {...register('referral_code', {
                        required: 'Vui lòng nhập AEID',
                        setValueAs: (value: string) => value?.trim(),
                        onChange: () => {
                          trigger('referral_code');
                        }
                      })}
                    />
                    <span className="text-red-main text-xs">
                      {errors?.referral_code?.message as string}
                    </span>
                  </div>
                  <div className="form-group">
                    <label
                      htmlFor="short_referral_code"
                      className="form-label required-before"
                    >
                      ID rút gọn
                    </label>
                    <input
                      id="short_referral_code"
                      type="text"
                      autoComplete="short_referral_code"
                      placeholder="Nhập ID rút gọn"
                      className="form-control"
                      {...register('short_referral_code', {
                        required: 'Vui lòng nhập ID rút gọn',
                        setValueAs: (value: string) => value?.trim(),
                        onChange: () => {
                          trigger('short_referral_code');
                        }
                      })}
                    />
                    <span className="text-red-main text-xs">
                      {errors?.short_referral_code?.message as string}
                    </span>
                  </div>
                  <div className="lg:flex lg:gap-4">
                    <div className="form-group flex-1">
                      <label htmlFor="type" className="required-before">
                        Loại mã
                      </label>
                      <Controller
                        name="type"
                        control={control}
                        render={({ field }) => {
                          return (
                            <Select
                              {...field}
                              id="type"
                              placeholder="Vui lòng chọn loại mã"
                              options={OPTIONS_ACTION}
                              onChange={option => {
                                field.onChange(option);
                                setValue('type', option, {
                                  shouldValidate: true
                                });
                              }}
                              value={getValues('type')}
                            />
                          );
                        }}
                      />
                      <span className="text-red-main text-xs">
                        {errors?.type?.message as string}
                      </span>
                    </div>
                  </div>
                </ArticleCard>
              </div>

              <div className={`flex mt-2 gap-5`}>
                {paramsId ? (
                  <button
                    type="button"
                    className="form-button !min-w-[211px]"
                    onClick={() => {
                      setConfirmUpdateModal(true);
                    }}
                  >
                    Lưu
                  </button>
                ) : (
                  <button
                    type="button"
                    className="form-button !min-w-[211px]"
                    onClick={() => {
                      setConfirmAddModal(true);
                    }}
                  >
                    Thêm
                  </button>
                )}
              </div>
              <Modal
                show={confirmAddModal}
                title={'Xác Nhận Tạo Mới'}
                onClose={() => {
                  setConfirmAddModal(false);
                }}
                className="max-w-[420px]"
              >
                <div className="flex space-x-4">
                  <button
                    type="button"
                    className="flex justify-center w-full md:w-52 form-button-seconday"
                    onClick={() => {
                      setConfirmAddModal(false);
                    }}
                  >
                    Huỷ
                  </button>
                  <button
                    type="submit"
                    className="flex justify-center w-full md:w-52 form-button"
                    disabled={submitAdd}
                    onClick={handleSubmit(submitAddHandler, onError)}
                  >
                    {submitAdd && <CircleSpin />}
                    Đồng ý
                  </button>
                </div>
              </Modal>
              <Modal
                show={confirmUpdateModal}
                title={'Xác Nhận Chỉnh Sửa'}
                onClose={() => {
                  setConfirmUpdateModal(false);
                }}
                className="max-w-[420px]"
              >
                <div className="flex space-x-4">
                  <button
                    type="button"
                    className="flex justify-center w-full md:w-52 form-button-seconday"
                    onClick={() => {
                      setConfirmUpdateModal(false);
                    }}
                  >
                    Huỷ
                  </button>
                  <button
                    id="submitUpdate"
                    type="submit"
                    className="flex justify-center w-full md:w-52 form-button"
                    disabled={submitUpdate}
                    onClick={handleSubmit(submitUpdateHandler, onError)}
                  >
                    {submitUpdate && <CircleSpin />}
                    Đồng ý
                  </button>
                </div>
              </Modal>
            </form>
          </div>
        </>
      )}
    </>
  );
};

export default ReferralCodeAdd;
