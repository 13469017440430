import { ArticleCard } from 'components/ArticleCard';
import Breadcrumb from 'components/Breadcrumb';
import TitlePage from 'components/TitlePage';
import React, { useEffect, useState } from 'react';
import { Controller, SubmitHandler, useForm } from 'react-hook-form';
import { AiOutlineBook } from 'react-icons/ai';
import { useNavigate, useParams } from 'react-router-dom';
import Select from 'react-select';
import Switch from 'react-switch';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import BlogEditorByJodit from 'components/Editor/BlogEditorByJodit';
import Modal from 'components/UiComponents/Modal';
import { CircleSpin } from 'components/UiComponents';
import { faqService } from 'services/faq.service';
import { toast } from 'react-toastify';
import moment from 'moment';

const FaqArticleAdd = () => {
  const {
    register,
    handleSubmit,
    setValue,
    getValues,
    trigger,
    setError,
    clearErrors,
    control,
    formState: { errors }
  } = useForm<any>({});
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [confirmShowModal, setConfirmShowModal] = useState(false);
  const [submit, setSubmit] = useState(false);
  const paramsURL = useParams();
  const paramsId = paramsURL?.id;
  const [status, setStatus] = useState(false);
  const [publishedDate, setPublishedDate] = useState(new Date());
  const [editorState, seteditorState] = useState<any>();
  const [editorENState, seteditorENState] = useState<any>();
  const [primaryCategories, setPrimaryCategories] = useState<any>();
  const [secondaryCategories, setSecondaryCategories] = useState<any>();

  const [crumbs] = useState([
    {
      name: 'Quản Lý Bài Viết',
      url: '/faq-article-management'
    },
    {
      name: `${paramsId ? 'Chỉnh Sửa' : 'Tạo Mới'} Bài Viết`,
      url: `${
        paramsId
          ? `/faq-article-management/add/${paramsId}`
          : '/faq-article-management/add'
      }`
    }
  ]);

  const selected = (url: any) => {
    if (url) {
      navigate(url);
    } else {
      navigate(-1);
    }
  };

  const getCategoryList = async (id?: string) => {
    const params = new URLSearchParams();
    params.append('CategoryId', id || '');

    if (!id) {
      params.append('IsHaveFaq', false as any);
    }

    const res = await faqService.getFaqCategoryList(params);
    if (res?.status === 'Success') {
      if (id) {
        setSecondaryCategories(
          res?.data.map((item: any) => {
            return { value: item.id, label: item.category_name };
          })
        );
      } else {
        setPrimaryCategories(
          res?.data.map((item: any) => {
            return { value: item.id, label: item.category_name };
          })
        );
      }
    } else {
      toast.error('Không thể lấy danh sách category!', {
        position: 'top-right',
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true
      });
    }
  };

  const getFaqArticleDetail = async (id: string) => {
    setLoading(true);
    const res = await faqService.getFaqArticleDetail(id);
    if (res?.status === 'Success') {
      setValue('question', res?.data?.question);
      setValue('question_eng', res?.data?.question_eng);
      setStatus(res?.data?.status === 'Unpublished' ? false : true);
      setValue('primary_category', {
        value: res?.data?.primary_category_id,
        label: res?.data?.primary_category_name
      });
      setValue('secondary_category', {
        value: res?.data?.seconary_category_id,
        label: res?.data?.seconary_category_name
      });
      if (res?.data?.published_date) {
        setPublishedDate(
          new Date(res?.data?.published_date.replace('Z', '')) || new Date()
        );
      }
      seteditorState(res?.data?.answer);
      seteditorENState(res?.data?.answer_eng);
      setValue('category_order', res?.data?.index.toString());

      getCategoryList(res?.data?.primary_category_id);
    } else {
      toast.error('Không thể lấy thông tin chi tiết bài viết!', {
        position: 'top-right',
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true
      });
    }
    setLoading(false);
  };

  const submitHandler: SubmitHandler<any> = async values => {
    setSubmit(true);

    let _editorState = editorState.replace(
      /<p style="display: none;">[\s\S]*?<\/p>\n<p><br><\/p>/g,
      ''
    );

    _editorState = _editorState.replace(
      /<p style="display: none;">[\s\S]*?<\/style>\n<\/p>/g,
      ''
    );

    let _editorENState = editorENState.replace(
      /<p style="display: none;">[\s\S]*?<\/p>\n<p><br><\/p>/g,
      ''
    );

    _editorENState = _editorENState.replace(
      /<p style="display: none;">[\s\S]*?<\/style>\n<\/p>/g,
      ''
    );

    if (!_editorENState || !_editorState) {
      if (!_editorState) {
        setError('editorState', {
          type: 'manual',
          message: 'Vui lòng nhập câu trả lời'
        });
      }

      if (!_editorENState) {
        setError('editorENState', {
          type: 'manual',
          message: 'Vui lòng nhập câu trả lời (EN)'
        });
      }

      setSubmit(false);
      setConfirmShowModal(false);
      return;
    }

    let params = {
      category_id:
        values?.secondary_category?.value || values?.primary_category?.value,
      index: Number(values?.category_order),
      question: values?.question,
      question_eng: values?.question_eng,
      answer: editorState,
      answer_eng: editorENState,
      status: status ? 'Published' : 'Unpublished',
      published_date: moment(publishedDate).format('YYYY-MM-DDTHH:mm:ss')
    };

    if (!status) {
      params = {
        ...params,
        published_date: null as any
      };
    }

    if (paramsId) {
      try {
        const res = await faqService.updateFaqArticle(paramsId, params);
        if (res?.status === 'Success') {
          toast.success('Cập nhật bài viết thành công!', {
            position: 'top-right',
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true
          });

          navigate(`/faq-article-management/${paramsId}`);
        } else {
          toast.error(
            res?.error_message || 'Cập nhật bài viết không thành công!',
            {
              position: 'top-right',
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true
            }
          );
        }
      } catch (error: any) {
        JSON.parse(error?.request?.response).map((item: any) => {
          toast.error(
            item?.error_message || 'Có lỗi xảy ra. Vui lòng thử lại sau!',
            {
              position: 'top-right',
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true
            }
          );
        });
      }
    }

    if (!paramsId) {
      try {
        const res = await faqService.addFaqArticle(params);
        if (res?.status === 'Success') {
          toast.success('Tạo mới bài viết thành công!', {
            position: 'top-right',
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true
          });

          navigate('/faq-article-management');
        } else {
          toast.error(
            res?.error_message || 'Tạo mới bài viết không thành công!',
            {
              position: 'top-right',
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true
            }
          );
        }
      } catch (error: any) {
        JSON.parse(error?.request?.response).map((item: any) => {
          toast.error(
            item?.error_message || 'Có lỗi xảy ra. Vui lòng thử lại sau!',
            {
              position: 'top-right',
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true
            }
          );
        });
      }
    }
    setSubmit(false);
    setConfirmShowModal(false);
  };

  const onError = (errors: any, e: any) => {
    if (errors) {
      setSubmit(false);
      setConfirmShowModal(false);
    }

    const primary_category = getValues('primary_category');

    if (!primary_category?.value) {
      setError('primary_category', {
        type: 'manual',
        message: 'Vui lòng chọn primary category'
      });
    }
  };

  const onEditorStateChange = (values: any) => {
    seteditorState(values);
    clearErrors('editorState');
  };

  const onEditorStateENChange = (values: any) => {
    seteditorENState(values);
    clearErrors('editorENState');
  };

  useEffect(() => {
    getCategoryList();
  }, []);

  useEffect(() => {
    if (paramsId) {
      getFaqArticleDetail(paramsId);
    }
  }, [paramsId]);

  return (
    <>
      <Breadcrumb crumbs={crumbs} selected={selected} />
      <TitlePage
        icon={() => <AiOutlineBook />}
        name={`${paramsId ? 'Chỉnh sửa' : 'Tạo mới'} Bài Viết`}
      />
      <div className="mt-10">
        <form
          className="form"
          onSubmit={handleSubmit(submitHandler, onError)}
          noValidate
          autoComplete="off"
        >
          <div className="flex gap-3">
            <ArticleCard className="w-full pt-14 relative">
              {!loading && (
                <>
                  <div className="absolute right-3 top-3 flex gap-5">
                    <label htmlFor="category_type">Trạng thái</label>
                    <Switch
                      onChange={(value: any) => {
                        setStatus(value);
                      }}
                      checked={status}
                      className="react-switch"
                      boxShadow="0px 1px 5px rgba(0, 0, 0, 0.6)"
                      activeBoxShadow="0px 0px 1px 10px rgba(0, 0, 0, 0.2)"
                      height={24}
                      width={44}
                      handleDiameter={18}
                      onColor={'#61CB2F'}
                      checkedIcon={false}
                      uncheckedIcon={false}
                    />
                  </div>
                  <div className="lg:flex lg:gap-4">
                    <div className="form-group w-1/2">
                      <label htmlFor="question" className="required-before">
                        Câu hỏi
                      </label>
                      <input
                        id="question"
                        type="text"
                        autoComplete="question"
                        placeholder="Nhập câu hỏi"
                        className="form-control"
                        {...register('question', {
                          required: 'Vui lòng nhập câu hỏi',
                          setValueAs: (value: string) => value?.trim(),
                          onChange: () => {
                            trigger('question');
                          }
                        })}
                      />
                      <span className="text-red-main text-xs">
                        {errors?.question?.message as string}
                      </span>
                    </div>
                    <div className="form-group w-1/2">
                      <label htmlFor="question_eng" className="required-before">
                        Câu hỏi (EN)
                      </label>
                      <input
                        id="question_eng"
                        type="text"
                        autoComplete="question_eng"
                        placeholder="Nhập câu hỏi (EN)"
                        className="form-control"
                        {...register('question_eng', {
                          required: 'Vui lòng nhập câu hỏi (EN)',
                          setValueAs: (value: string) => value?.trim(),
                          onChange: () => {
                            trigger('question_eng');
                          }
                        })}
                      />
                      <span className="text-red-main text-xs">
                        {errors?.question_eng?.message as string}
                      </span>
                    </div>
                  </div>
                  <div className="lg:flex lg:gap-4">
                    <div className="form-group flex-1 w-1/2">
                      <label
                        htmlFor="primary_category"
                        className="required-before"
                      >
                        Primary category
                      </label>
                      <Controller
                        name="primary_category"
                        control={control}
                        render={({ field }) => {
                          return (
                            <Select
                              {...field}
                              id="primary_category"
                              placeholder="Vui lòng chọn primary category"
                              options={primaryCategories}
                              onChange={option => {
                                field.onChange(option);
                                setValue('primary_category', option, {
                                  shouldValidate: true
                                });
                                getCategoryList(option?.value);
                                setValue('secondary_category', null);
                              }}
                              value={getValues('primary_category')}
                            />
                          );
                        }}
                      />
                      <span className="text-red-main text-xs">
                        {errors?.primary_category?.message as string}
                      </span>
                    </div>
                    <div className="form-group flex-1 w-1/2">
                      <label htmlFor="secondary_category">
                        Secondary category
                      </label>
                      <Controller
                        name="secondary_category"
                        control={control}
                        render={({ field }) => {
                          return (
                            <Select
                              {...field}
                              id="secondary_category"
                              placeholder="Vui lòng chọn Secondary category"
                              options={secondaryCategories}
                              onChange={option => {
                                field.onChange(option);
                                setValue('secondary_category', option, {
                                  shouldValidate: true
                                });
                              }}
                              value={getValues('secondary_category')}
                            />
                          );
                        }}
                      />
                      <span className="text-red-main text-xs">
                        {errors?.category_type?.message as string}
                      </span>
                    </div>
                  </div>
                  <div className="lg:flex lg:gap-4">
                    <div className="form-group w-1/2">
                      <label htmlFor="category_order">Ngày đăng</label>
                      <DatePicker
                        selected={publishedDate}
                        onChange={(date: any) => {
                          // check if date == current date
                          if (date.getDate() === new Date().getDate()) {
                            if (date.getHours() < new Date().getHours()) {
                              setError('published_date', {
                                type: 'manual',
                                message:
                                  'Không được chọn khoảng thời gian trong quá khứ'
                              });
                            } else {
                              if (
                                date.getHours() === new Date().getHours() &&
                                date.getMinutes() < new Date().getMinutes()
                              ) {
                                setError('published_date', {
                                  type: 'manual',
                                  message:
                                    'Không được chọn khoảng thời gian trong quá khứ'
                                });
                              } else {
                                setPublishedDate(date);
                                clearErrors('published_date');
                              }
                            }
                          } else {
                            setPublishedDate(date);
                            clearErrors('published_date');
                          }
                        }}
                        className="form-control z-50"
                        placeholderText="Chọn ngày đăng"
                        timeInputLabel="Time:"
                        showTimeInput
                        dateFormat="dd/MM/yyyy HH:mm:ss"
                        popperPlacement="top"
                        minDate={new Date()}
                        disabled={!status}
                      />
                      <span className="text-red-main text-xs">
                        {errors?.published_date?.message as string}
                      </span>
                    </div>
                    <div className="form-group w-1/2">
                      <label
                        htmlFor="category_order"
                        className="required-before"
                      >
                        Thứ tự hiển thị
                      </label>
                      <input
                        id="category_order"
                        type="number"
                        autoComplete="category_order"
                        placeholder="Nhập thứ tự hiển thị"
                        className="form-control"
                        {...register('category_order', {
                          required: 'Vui lòng nhập thứ tự ưu tiên !',
                          setValueAs: (value: string) => value?.trim(),
                          onChange: () => {
                            trigger('category_order');
                          }
                        })}
                      />
                      <span className="text-red-main text-xs">
                        {errors?.category_order?.message as string}
                      </span>
                    </div>
                  </div>
                  <div className="form-group">
                    <label htmlFor="category_order" className="required-before">
                      Câu trả lời
                    </label>
                    <div className="w-full min-h-[300px]">
                      <BlogEditorByJodit
                        editorValue={editorState}
                        saveEditorStateValues={onEditorStateChange}
                      />
                      <span className="text-red-main text-xs">
                        {errors?.editorState?.message as string}
                      </span>
                    </div>
                  </div>
                  <div className="form-group">
                    <label htmlFor="category_order" className="required-before">
                      Câu trả lời (EN)
                    </label>
                    <BlogEditorByJodit
                      editorValue={editorENState}
                      saveEditorStateValues={onEditorStateENChange}
                    />
                    <span className="text-red-main text-xs">
                      {errors?.editorENState?.message as string}
                    </span>
                  </div>
                </>
              )}
              {loading && (
                <div className="flex justify-center">
                  <CircleSpin color="text-primary-e2" /> Loading...
                </div>
              )}
            </ArticleCard>
          </div>
          <div className={`flex mt-2 gap-5`}>
            {paramsId ? (
              <button
                type="button"
                className="form-button !min-w-[211px]"
                onClick={() => {
                  setConfirmShowModal(true);
                }}
              >
                Lưu
              </button>
            ) : (
              <button
                type="button"
                className="form-button !min-w-[211px]"
                onClick={() => {
                  setConfirmShowModal(true);
                }}
              >
                Thêm
              </button>
            )}
          </div>
          <Modal
            show={confirmShowModal}
            title={`Xác Nhận ${paramsId ? 'Chỉnh Sửa' : 'Tạo Mới'}`}
            onClose={() => {
              setConfirmShowModal(false);
            }}
            className="max-w-[420px]"
          >
            <div className="flex space-x-4">
              <button
                type="button"
                className="flex justify-center w-full md:w-52 form-button-seconday"
                onClick={() => {
                  setConfirmShowModal(false);
                }}
              >
                Huỷ
              </button>
              <button
                type="submit"
                className="flex justify-center w-full md:w-52 form-button"
                disabled={submit}
                onClick={handleSubmit(submitHandler, onError)}
              >
                {submit && <CircleSpin />}
                Đồng ý
              </button>
            </div>
          </Modal>
        </form>
      </div>
    </>
  );
};

export default FaqArticleAdd;
