export const CircleWarningIcon = () => {
  return (
    <svg
      width="14"
      height="14"
      fill="currentColor"
      viewBox="0 0 14 14"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M6.99998 1.66659C4.05446 1.66659 1.66665 4.0544 1.66665 6.99992C1.66665 9.94544 4.05446 12.3333 6.99998 12.3333C9.9455 12.3333 12.3333 9.94544 12.3333 6.99992C12.3333 4.0544 9.9455 1.66659 6.99998 1.66659ZM0.333313 6.99992C0.333313 3.31802 3.31808 0.333252 6.99998 0.333252C10.6819 0.333252 13.6666 3.31802 13.6666 6.99992C13.6666 10.6818 10.6819 13.6666 6.99998 13.6666C3.31808 13.6666 0.333313 10.6818 0.333313 6.99992Z" />
      <path d="M6.99998 8.33325C6.63179 8.33325 6.33331 8.03478 6.33331 7.66659L6.33331 3.66659C6.33331 3.2984 6.63179 2.99992 6.99998 2.99992C7.36817 2.99992 7.66665 3.2984 7.66665 3.66659L7.66665 7.66659C7.66665 8.03478 7.36817 8.33325 6.99998 8.33325Z" />
      <path d="M5.99998 9.99992C5.99998 9.44763 6.4477 8.99992 6.99998 8.99992C7.55226 8.99992 7.99998 9.44763 7.99998 9.99992C7.99998 10.5522 7.55226 10.9999 6.99998 10.9999C6.4477 10.9999 5.99998 10.5522 5.99998 9.99992Z" />
    </svg>
  );
};
